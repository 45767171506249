import { createContext, useContext } from "react";
import { ApiResume } from "src/api/types";
import { ResumeOptions } from "src/components/ResumeCompanyUser/resume-card/ResumeCard";

export const ResumeCardContext = createContext<{
	resume: ApiResume;
	options?: ResumeOptions;
} | null>(null);

export function useResumeCardContext() {
	const context = useContext(ResumeCardContext);
	if (!context) {
		throw new Error(
			"ResumeCard.* component must be rendered as child of ResumeCard component"
		);
	}
	return context;
}

export default useResumeCardContext;
