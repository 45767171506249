const subscription = {
	"minimal-subscription-plan": {
		confirm: {
			title: "Are you sure",
			text: "Are you sure you want to change your subscription to: {{name}} The tasks already placed will be immediately recalculated",
		},
		"cant-change":
			"You can't change to this subscription because you have more user licenses than the package offers.",
		"per-month": " / per month",
		button: {
			current: "Current subscription",
			upgrade: "Upgrade",
		},
	},
	"subscription-plan": {
		"most-common": "Most chosen",
		"cant-change":
			"You can't change to this subscription because you have more user licenses than the package offers.",
		"per-month": "/ per month",
		"service-fee": "Service fee",
		trial: "{{days}} days free trial",
		button: {
			"other-options": "View other options",
			choose: "Choose subscription",
			current: "Current plan",
		},
		promos: {
			blackfriday: "Limited <1>BLACKFRIDAY</1> deal",
		},
		footer: {
			"save-cta": "Save {{save}}",
		},
	},
	"subscription-plan-price": {
		"first-month": "1st month",
		"after-discount-price": "Then {{price}} per month",
		"service-fee": "Service fee",
		"per-month": "per month",
		"per-year": "per year",
		save: "Save {{value}}%",
		"excl-vat": "Excl. VAT",
		"first-x-months": "First {{months}} months",
		after: "Then {{price}} per month",
	},
	"subscription-plan-custom": {
		title: "Not quite what you're looking for?",
		description:
			"Feel free to get in touch! An {{tenant.name}} team member will explore potential options with you.",
		button: "Contact us",
		subject: "Custom subscription: {{name}}",
	},
	"subscription-plan-selected": {
		"black-friday-sale": "Black Friday discount",
		discount: "Discount",
		"trial-disclaimer-fee-later":
			"This amount will be charged on <1>{{date}}</1>.",
		"trial-disclaimer-fee-direct":
			"This amount will be automatically charged",
		error: {
			title: "Something went wrong",
			text: "If this problem persists, please contact support@{{tenant.slug}}.nl",
		},
		summary: {
			title: "Summary",
		},
		addons: {
			title: "Modules",
		},
		button: {
			confirm: "Confirm subscription",
			"start-trial": "Start my free trial",
		},
		wallet: {
			title: "You still have {{value}} in your wallet!",
			description:
				"Therefore, you will be charged <1>{{value}}</1> for this payment",
			"description-remaining":
				"Therefore, you will be charged <1>{{value}}</1> for this transaction. After completing this payment, <3>{{remaining}}</3> will remain in your wallet.",
			"description-no-calculation":
				"We will deduct this amount from the subtotal",
		},
	},
	"subscription-usage-card": {
		"per-month": "per month",
		"service-fee": "service fee",
		"auto-renew": "Will automatically renew on {{date}}",
		button: {
			view: "View service fee",
		},
	},
	"subscription-status": {
		title: "Almost done",
		mandate: {
			completed: "Authorization successful",
			cancelled:
				"Authorization failed. If you would like to link in a different way than through Rabobank, ABN or ING, please contact <1>support@{{tenant.slug}}.nl<1>",
			subject: "Create Direct Debit {{name}}",
			pending: "We are currently checking the authorizations",
		},
		subscription: {
			loading: "Subscription is being created",
			completed: "{{name}} subscription linked",
		},
		button: {
			continue: "Continue",
		},
	},
	"current-mandate": {
		"change-subscription": "Change subscription",
	},
	"current-subscription": {
		current: "Current subscription",
		"per-month": "/ per month excl. VAT",
		"per-year": "/ per year excl. VAT",
		"service-fee": {
			title: "+ {{price}} placement fee",
			button: "View jobs",
		},
	},
	"current-wallet": {
		title: "Current placement balance",
		remaining: "remaining",
		why: {
			title: "Why do I still have placement balance?",
			description:
				"Since March 1, 2021, {{tenant.name}} has switched to a subscription model. Previously, a wallet was used. To not lose early adopters, all fees and subscription payments will be deducted from this wallet.",
		},
	},
	"mandate-card": {
		connected: "Connected",
		pending: "Pending",
		"created-at": "Created at: {{date}}",
		button: {
			selected: "Active payment method",
			select: "Set as active",
		},
	},
	"mandate-list": {
		error: {
			title: "Something went wrong",
			text: "If this issue continues, please contact support@{{tenant.slug}}.nl",
		},
	},
	"subscription-next-payment": {
		title: "Next payment",
		description: "On {{date}}",
	},
	"subscription-payment-item": {
		summary: {
			title: "Summary",
		},
		information: {
			title: "Information",
			"start-date": "Start date:",
			"end-date": "End date:",
			invoice: "Invoice",
			"invoice-number": "Invoice number",
			"worth-excl-vat": "Value excl. VAT:",
		},
		"credit-log": {
			before: "Before",
			total: "Value",
			after: "After",
			"service-fee": "Placement balance",
		},
		button: {
			"download-invoice": "Download invoice",
			before: "Before debit:",
			decreased: "Debited:",
			after: "After debit:",
		},
	},
	"subscription-payments-table": {
		headers: {
			price: "Price",
			status: "Status",
			description: "Description",
			date: "Date",
		},
		empty: {
			title: "No subscriptions found",
		},
	},
};

export default subscription;
