import React from "react";
import { useTranslation } from "react-i18next";
import Card from "src/components/Card";

interface TipCardProps {
	children?: any;
}

const TipCard = ({ children }: TipCardProps) => {
	const { t } = useTranslation("elements", { keyPrefix: "tip-card" });
	return (
		<Card title={t("title")} className=" ">
			{children && <p className="mb-0 mt-2">{children}</p>}
		</Card>
	);
};

export default TipCard;
