const subscription = {
	"thanks-view": {
		title: "Je abonnement is gekozen",
		subtitle: "Een ogenblik geduld, we maken je account klaar...",
	},
	"index-view": {
		already: {
			title: "Je hebt al een actief abonnement",
			button: "Doorgaan naar mijn dashboard",
		},
		back: "Terug naar dashboard",
		loyal: {
			title: "Speciaal abonnement voor loyale klanten!",
			description:
				"Bedankt dat je al <1>{{days}}</1> dagen een klant van ons bent! Daarom hebben we voor jou een speciale korting.",
		},
		head: {
			title: "Pakket kiezen",
			subtitle: "Kies het pakket dat bij jouw organisatie past.",
		},
	},
};

export default subscription;
