import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiRegistration } from "src/api/types";
import Button from "src/components/Button";
import Card from "src/components/Card";
import ModalReport from "src/components/ModalReport";
import Chat from "src/components/chat/Chat";
import ReviewForm from "src/components/forms/ReviewForm";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import Resume from "src/components/resume/Resume";
import ChatButton from "src/components/tasks/TaskForm/elements/ChatButton";
import RegistrationAcceptModal from "src/components/tasks/TaskForm/modals/RegistrationAcceptModal";
import RegistrationDenieModal from "src/components/tasks/TaskForm/modals/RegistrationDenieModal";
import RegistrationButtons from "src/components/tasks/panes/registration/RegistrationButtons";
import RegistrationDetails from "src/components/tasks/panes/registration/RegistrationDetails";
import RegistrationHead from "src/components/tasks/panes/registration/RegistrationHead";
import RegistrationMatchDetails from "src/components/tasks/panes/registration/RegistrationMatchDetails";
import RegistrationReviews from "src/components/tasks/panes/registration/RegistrationReviews";
import useTask from "src/hooks/api/tasks/useTask";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import useQueryString from "src/hooks/useQueryString";

interface SelectedRegistrationPaneProps {
	id: number | string;
}

const SelectedRegistrationPane = ({ id }: SelectedRegistrationPaneProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "selected-registration-pane",
	});
	const { extra } = useParams() as any;
	const [{ data: registration }, , { fetchGet }] = useGet(
		`/client/registrations/${id}?with[]=resume&with[]=favorite`
	);
	const [matchTries, setMatchTries] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();
	const { task, actions } = useTask();
	const auth = useAuth();
	const { status, user } = registration || {};
	const tabs = {
		matched: [t("tabs.match"), t("tabs.registration"), t("tabs.reviews")],
		pending: [t("tabs.registration")],
	};

	const activeTabs = ["matched", "completed", "canceled"].includes(status)
		? tabs.matched
		: tabs.pending;
	const [activeTab, setActiveTab] = useState(activeTabs[0]);
	if (user?.has_resume) {
		tabs.pending.push(t("tabs.resume"));
	}

	const qs = useQueryString();

	useSocketListener(
		registration?.payment && `payment.${registration?.payment?.hid}.status`,
		() => {
			fetchGet(false);
		}
	);

	useEffect(() => {
		if (qs.payment_return && qs.rid && registration?.id) {
			if (registration?.status !== "matched") {
				const interval = setInterval(() => {
					fetchGet();
					setMatchTries((i) => i + 1);
				}, 3000);

				if (matchTries > 11) {
					navigate(location.pathname);
				}
				return () => clearInterval(interval);
			} else {
				navigate(location.pathname);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qs, registration]);

	const onClose = async (data?: ApiRegistration) => {
		fetchGet(false);
		navigate(`/tasks/${task?.hid}/show/${registration?.hid}`);
		actions.get(false);
	};

	if (registration && task && task?.hid !== registration?.task?.hid) {
		return (
			<div className="flex flex-col flex-1 justify-center items-center">
				<strong>{t("empty.title")}</strong>
			</div>
		);
	}

	if (qs?.payment_return) {
		return (
			<div className="flex flex-col">
				<Card type="no-padding">
					<div className="flex flex-col gap-6">
						{registration && (
							<RegistrationHead {...{ registration, task }} />
						)}
						<div className="p-12 flex flex-col justify-center items-center gap-6">
							<span className="base-loader"></span>
						</div>
					</div>
				</Card>
			</div>
		);
	}

	if (extra && extra === "chat" && registration?.id && task?.id) {
		return (
			<Chat
				options={{
					type: "task",
					room: `room-${registration.id}`,
					backUrl: `/tasks/${task?.hid}/show/${registration.hid}`,
				}}
				data={{
					task,
					registration,
				}}
				loading={false}
			/>
		);
	}

	if (
		(extra === "review" || extra === "cancel") &&
		registration?.id &&
		task?.id
	) {
		if (
			extra === "review" &&
			!["reserved", "completed"]?.includes(registration?.payment?.status)
		) {
			return (
				<Card>
					<Card.Head
						title={t("not-finishable.title")}
						description={t("not-finishable.description")}
					/>
					<div className="flex">
						<Button
							type="gray"
							to={`/tasks/${task?.hid}/show/${registration?.hid}`}
						>
							{t("not-finishable.button")}
						</Button>
					</div>
				</Card>
			);
		}

		return (
			<div className="flex flex-col gap-8">
				<ReviewForm
					taskId={task.id}
					userId={registration?.user?.id}
					companyId={task?.company?.id}
					registrationId={registration?.id}
					onDone={() => {
						fetchGet(false);
						navigate(
							`/tasks/${task?.hid}/show/${registration?.hid}`
						);
					}}
					variant={extra === "cancel" ? "cancel" : undefined}
					onCancel={() =>
						navigate(
							`/tasks/${task?.hid}/show/${registration?.hid}`
						)
					}
				/>
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-8">
			{registration?.id && (
				<>
					{auth?.has_subscription &&
						registration.status === "pending" && (
							<>
								<RegistrationDenieModal
									visible={extra === "denie"}
									registrationId={id}
									{...{ onClose }}
								/>
								<RegistrationAcceptModal
									visible={extra === "accept"}
									{...{ registration, task }}
									{...{ onClose }}
								/>
							</>
						)}
					<ModalReport
						visible={extra === "report"}
						{...{ registration, task }}
						{...{ onClose }}
					/>
					<Card type="no-padding">
						<div className="flex flex-col gap-8">
							<RegistrationHead {...{ registration, task }} />
							<div className="px-6 pb-6 flex flex-col gap-8">
								<Tabs.Container className="bg-accent-soft">
									{activeTabs.map((tab) => (
										<Tab
											key={tab}
											onClick={() => setActiveTab(tab)}
											active={tab === activeTab}
										>
											{tab}
										</Tab>
									))}
									<div className="flex-1"></div>
									<ChatButton
										xsmall
										className="gtm-company-dashboard-opdracht--button-naar-chat"
										to={`/tasks/${task.hid}/show/${registration?.hid}/chat`}
										registrationId={registration?.id}
									/>
								</Tabs.Container>

								{activeTab === t("tabs.match") && (
									<RegistrationMatchDetails
										{...{
											registration,
										}}
									/>
								)}
								{activeTab === t("tabs.reviews") && (
									<RegistrationReviews
										{...{
											registration,
										}}
									/>
								)}
								{activeTab === t("tabs.resume") && (
									<Resume
										id={user?.id}
										exclude={["header"]}
										variant="registration"
									/>
								)}
								{activeTab === t("tabs.registration") && (
									<RegistrationDetails
										{...{ registration }}
									/>
								)}

								<hr className="border-dark opacity-10" />
								<RegistrationButtons {...{ registration }} />
							</div>
						</div>
					</Card>
				</>
			)}
		</div>
	);
};

export default SelectedRegistrationPane;
