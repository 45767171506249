import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiRegistration } from "src/api/types";
import ResumeMiddleware from "src/components/middlewares/ResumeMiddleware";
import ReadMore from "src/components/ReadMore";
import ResumeSkills from "src/components/resume/modules/ResumeSkills";
import RegistrationSection from "src/components/tasks/panes/registration/RegistrationSection";
import useAuth from "src/hooks/selectors/useAuth";

interface RegistrationDetailsProps {
	registration: ApiRegistration;
	variant: "vertical" | "horizontal";
}

const RegistrationDetails = ({
	registration,
	variant,
}: RegistrationDetailsProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "registration-details",
	});
	const auth = useAuth();
	let answers = registration?.answers || [];
	const user: any = registration?.user || {};
	const currentEducation = user?.current?.education;

	const modules = user?.resume_modules || [];
	const hasAboutMe =
		modules?.includes("skills") ||
		currentEducation?.title ||
		user?.bio ||
		user?.telephone;

	if (!(answers?.length > 0) && registration?.motivation) {
		answers = [
			{
				hid: "",
				question: "Motivatie",
				answer: registration.motivation,
			},
		];
	}

	if (registration?.user?.variant === "FULL_ANONYMOUS") {
		return (
			<ResumeMiddleware className="flex flex-col gap-4 p-8 md:p-12">
				<div className="flex flex-col">
					<ResumeMiddleware.Title />
					<ResumeMiddleware.Description />
				</div>
				<div className="flex">
					<ResumeMiddleware.UpgradeButton />
				</div>
			</ResumeMiddleware>
		);
	}

	return (
		<div
			className={`flex ${
				variant === "horizontal" && "md:flex-row"
			} flex-col gap-6`}
		>
			<RegistrationSection
				className="flex-1 text-background-foreground"
				title={t("registration")}
			>
				{answers.map((answer: any) => (
					<div
						className="flex flex-col gap-1"
						key={`${answer.question}`}
					>
						<strong>{answer.question}</strong>
						<span
							className={
								answer?.is_right !== undefined
									? answer.is_right
										? "text-green"
										: "text-red"
									: ""
							}
						>
							{answer.answer}
						</span>
					</div>
				))}

				{auth.type === "company" && registration.deny_motivation && (
					<div className="flex flex-col gap-1 text-red">
						<strong>{t("reason-of-deny")}</strong>
						<ReadMore
							length={120}
							text={registration.deny_motivation}
						/>
					</div>
				)}

				<div className="flex">
					<small className="opacity-40">
						{moment(registration?.created_at).fromNow()}
					</small>
				</div>
			</RegistrationSection>

			{hasAboutMe && (
				<RegistrationSection
					className="flex-1 text-background-foreground"
					title={t("about-me")}
				>
					{user?.telephone ? (
						<div className="flex flex-col gap-1">
							<strong>{t("telephone")}</strong>
							<span>{user.telephone}</span>
						</div>
					) : (
						false
					)}
					{currentEducation && (
						<div className="flex flex-col gap-1">
							<strong>{t("current-education")}</strong>
							<span>
								{t("current-education-text", currentEducation)}
							</span>
						</div>
					)}
					{user?.bio && (
						<div className="flex flex-col gap-2">
							<strong>{t("bio")}</strong>
							<ReadMore text={user?.bio?.bio} length={200} />
						</div>
					)}
					{modules?.includes("skills") &&
						user?.skills?.length > 0 && (
							<div className="flex flex-col gap-2">
								<strong>{t("skills")}</strong>
								<ResumeSkills
									skills={user?.skills}
									variant="small"
									// query={`/resume/${user?.hid}/skills`}
								/>
							</div>
						)}
				</RegistrationSection>
			)}
		</div>
	);
};

RegistrationDetails.defaultProps = {
	variant: "horizontal",
};

export default RegistrationDetails;
