import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSearch from "src/hooks/api/services/useSearch";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import Avatar from "../Avatar";
import DropdownButton from "../DropdownButton";
import Modal from "../Modal";
import Skeleton from "../Skeleton";
import withTranslation, { Translation } from "../hoc/withTranslation";
import Icon from "../icon/Icon";
import {
	Command,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command";
import Text from "src/components/ui/text";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { cutString } from "src/lib/formatters";

const MAX_LABEL_LENGTH = 43;

interface SearchViewProps extends Translation {
	defaultVisible: boolean;
	backUrl: string;
}

const SearchView = ({ t, defaultVisible, backUrl }: SearchViewProps) => {
	const {
		tenant: { modules },
	} = useTenant();
	const { searchList, actions, status } = useSearch();
	const [search, setSearch] = useState("");
	const navigate = useNavigate();
	const classNames = {
		group: "[&_[cmdk-group-items]]:gap-2 [&_[cmdk-group-items]]:flex [&_[cmdk-group-items]]:flex-col [&_[cmdk-group-items]]:pb-4",
		item: "px-2 mx-4 bg-transparent text-background-foreground aria-selected:bg-accent aria-selected:text-accent-foreground",
	};

	useUpdateEffectDebounce(search, 1000, () => {
		actions.list({
			page: 1,
			search: search.toLowerCase(),
		});
	});

	useEffect(() => {
		actions.list({
			page: 1,
			search: null,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClose = () => {
		navigate(backUrl);
	};

	const renderHeading = (label: string) => {
		return (
			<Text.Eyebrow className="pl-3 py-2 opacity-40">
				{label}
			</Text.Eyebrow>
		);
	};

	return (
		<Modal
			head="disabled"
			// size="default"
			bodyClassName="py-0"
			visible={defaultVisible || false}
			className="w-full max-w-md"
			{...{ onClose }}
		>
			<Command className="" shouldFilter={false}>
				<CommandInput
					autoFocus
					className="px-6 py-3"
					onValueChange={(search) => setSearch(search)}
					value={search}
					placeholder={t("search")}
				/>
				<CommandList className="max-h-[500px] min-h-[500px]">
					{status === "idle" && searchList.length === 0 && (
						<p className="pl-7 py-2 text-[16px]">
							{t("no-results")}
						</p>
					)}
					<CommandGroup
						className={classNames.group}
						heading={renderHeading(t("tasks"))}
					>
						{status === "loading" ? (
							<SearchSkeleton />
						) : (
							searchList
								.filter((task) => task.type === "TASK")
								.slice(0, 3)
								.map((task, index) => {
									return (
										<CommandItem
											key={task.hid}
											value={task.hid.toString()}
											className={classNames.item}
											onSelect={() =>
												navigate(
													`/tasks/${task.hid}/editor`
												)
											}
										>
											<div className="flex items-center">
												<Icon
													className="mr-2"
													icon={{
														font_awesome: {
															name: "fas fa-file",
														},
													}}
												/>
											</div>
											<p className="text-[16px]">
												{cutString(
													task.label,
													MAX_LABEL_LENGTH
												)}
											</p>
										</CommandItem>
									);
								})
						)}
					</CommandGroup>
					{modules.includes("intern-task") && (
						<>
							<hr className="border-border" />
							<CommandGroup
								className={classNames.group}
								heading={renderHeading(t("boards"))}
							>
								{status === "loading" ? (
									<SearchSkeleton />
								) : (
									searchList
										.filter(
											(board) => board.type === "BOARD"
										)
										.slice(0, 3)
										.map((board) => {
											return (
												<CommandItem
													key={board.slug}
													className={classNames.item}
													value={board.slug.toString()}
													onSelect={() =>
														navigate(
															`/tasks/boards/${board.slug}`
														)
													}
												>
													<div className="flex items-center">
														<Icon
															className="mr-2"
															icon={
																board.slug ===
																"my-tasks"
																	? {
																			font_awesome:
																				{
																					name: "fas fa-user-crown",
																				},
																	  }
																	: board?.image
															}
														/>
													</div>
													<p className="text-[16px]">
														{cutString(
															board.label,
															MAX_LABEL_LENGTH
														)}
													</p>
												</CommandItem>
											);
										})
								)}
							</CommandGroup>
						</>
					)}
					{modules.includes("intern-task") && (
						<>
							<hr className="border-border" />
							<CommandGroup
								className={classNames.group}
								heading={renderHeading(t("projects"))}
							>
								{status === "loading" ? (
									<SearchSkeleton />
								) : (
									searchList
										.filter(
											(project) =>
												project.type === "PROJECT"
										)
										.slice(0, 3)
										.map((project) => {
											return (
												<CommandItem
													key={project.hid}
													value={project.hid}
													className={classNames.item}
													onSelect={() =>
														navigate(
															`/projects/${project.slug}`
														)
													}
												>
													<div className="flex items-center">
														<Icon
															className="mr-2"
															icon={
																project?.image
															}
														/>
													</div>
													<p className="text-[16px]">
														{cutString(
															project.label,
															MAX_LABEL_LENGTH
														)}
													</p>
												</CommandItem>
											);
										})
								)}
							</CommandGroup>
						</>
					)}
					{modules.includes("extern-task") && (
						<>
							<hr className="p-0 border-border" />
							<CommandGroup
								className={classNames.group}
								heading={renderHeading(t("professionals"))}
							>
								{status === "loading" ? (
									<SearchSkeleton />
								) : (
									searchList
										.filter(
											(connection) =>
												connection.type === "CONNECTION"
										)
										.slice(0, 3)
										.map((row: any) => {
											return (
												<CommandItem
													key={row.hid}
													value={row.hid}
													className="flex justify-between px-2 mx-4"
												>
													<div className="flex items-center gap-2">
														<Avatar
															className=""
															src={row.image}
															size="xsmall"
															alt={row.label}
														/>
														<p className="text-[16px]">
															{cutString(
																row.label,
																MAX_LABEL_LENGTH
															)}
														</p>
													</div>
													<div className="flex items-center gap-3">
														<DropdownButton
															menu={{
																position:
																	"bottom-right",
															}}
															button={{
																tabIndex: "-1",
																children: (
																	<i className="far fa-ellipsis-v " />
																),
																iconOnly: true,
																className:
																	"w-[30px] h-[30px]",
																type: "border",
																small: true,
															}}
														>
															<DropdownButton.Item
																icon="fas fa-user mr-2"
																onClick={() => {
																	navigate(
																		`/view-profile/${row.hid}`
																	);
																}}
															>
																{t(
																	"view-profile"
																)}
															</DropdownButton.Item>
															<DropdownButton.Item
																icon="fas fa-file-plus mr-2"
																to={`/tasks/create?profile=${row.hid}`}
															>
																{t(
																	"create-task"
																)}
															</DropdownButton.Item>
														</DropdownButton>
													</div>
												</CommandItem>
											);
										})
								)}
							</CommandGroup>
						</>
					)}
				</CommandList>
			</Command>
		</Modal>
	);
};

const SearchSkeleton = () => {
	return (
		<>
			<div className="flex flex-col gap-1 px-4">
				<Skeleton className="h-10 w-full" />
				<Skeleton className="h-10 w-full" />
				<Skeleton className="h-10 w-full" />
			</div>
		</>
	);
};

SearchView.locale = {
	nl: {
		search: "Zoek binnen je {{tenant.name}} account...",
		tasks: "Taken",
		projects: "Projecten",
		boards: "Borden",
		professionals: "Professionals",
		"view-profile": "Profiel bekijken",
		"create-task": "Taak aanmaken",
		"no-results": "Geen resultaten gevonden",
	},
	en: {
		search: "Search within your {{tenant.name}} account...",
		tasks: "Tasks",
		projects: "Projects",
		boards: "Boards",
		professionals: "Professionals",
		"view-profile": "View profile",
		"create-task": "Create task",
		"no-results": "No results found",
	},
};

export default withTranslation(SearchView);
