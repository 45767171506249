import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import AddressForm from "src/components/forms/AddressForm";
import Modal from "src/components/Modal";
import usePost from "src/hooks/rest/usePost";
import { Address } from "src/api/types";
import { Trans, useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface CompanyCreateModalProps {
	visible: boolean;
	setVisible: (value: boolean) => void;
	refetch?: () => void;
}

const CompanyCreateModal = ({
	refetch,
	visible,
	setVisible,
}: CompanyCreateModalProps) => {
	const { t } = useTranslation("profile-view", {
		keyPrefix: "company-create-modal",
	});
	const EmailScheme = Yup.object().shape({
		email: Yup.string().email(t("email-incorrect")).required(t("required")),
	});
	const [createCompany] = usePost("/company/companies");
	const { tenant } = useTenant();
	const [status, setStatus] = useState("");
	const [address, setAddress] = useState({} as Address);
	const formattedAddress = address?.street
		? `${address?.street} ${address?.house_number || ""}${
				address?.house_number_extra
					? ` ${address?.house_number_extra || ""}`
					: ""
		  }, ${address?.city || ""}`
		: "";
	const checkIfEmailExists = async (email: string) => {
		const { data } = await axios.get(
			`/helpers/check-email-available?email=${email}`
		);
		return data?.data || false;
	};

	const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
		setSubmitting(true);
		checkIfEmailExists(values.email).then((data) => {
			if (data) {
				createCompany({
					address,
					...values,
				})
					.then(() => {
						if (refetch) {
							refetch();
						}
						setStatus("added");
					})
					.catch(() => {
						setStatus("error");
					})
					.finally(() => {
						setSubmitting(false);
					});
			} else {
				resetForm({
					values,
					errors: {
						email: t("already-in-use", { email: values.email }),
					},
					touched: {
						email: true,
					},
				});
				setSubmitting(false);
			}
		});
	};

	const wrapChildren = (elems?: any) => {
		if (status === "added") {
			return (
				<div className="flex flex-col gap-4">
					<i className="fas fa-check-circle text-4xl text-green"></i>
					<p>{t("status.added")}</p>
					<div className="flex">
						<Button
							small
							onClick={() => {
								setVisible(false);
								setAddress({} as Address);
								setStatus("");
							}}
							type="dark"
						>
							{t("status.button")}
						</Button>
					</div>
				</div>
			);
		}
		if (status === "error") {
			return (
				<div className="flex flex-col gap-4">
					{/* <i className="fas fa-times text-2xl text-red"></i> */}
					<p>
						<Trans {...{ t }} i18nKey="status.failed">
							Er is iets misgegaan, probeer het later opnieuw.
							Blijft dit probleem neem dan contact op met
							<a
								href={`mailto: support@${tenant?.urls?.domain}`}
								className="highlight-green"
							>
								support@{tenant?.urls?.domain}
							</a>
						</Trans>
					</p>
					<div className="flex">
						<Button
							small
							onClick={() => {
								setVisible(false);
								setAddress({} as Address);
								setStatus("");
							}}
							type="dark"
						>
							{t("status.button")}
						</Button>
					</div>
				</div>
			);
		}
		return elems;
	};

	return (
		<Modal
			title={t("title")}
			onClose={() => setVisible(false)}
			{...{ visible }}
		>
			{wrapChildren(
				address.street ? (
					<>
						<div className="description">
							<p className="mb-4">
								<Trans i18nKey="description" {...{ t }}>
									Vul een emailadres in en wij nodigen degene
									uit voor de vestiging op
									<span className="highlight-green">
										{{
											address: formattedAddress,
										}}
									</span>
									. Door middel van een mail kan degene een
									account aanmaken.
								</Trans>
							</p>
							<Formik
								initialValues={{ email: "" }}
								validationSchema={EmailScheme}
								onSubmit={handleSubmit}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
								}) => (
									<form
										className="invite-modal-form flex flex-col gap-4"
										onSubmit={handleSubmit}
									>
										<Input
											label={t("email")}
											placeholder={t("email")}
											name="email"
											type="email"
											value={values.email}
											valid={
												values.email && !errors.email
											}
											error={
												errors.email &&
												touched.email &&
												errors.email
											}
											{...{ handleChange, handleBlur }}
										/>
										<div className="flex justify-between itmes-center mt-4">
											<Button
												onClick={() =>
													setAddress({
														...address,
														street: "",
													})
												}
												type="gray"
												className="gtm-werkgever-dashboard-profiel-vestigingen-modal-2--button-stap-terug"
											>
												{t("buttons.back")}
											</Button>
											<Button
												submit
												loading={isSubmitting}
												className="gtm-werkgever-dashboard-profiel-vestigingen-modal-2--button-vestiging-aanmaken"
											>
												{t("buttons.create")}
											</Button>
										</div>
									</form>
								)}
							</Formik>
						</div>
					</>
				) : (
					<>
						<div className="description mb-4">
							<p>{t("fill-description")}</p>
						</div>
						<AddressForm
							{...{ address }}
							type={"company-vestiging"}
							prevButton={
								<Button
									onClick={() => setVisible(false)}
									type="dark"
									className="gtm-werkgever-dashboard-profiel-vestigingen-modal-1--button-annuleren"
								>
									{t("buttons.cancel")}
								</Button>
							}
							onSubmit={setAddress}
						/>
					</>
				)
			)}
		</Modal>
	);
};

export default CompanyCreateModal;
