import { useState } from "react";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useSetType from "src/hooks/api/services/auth/useSetType";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import { cn } from "src/lib/utils";

type Type = "company" | "student" | "freelancer";

const SetupTypeView = ({ t }: Translation) => {
	const { tenant } = useTenant();
	const [active, setActive] = useState<Type>();
	const { status, actions } = useSetType();

	const onSubmit = async () => {
		if (!active) return;

		await actions.setType(active === "company" ? "company" : "student");
	};

	if (tenant?.slug === "odylyt") {
		return (
			<div className="dark flex flex-1 flex-col justify-center mx-auto gap-8 w-full max-w-[444px] px-3">
				<h1 className="">{t("title")}</h1>
				<div className="flex flex-col gap-4">
					<Item
						onClick={() => setActive("student")}
						type="student"
						isActive={active === "student"}
						icon={<i className="far fa-user" />}
					/>
					<Item
						onClick={() => setActive("company")}
						type="company"
						isActive={active === "company"}
						icon={<i className="far fa-user-friends" />}
					/>
				</div>
				<Button
					disabled={!active}
					loading={status !== "idle"}
					onClick={onSubmit}
					type="primary"
					className="w-full"
				>
					<span>{t("continue")}</span>
					<i className="fas fa-arrow-right ml-2"></i>
				</Button>
			</div>
		);
	}

	return (
		<div className="flex flex-1 flex-col justify-center mx-auto gap-8 w-full max-w-[444px] px-3">
			<h1 className="">{t("title")}</h1>
			<div className="flex flex-col gap-4">
				{["company", "student"].map((type: any) => (
					<Item
						key={`onboarding-${type}`}
						onClick={() => setActive(type)}
						isActive={active === type}
						{...{ type }}
					/>
				))}
			</div>
			<Button
				disabled={!active}
				loading={status !== "idle"}
				onClick={onSubmit}
				type="primary"
				className="w-full"
			>
				<span>{t("continue")}</span>
				<i className="fas fa-arrow-right ml-2"></i>
			</Button>
		</div>
	);
};

interface ItemProps extends Translation {
	type: Type;
	onClick: () => void;
	isActive?: boolean;
	icon?: any;
}

const Item = withTranslation(
	({ t, type, onClick, isActive, icon }: ItemProps) => {
		const { tenant } = useTenant();
		return (
			<Card
				{...{ onClick }}
				className={cn(
					"group border hover:border-primary transition-all flex-1 cursor-pointer rounded-xl",
					isActive && "border-primary shadow-md"
				)}
				contentClassName="p-6 overflow-hidden relative flex-row items-center gap-5"
			>
				{icon && (
					<div
						className={cn(
							"text-[32px] transition-all w-10 flex justify-center",
							isActive && "text-primary"
						)}
					>
						{icon}
					</div>
				)}
				<div className="flex flex-col">
					<p
						className={cn(
							"text-xl transition-all",
							isActive && "text-primary"
						)}
					>
						{t(
							tenant.slug === "odylyt"
								? `odylyt.${type}.title`
								: `${type}.title`
						)}
					</p>
					{tenant.slug === "odylyt" && (
						<p
							className={cn(
								"opacity-70 transition-all",
								isActive && "opacity-100"
							)}
						>
							{t(
								tenant.slug === "odylyt"
									? `odylyt.${type}.description`
									: `${type}.description`
							)}
						</p>
					)}
					{tenant.slug !== "odylyt" && (
						<ul className="flex gap-1 flex-col flex-1 mt-3">
							<li className="flex items-center gap-2 opacity-60 group-hover:opacity-100 transition-all">
								<i className="far fa-check"></i>
								<p className="font-regular">
									{t(
										tenant.slug === "odylyt"
											? `odylyt.${type}.usps.one`
											: `${type}.usps.one`
									)}
								</p>
							</li>

							<li className="flex items-center gap-2 opacity-60 group-hover:opacity-100 transition-all">
								<i className="far fa-check"></i>
								<p className="font-regular">
									{t(
										tenant.slug === "odylyt"
											? `odylyt.${type}.usps.two`
											: `${type}.usps.two`
									)}
								</p>
							</li>
							{type === "student" && (
								<li className="flex items-center gap-2 opacity-60 group-hover:opacity-100 transition-all">
									<i className="far fa-check"></i>
									<p className="font-regular">
										{t(
											tenant.slug === "odylyt"
												? `odylyt.${type}.usps.three`
												: `${type}.usps.three`
										)}
									</p>
								</li>
							)}
						</ul>
					)}
				</div>
			</Card>
		);
	},
	{
		nl: {
			company: {
				title: "Aanmelden als bedrijf",
				usps: {
					one: "Taken uitbesteden",
					two: "Pool met professionals opbouwen",
					// three: "Pool met professionals opbouwen",
				},
				button: "Ik wil inzicht in mijn taken",
			},
			student: {
				title: "Aanmelden als (young) professional",
				usps: {
					one: "Opdrachten uitvoeren voor bedrijven",
					two: "Geld verdienen naast je studie",
					three: "Werken waar en wanneer je wilt",
				},
				button: "Ik wil bijverdienen",
			},
			freelancer: {
				title: "ZZP'er",
				usps: {
					one: "Opdrachten uitvoeren voor bedrijven",
					two: "Geld verdienen naast je studie",
					three: "Werken waar en wanneer je wilt",
				},
				button: "Ik wil bijverdienen",
				disclaimer: "Wanneer je een KVK inschrijving hebt",
			},
			odylyt: {
				student: {
					title: "Persoonlijk",
					description: "Ik beheer mijn taken alleen",
					usps: {
						one: "Takenlijst bijhouden",
						two: "Inzicht in je persoonlijke groei",
						three: "Verschillenden projecten",
					},
					button: "Start met taken bijhouden",
				},
				company: {
					title: "Team",
					description: "Ik werk samen met een team",
					usps: {
						one: "Inzicht in interne taken",
						two: "Inzicht in persoonlijke groei",
						three: "Samenwerken met collega's",
					},
					button: "Start met taken bijhouden",
				},
			},
		},
		en: {
			company: {
				title: "Signup as company",
				usps: {
					one: "Outsource tasks",
					two: "Build a pool of professionals",
				},
				button: "I want insight into my tasks",
			},
			student: {
				title: "Earn Extra as a professional",
				usps: {
					one: "Perform tasks for companies",
					two: "Earn money alongside your studies",
					three: "Work where and when you want",
				},
				button: "I want to earn extra",
			},
			freelancer: {
				title: "Signup as (young) professional",
				usps: {
					one: "Perform tasks for companies",
					two: "Earn money alongside your studies",
					three: "Work where and when you want",
				},
				button: "I want to earn extra",
				disclaimer: "When you have a Chamber of Commerce registration",
			},
			odylyt: {
				student: {
					title: "Personal",
					description: "I manage my tasks alone",
					usps: {
						one: "Keep track of task lists",
						two: "Insight into your personal growth",
						three: "Manage projects",
					},
					button: "Start tracking tasks",
				},
				company: {
					title: "Team",
					description: "I manage my tasks with a team",
					usps: {
						one: "Insight into internal tasks",
						two: "Insight into personal growth",
						three: "Collaborate with colleagues",
					},
					button: "Start tracking tasks",
				},
			},
		},
	}
);

SetupTypeView.locale = {
	nl: {
		title: "Account type",
		// description: "Binnen {{tenant.name}} kan je als veel "
		continue: "Doorgaan",
	},
	en: {
		title: "Account type",
		continue: "Continue",
	},
};

export default withTranslation(SetupTypeView);
