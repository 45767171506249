import React from "react";
import moment from "moment";
import { ApiPayment } from "src/api/types";
import { formatPrice } from "src/lib/formatters";
import Card from "src/components/Card";
import Skeleton from "src/components/Skeleton";
import CheckoutSummaryItem from "./CheckoutSummaryItem";
import useQueryString from "src/hooks/useQueryString";
import BackButton from "src/components/BackButton";
import { useTranslation } from "react-i18next";

interface CheckoutSummaryProps {
	payment: ApiPayment;
}

const CheckoutSummary = ({ payment }: CheckoutSummaryProps) => {
	const { t } = useTranslation(["payments", ""]);
	const query = useQueryString();
	const subtotal = payment?.price + (payment?.partner_fee || 0);
	const totals = {
		subtotal,
		vat: subtotal * payment.btw,
		total: payment?.total,
	} as any;

	const vatPercentage = payment.btw * 100;
	if (!payment) {
		return <CheckoutSummaryLoading />;
	}
	return (
		<Card className="md:min-h-[40vh]">
			{query?.back && (
				<div className="mb-8">
					<BackButton label="Terug" type="light" />
				</div>
			)}

			<div className="mb-8">
				<h2>
					{t("checkout-summary.title", {
						ns: "payments",
						invoice_number: payment.invoice_number
							? `: #${payment.invoice_number}`
							: "",
					})}
				</h2>
				<small className="opacity-80">
					{t("labels.created-at", {
						ns: "common",
					})}
					: {moment(payment.created_at).format("DD-MM-YYYY")}
				</small>
			</div>

			<strong className="mb-2">
				{t("checkout-summary.products", {
					ns: "payments",
				})}
			</strong>
			<hr className="opacity-20" />
			<div className="flex py-4 gap-3 flex-col flex-1">
				{payment.lines?.map((line, index) => (
					<CheckoutSummaryItem
						key={`${index}-${line.description}`}
						{...{ line }}
					/>
				))}
				{payment.partner_fee ? (
					<CheckoutSummaryItem
						line={{
							description: t("checkout-summary.partner-fee"),
							price: payment?.partner_fee || 0,
						}}
					/>
				) : (
					false
				)}
			</div>
			<hr className="border-dashed opacity-20" />
			<div className="flex flex-col justify ml-auto items-end mt-8">
				<div className="grid grid-cols-2 gap-3">
					<p>
						{t("checkout-summary.subtotal", {
							ns: "payments",
						})}
					</p>
					<p className="text-right">{formatPrice(totals.subtotal)}</p>
					<p className="mb-0">
						{t("checkout-summary.vat", {
							ns: "payments",
							vat: vatPercentage,
						})}
					</p>
					<p className="text-right">{formatPrice(totals.vat)}</p>
					<p className="mb-0">
						{t("checkout-summary.total", {
							ns: "payments",
						})}
					</p>
					<p className="text-right">{formatPrice(totals.total)}</p>
				</div>
			</div>
		</Card>
	);
};

export const CheckoutSummaryLoading = () => (
	<Card>
		<div className="mb-8">
			<Skeleton className="h-6 w-2/4 mb-2 bg-dark-900" />
			<Skeleton className="h-6 w-3/4 bg-dark-900" />
		</div>

		<Skeleton className="h-6 w-1/4 bg-dark-900" />
		<div className="flex mt-2 gap-2 flex-col flex-1">
			{new Array(4).fill("").map((_, index) => (
				<div
					key={`skeleton-${index}`}
					className="flex justify-between items-center gap-4"
				>
					<Skeleton className="h-6 w-2/4 bg-dark-900" />
					<Skeleton className="h-6 w-[20px] bg-dark-900" />
				</div>
			))}
		</div>
		<div className="flex flex-col mr-auto items-end mt-8">
			<div className="grid grid-cols-[90px,60px] gap-2">
				<Skeleton className="h-6 w-[90px] bg-dark-900" />
				<Skeleton className="h-6 w-[60px] bg-dark-900" />
				<Skeleton className="h-6 w-[90px] bg-dark-900" />
				<Skeleton className="h-6 w-[60px] bg-dark-900" />
				<Skeleton className="h-6 w-[90px] bg-dark-900" />
				<Skeleton className="h-6 w-[60px] bg-dark-900" />
			</div>
		</div>
	</Card>
);

export default CheckoutSummary;
