import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tab from "src/components/layout/Tab";
import useRememberState from "src/hooks/useRememberState";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

function useTabState(defaultValue: string, key?: string) {
	if (key) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useRememberState(key, defaultValue);
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useState(defaultValue);
}

interface TabsProps {
	tabs: string[];
	defaultTab?: string;
	children?: (activeTab: string) => any | JSX.Element | JSX.Element[];
	onChange?: (activeTab: string) => void;
	changeType?: "navigate" | "state";
	variant: "dark" | "light";
	renderTab?: (text: string) => any;
	actions?: {
		left?: any;
		right?: any;
	};
	options?: {
		noBackground?: boolean;
		scrollToActive?: boolean;
		small?: boolean;
		rememberKey?: string;
		variant?: "dropdown";
		noSpacer?: boolean;
	};
	tab?: {
		padding?: string;
	};
	header?: any;
	spacing?: {
		horizontal?: number;
		wrapper?: {
			top?: number;
		};
	};
	className?: string;
	containerClassName?: string;
}

const Tabs = ({
	tabs,
	defaultTab,
	children,
	variant,
	changeType = "navigate",
	renderTab,
	tab: tabProps,
	actions,
	options,
	header,
	spacing,
	onChange,
	className,
	containerClassName,
}: TabsProps) => {
	const [tab, _setTab] = useTabState(
		defaultTab ? defaultTab : tabs[0],
		options?.rememberKey
	);
	const [visible, setVisible] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const size = useWindowSize();
	const activeTabRef = useRef(null);

	const setTab = (tab: string) => {
		if (onChange) {
			onChange(tab);
		}
		_setTab(tab);
	};

	const slug = (str: string) => {
		return str.toLowerCase().split(" ").join("-");
	};

	const handleScrollToActiveTab = () => {
		if (activeTabRef?.current) {
			setTimeout(() => {
				const current = activeTabRef?.current as any;
				current.scrollIntoView({
					behavior: "smooth",
				});
			}, 200);
		}
	};

	const onNavigate = (t: string) => {
		if (options?.rememberKey) {
			const exist = tabs.find((i) => slug(i) === t);
			setTab(exist || tabs[0]);
			if (options?.scrollToActive) {
				handleScrollToActiveTab();
			}
			return;
		}

		if (changeType === "navigate") {
			const path = location.pathname.split("#")[0];
			// return `${path}#${t.toLowerCase()}${location.search || ""}`;
			navigate(`${path}${location.search || ""}#${t.toLowerCase()}`);
		}

		if (changeType === "state") {
			const value = tabs.find((i) => slug(i) === t);
			setTab(value || t);
		}
	};

	useEffect(() => {
		if (options?.scrollToActive) {
			handleScrollToActiveTab();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, tabs, location]);

	useEffect(() => {
		if (!options?.rememberKey) {
			if (location.hash) {
				const hash = location.hash.replace("#", "");
				const exist = tabs.find((i) => slug(i) === hash);
				setTab(exist || defaultTab || tabs[0]);
			} else {
				setTab(defaultTab || tabs[0]);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, tabs]);

	const renderChildren = () => {
		if (typeof children === "function") {
			return children(tab);
		}
		return children;
	};

	if (size.isTablet || options?.variant === "dropdown") {
		let activeTab = tabs.find((t) => t === tab);
		return (
			<div className="flex flex-col flex-1 w-full relative">
				<div
					className={cn(
						"flex flex-col rounded-md bg-accent border border-border",
						visible && "rounded-t-md rounded-b-none",
						spacing?.horizontal && `mx-${spacing?.horizontal}`
					)}
				>
					<div
						onClick={() => setVisible(!visible)}
						className={`px-4 py-2 gap-2 flex items-center justify-between cursor-pointer`}
					>
						<strong>{activeTab}</strong>
						<i
							className={`fas fa-angle-${
								visible ? "up" : "down"
							}`}
						></i>
					</div>
					<AnimatePresence>
						{visible && (
							<motion.div
								className={cn(
									"absolute top-[43px] rounded-b-md left-0 right-0 z-40 bg-accent border-l border-r border-b border-border overflow-hidden",
									spacing?.horizontal &&
										`mx-${spacing?.horizontal}`
								)}
								initial={{ opacity: 0, y: -20 }}
								exit={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
							>
								{tabs.map((t) => {
									if (t === tab) {
										return <></>;
									}
									return (
										<div
											key={tab}
											onClick={() => {
												onNavigate(slug(t));
												setVisible(false);
											}}
											className="border-b hover:bg-border cursor-pointer px-4 py-2 border-accent"
										>
											{t}
										</div>
									);
								})}
							</motion.div>
						)}
					</AnimatePresence>
				</div>
				<div className="mt-8 empty:hidden">{renderChildren()}</div>
			</div>
		);
	}

	return (
		<div className={cn("flex flex-col flex-1 w-full relative", className)}>
			<TabContainer className={cn("w-full", containerClassName)}>
				{actions?.left}
				{tabs.map((t) => (
					<div key={t} ref={tab === t ? activeTabRef : null}>
						<Tab
							active={tab === t}
							onClick={() => onNavigate(slug(t))}
							small={options?.small}
							{...tabProps}
						>
							{renderTab ? renderTab(t) : t}
						</Tab>
					</div>
				))}
				{!options?.noSpacer && (
					<>
						{spacing?.horizontal ? (
							<div
								className={`w-${spacing?.horizontal} block`}
							></div>
						) : (
							<div className="flex-1" />
						)}
					</>
				)}
				{actions?.right}
			</TabContainer>
			{header}
			{children && (
				<div
					className={`mt-${
						spacing?.wrapper?.top || 4
					} w-full flex flex-col flex-1`}
				>
					{renderChildren()}
				</div>
			)}
		</div>
	);
};

Tabs.defaultProps = {
	variant: "light",
};

interface TabContainerProps {
	className?: string;
	children?: any;
}

const TabContainer = ({ className, children }: TabContainerProps) => {
	return (
		<div
			className={cn(
				"flex flex-row items-center gap-2 p-1 overflow-x-auto w-auto rounded-md bg-accent dark:bg-transparent dark:p-0",
				className
			)}
		>
			{children}
		</div>
	);
};

Tabs.Container = TabContainer;

export default Tabs;
