import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeCardContext from "../ResumeCardContext";
import Card from "src/components/Card";
import Slider from "src/components/slider/Slider";
import ReadMore from "src/components/ReadMore";
import useWindowSize from "src/hooks/useWindowSize";
import ResumeEditButton from "src/components/ResumeCompanyUser/resume-card/ResumeEditButton";
import TimelineItem from "src/components/Timeline/TimelineItem";
import ResumeSectionTitle from "src/components/ResumeCompanyUser/resume-card/ResumeSectionTitle";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface ResumeWorkProps extends Translation {
	editable?: boolean;
	variant?: "slider" | "timeline";
}

function ResumeWork({ t, editable, variant = "slider" }: ResumeWorkProps) {
	const auth = useAuth();
	const { resume } = useResumeCardContext();
	const experiences = resume?.job_experiences || [];
	const { isPhone } = useWindowSize();
	const color = resume?.primary_color;

	if (!resume.job_experiences.length) {
		if (editable && resume?.id === auth.id) {
			return (
				<div
					className={cn(
						"flex flex-col text-center px-4 py-8 md:p-8 rounded-md justify-center items-center"
					)}
				>
					<h3 className="mb-2">{t("empty.title")}</h3>
					<p className="">{t("empty.description")}</p>
					{editable && (
						<ResumeEditButton
							type="button"
							variant="work"
							className="mt-6"
						>
							<i className="fas fa-plus mr-1"></i>
							{t("empty.button")}
						</ResumeEditButton>
					)}
				</div>
			);
		}
		return <></>;
	}

	if (variant === "timeline") {
		return (
			<div className="flex flex-col gap-4">
				<div className="flex justify-between items-center mb-2">
					<ResumeSectionTitle className="">
						{t("title")}
					</ResumeSectionTitle>
					{editable && <ResumeEditButton variant="work" />}
				</div>
				<div className="flex flex-col">
					{experiences.map((item: any, index) => (
						<TimelineItem
							key={`experience-${item.id}`}
							date={{
								start: item.started,
								end: item?.ended,
							}}
							title={item.title}
							subtitle={item.subtitle}
							type={{
								first: index === 0,
								last: experiences.length === (index || 0) + 1,
							}}
							{...{ color }}
							// actions={
							// 	editable && (
							// 		<div className="flex items-center">
							// 			<ResumeEditButton variant="work" />
							// 		</div>
							// 	)
							// }
						/>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className={`flex flex-col gap-4`}>
			<Slider
				options={{
					slides: 3,
					gap: 12,
				}}
			>
				<div className="flex items-center justify-between mb-4">
					<ResumeSectionTitle className="">
						{t("title")}
					</ResumeSectionTitle>
					<div className="flex items-center gap-4">
						{editable && <ResumeEditButton variant="work" />}
						{(experiences.length > 3 ||
							(isPhone && experiences.length > 1)) && (
							<div className="flex items-center gap-3">
								<Slider.Button
									direction="previous"
									iconOnly
									type="dark"
								>
									<i className="far fa-arrow-left"></i>
								</Slider.Button>
								<Slider.Button
									direction="next"
									iconOnly
									type="dark"
								>
									<i className="far fa-arrow-right"></i>
								</Slider.Button>
							</div>
						)}
					</div>
				</div>

				<Slider.Items>
					{experiences.map((item: any) => (
						<Card key={item.id}>
							<div className="flex flex-col">
								<small>{item.title}</small>
								<div className="flex mt-2 justify-between items-center">
									<h4 className="mb-0">{item.subtitle}</h4>
									<small>{`${item.started}-${
										item.ended ? item.ended : "heden"
									}`}</small>
								</div>
								{item.description && (
									<ReadMore
										className="mt-2"
										text={item.description}
										length={120}
									/>
								)}
							</div>
						</Card>
					))}
				</Slider.Items>
			</Slider>
		</div>
	);
}

ResumeWork.locale = {
	nl: {
		title: "Werkervaringen",
		empty: {
			title: "Voeg je werkervaring toe",
			description: "Van bijbanen tot stages, voeg ze allemaal toe.",
			button: "Werkervaring toevoegen",
		},
	},
	en: {
		title: "Work experiences",
		empty: {
			title: "Add your work experience",
			description: "From side jobs to internships, add them all.",
			button: "Add work experience",
		},
	},
};

export default withTranslation(ResumeWork);
