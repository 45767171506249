import Card from "src/components/Card";
import Link from "src/components/Link";
import Logo from "src/components/Logo";
import LanguageSelector from "src/components/language/LanguageSelector";
import useTheme from "src/hooks/useTheme";

interface AuthLayoutProps {
	children?: any;
	head?: {
		title?: string;
		description?: string;
		back?: {
			to?: string;
			label?: string;
		};
	};
	variant?: "card" | "flat";
	loading?: boolean;
}

const AuthLayout = ({ children, head, variant = "card" }: AuthLayoutProps) => {
	const { theme } = useTheme();
	return (
		<div className="flex flex-col flex-1 bg-gray-background dark:bg-background">
			<div className="flex items-center justify-center h-16 py-4 ">
				<Logo
					variant="default"
					color={theme === "dark" ? "white" : "dark"}
					className="h-full"
				/>
			</div>
			<div className="flex flex-col flex-1 justify-center items-center px-3 md:px-0">
				{variant === "card" ? (
					<Card className="w-full max-w-[520px]">
						{head && (
							<div className="flex flex-col gap-3 mb-4">
								<div className="flex items-center justify-between mb2">
									<h2>{head.title}</h2>
									<LanguageSelector theme={"transparent"} />
								</div>
								{head?.description && <p>{head.description}</p>}
								{head.back?.to && (
									<Link to={head.back.to}>
										<i className="fal fa-arrow-left"></i>
										{head.back?.label && (
											<span className="ml-2">
												{head.back.label}
											</span>
										)}
									</Link>
								)}
							</div>
						)}

						{children}
					</Card>
				) : (
					children
				)}
			</div>
			<div className="h-16 flex"></div>
		</div>

		// <div className="flex flex-1 flex-col-reverse md:flex-row">
		// 	<div className="bg-background flex-1 w-full md:max-w-[720px] p-8 md:p-16 md:px-24 flex flex-col gap-4">
		// 		{head && (
		// 			<div className="flex items-start pb-8 justify-between md:mb-4">
		// 				<div className="flex flex-col gap-4 md:gap-6">
		// 					<h2>{head.title}</h2>
		// 					{head.back?.to && (
		// 						<Link to={head.back.to}>
		// 							<i className="fal fa-arrow-left"></i>
		// 							{head.back?.label && (
		// 								<span className="ml-2">
		// 									{head.back.label}
		// 								</span>
		// 							)}
		// 						</Link>
		// 					)}
		// 				</div>
		// 				<LanguageSelector theme={"dark"} />
		// 			</div>
		// 		)}
		// 		{loading ? (
		// 			<div className="flex flex-col flex-1 justify-center items-center">
		// 				<Loader />
		// 			</div>
		// 		) : (
		// 			<div className="flex flex-1 flex-col">{children}</div>
		// 		)}
		// 	</div>
		// 	<div className="bg-dark md:flex-1 relative overflow-hidden">
		// 		<div className="flex justify-between p-8 md:p-16">
		// 			<Logo
		// 				variant="default"
		// 				color="white"
		// 				className="max-w-[180px] md:max-w-[320px]"
		// 			/>
		// 		</div>
		// 		<Logo
		// 			variant="small"
		// 			color="primary"
		// 			className="max-w-md absolute -left-16 -bottom-16 opacity-10"
		// 		/>
		// 	</div>
		// </div>
	);
};

export default AuthLayout;
