import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubscriptionProtectButton from "src/components/subscription/subscription-protect/SubscriptionProtectButton";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface ChatButtonProps {
	to: string;
	registrationId: any;
	className?: string;
	xsmall?: boolean;
}

const ChatButton = ({
	to,
	registrationId,
	className,
	xsmall,
}: ChatButtonProps) => {
	const { t } = useTranslation("common");
	const auth = useAuth();
	const [data] = useGet(`/inbox/${registrationId}/unread-count`);
	const { count } = data?.data || {};

	if (auth.type === "company") {
		return (
			<SubscriptionProtectButton
				{...{ className, to, xsmall }}
				type="dark"
				indicator={count}
			>
				<i className={cn("fas fa-comments", !xsmall && "mr-1")}></i>
				{t("chat")}
			</SubscriptionProtectButton>
		);
	}

	return (
		<Button {...{ to, xsmall, className }} type="dark" indicator={count}>
			<i className={cn("fas fa-comments", !xsmall && "mr-1")}></i>

			{t("chat")}
		</Button>
	);
};

export default ChatButton;
