import React, { useState } from "react";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import DebugLanguage from "src/components/_debug/DebugLanguage";
import RoleSwitcher from "src/components/_debug/RoleSwitcher";

const DebugTools = () => {
	const [visible, setVisible] = useState(false);
	const disabled = true;
	if (process.env.NODE_ENV !== "development" || disabled) {
		return <></>;
	}
	return (
		<>
			<div className="fixed rounded-tl-md bottom-0 z-[99] right-0 bg-dark p-4">
				<Button
					onClick={() => {
						setVisible(!visible);
					}}
					iconOnly
				>
					<i className="fas fa-cogs"></i>
				</Button>
			</div>
			<Modal
				{...{ visible }}
				onClose={() => setVisible(false)}
				className=" "
				title="Debug tools"
			>
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-2">
						<h3>Taal aanpassen</h3>
						<DebugLanguage />
					</div>
					<div className="flex flex-col gap-2">
						<h3>Account switchen</h3>
						<RoleSwitcher />
					</div>
				</div>
			</Modal>
		</>
	);
};

export default DebugTools;
