import React, { HTMLAttributes } from "react";
import GeneralInfoForm from "./elements/GeneralInfoForm";
import EducationForm from "./elements/EducationForm";
import WorkExperienceForm from "./elements/WorkExperienceForm";
import SkillForm from "./elements/SkillForm";
import InterestsForm from "src/components/ResumeCompanyUser/elements/InterestsForm";

interface ResumeFormProps extends HTMLAttributes<HTMLFormElement> {}

const ResumeForm = ({ children, ...rest }: ResumeFormProps) => {
	return <form {...rest}>{children}</form>;
};

ResumeForm.GeneralInfo = GeneralInfoForm;
ResumeForm.Education = EducationForm;
ResumeForm.WorkExperience = WorkExperienceForm;
ResumeForm.Skill = SkillForm;
ResumeForm.Interests = InterestsForm;

export default ResumeForm;
