import { useEffect } from "react";
import { ApiCompanyMinimal, ApiUserMinimal } from "src/api/types";
import Button from "src/components/Button";
import FlatList from "src/components/FlatList";
import CompanyCard from "src/components/company-card/CompanyCard";
import CustomFormFilters from "src/components/custom-form/filters/CustomFormFilters";
import SearchInput from "src/components/form/SearchInput";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";
import UserCard from "src/components/user-card/UserCard";
import useConnectionCustomForm from "src/hooks/api/services/forms/useConnectionCustomForm";
import useConnectionFilter from "src/hooks/api/services/users/useConnectionFilter";
import useConnections from "src/hooks/api/services/users/useConnections";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

const DEFAULT_QUERY = {
	with: ["form", "forms", "fields", "values"],
};

function isApiCompanyMinimal(obj: any): obj is ApiCompanyMinimal {
	return (obj as ApiCompanyMinimal).type === "company";
}

function NetworkView({ t }: Translation) {
	const auth = useAuth();
	const { breakpoint } = useWindowSize();
	const { modules } = useModules();
	const { forms, actions: customFormsActions } = useConnectionCustomForm({
		company_id: auth?.company?.id,
	});
	const { filter, setFilter } = useConnectionFilter();
	const { connections, pagination, status, actions } = useConnections({
		filter: { ...DEFAULT_QUERY, ...filter },
		rememberKey: "NETWORK_CONNECTIONS",
	});
	const showFilters = auth.type === "company" && !!forms.length;
	useUpdateEffectDebounce(filter, 1000, (update) => {
		if (auth.type === "company") {
			actions.list(update);
		}
	});

	useEffect(() => {
		if (modules.includes("student-pool") || auth.type === "student") {
			actions.list({ ...DEFAULT_QUERY, ...filter });
		}
		if (auth.type === "company") {
			customFormsActions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modules]);

	const getColumnCount = () => {
		if (showFilters && breakpoint === "md") {
			return 1;
		}
		if (["md", "lg"].includes(breakpoint)) {
			return 2;
		}
		return 3;
	};

	if (auth.type === "company" && !modules.includes("student-pool")) {
		return (
			<Layout
				title={t(`title-${auth.type}`)}
				breadcrumb={[{ label: t("title-company"), to: "/network" }]}
			>
				<SubscriptionFeatureMiddleware plan="premium" />
			</Layout>
		);
	}
	return (
		<Layout
			title={t(`title-${auth.type}`)}
			breadcrumb={[{ label: t(`title-${auth.type}`), to: "/network" }]}
			container={!showFilters ? "xxl" : undefined}
		>
			<div
				className={cn(
					"flex flex-col flex-1",
					showFilters && "grid grid-cols-1 md:grid-cols-4 gap-4"
				)}
			>
				{showFilters && (
					<div className="flex flex-col p-4 gap-6">
						<div className="flex flex-col">
							<SearchInput
								theme="bordered"
								value={filter?.q}
								onChange={(q) => {
									actions.setFilter({
										...filter,
										q,
									});
								}}
							/>
						</div>
						<CustomFormFilters
							value={filter?.form_filters || {}}
							onChange={(form_filters) => {
								setFilter({
									...filter,
									form_filters,
								});
							}}
							type="CONNECTION"
						/>
					</div>
				)}
				<div className="flex flex-col flex-1 col-span-3">
					{!showFilters && auth.type === "company" && (
						<div className="flex flex-col mb-6">
							<SearchInput
								theme="bordered"
								value={filter?.q}
								onChange={(q) => {
									actions.setFilter({
										...filter,
										q,
									});
								}}
							/>
						</div>
					)}
					<FlatList
						loading={status !== "idle"}
						data={connections || []}
						gap={16}
						columns={getColumnCount()}
						empty={{
							title: t("empty"),
						}}
						pagination={{
							page: pagination.page,
							last_page: pagination.last_page,
							setPage: (page) =>
								actions.list({
									...DEFAULT_QUERY,
									...filter,
									page,
								}),
						}}
						renderItem={(
							row: ApiUserMinimal | ApiCompanyMinimal
						) => {
							if (isApiCompanyMinimal(row)) {
								return (
									<CompanyCard
										key={`company-network-${row.id}`}
										company={row}
									>
										<div className="flex gap-2 items-center flex-row justify-between">
											<div className="flex items-center gap-2">
												<CompanyCard.Avatar />
												<div className="">
													<CompanyCard.Name />
												</div>
											</div>
										</div>
										<CompanyCard.Excerpt className="opacity-70" />

										<div className="flex-1">
											<CompanyCard.CustomFormDetails />
										</div>
										<div className="flex gap-3">
											<Button
												xsmall
												to={`/payment-requests?connection_id=${row.id}`}
											>
												{t("buttons.payment-request")}
											</Button>
											{row.form && (
												<Button
													xsmall
													type="border"
													to={{
														modal: `/network/${row.id}/forms/${row.form.id}`,
													}}
												>
													<i className="fas fa-info"></i>
													{t("buttons.form")}
												</Button>
											)}
										</div>
									</CompanyCard>
								);
							}
							return (
								<UserCard
									key={`user-network-${row.id}`}
									user={row}
								>
									<div className="flex gap-2 items-center flex-row justify-between">
										<div className="flex items-center gap-2">
											<UserCard.Avatar />
											<div className="">
												<UserCard.Name />
												<UserCard.Stars />
											</div>
										</div>
										<UserCard.Actions
											onSubmit={async () => {
												await actions.list();
											}}
										/>
									</div>
									<UserCard.Telephone />
									<div className="flex-1">
										<UserCard.CustomForm />
									</div>
									<div className="flex flex-row justify-between items-center">
										<UserCard.Badges />
										<div className="flex gap-4">
											<UserCard.ButtonCreateTask />
											{forms.map((form) => (
												<Button
													key={form.id}
													xsmall
													type="border"
													to={{
														modal: `/network/${row.id}/forms/${form.id}`,
													}}
												>
													<i className="fas fa-info"></i>
													{t("buttons.form")}
												</Button>
											))}
										</div>
									</div>
								</UserCard>
							);
						}}
					/>
				</div>
			</div>
		</Layout>
	);
}

NetworkView.locale = {
	nl: {
		"title-student": "Mijn netwerk",
		"title-company": "Connecties",
		empty: "Geen connecties gevonden",
		buttons: {
			"payment-request": "Betaalverzoek",
			form: "Informatie",
		},
		filters: {
			title: "Filters",
		},
	},
	en: {
		"title-student": "My network",
		"title-company": "Connections",
		empty: "No connections found",
		buttons: {
			"payment-request": "Payment request",
			form: "Information",
		},
	},
};

export default withTranslation(NetworkView);
