import { ComponentProps, useState } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Loader from "src/components/Loader";
import Tag from "src/components/Tag";
import {
	Skill,
	useSkillSelector,
} from "src/components/form/skill-selector/Provider";
import { ucFirst } from "src/lib/formatters";
import { slugify } from "src/lib/slugify";
import { cn } from "src/lib/utils";

const Results = ({ t, className }: Translation & ComponentProps<"div">) => {
	const [open, setOpen] = useState<boolean>(false);
	const { skills, value, onChange, status, search, options, theme } =
		useSkillSelector();

	if (
		!skills.length &&
		search &&
		!value.find((item) => item.slug === slugify(search)) &&
		status === "idle"
	) {
		skills.push({
			slug: slugify(search),
			name: search,
		});
	}

	const isActive = (skill: Skill) =>
		value.find((item) => item.slug === skill.slug);

	const renderItem = (skill: Skill, index: number) => (
		<Tag
			className={cn(
				"font-regular cursor-auto group",
				onChange ? "cursor-pointer overflow-hidden gap-0" : undefined,
				theme?.default?.className,
				isActive(skill)
					? "bg-dark text-white"
					: "bg-accent text-background-foreground",
				isActive(skill)
					? theme?.active?.className
					: theme?.inactive?.className
			)}
			size={
				isActive(skill)
					? theme?.active?.size || theme?.default?.size
					: theme?.inactive?.size || theme?.default?.size
			}
			onClick={
				onChange
					? () => {
							if (isActive(skill)) {
								onChange(
									value.filter(
										(item) => item.slug !== skill.slug
									)
								);
							} else {
								onChange([
									...value,
									{ ...skill, name: ucFirst(skill.name) },
								]);
							}
					  }
					: undefined
			}
			key={`skill-${skill.slug}-${index}`}
		>
			<span>{ucFirst(skill.name)}</span>
			{skill?.score && skill?.score > 1 && (
				<span className="ml-1 text-border">{skill.score}</span>
			)}
			{onChange && options?.methods?.delete && (
				<div className="flex absolute rounded-sm group-hover:right-1 group-hover:scale-100 -right-5 scale-95 w-4 h-4 bg-border text-border-foreground justify-center items-center transition-all">
					<i className="far fa-times text-xs leading-[60%]"></i>
				</div>
			)}
		</Tag>
	);

	return (
		<div
			className={cn(
				"flex gap-2 flex-wrap mb-2 empty:hidden items-center",
				className
			)}
		>
			{status === "loading" && (
				<div className="flex flex-col">
					<Loader />
				</div>
			)}
			{value
				.filter(
					(item) => !skills.find((skill) => skill.slug === item.slug)
				)
				.filter((item, index) =>
					open || !options?.limit ? true : index < options.limit
				)
				.map(renderItem)}
			{skills.map(renderItem)}
			{options?.limit && value.length > options.limit && (
				<>
					<Tag
						size={theme?.default?.size}
						onClick={() => setOpen(!open)}
						className={cn(
							"font-bold hover:bg-dark hover:text-white",
							theme?.default?.className,
							"border border-dark bg-transparent"
						)}
					>
						{!open ? (
							<>
								<i className="far fa-plus text-sm leading-[7px] text-[8px]"></i>
								{value.length - options.limit}
							</>
						) : (
							t("hide")
						)}
					</Tag>
				</>
			)}
		</div>
	);
};

Results.locale = {
	nl: {
		hide: "Toon minder",
	},
	en: {
		hide: "Show less",
	},
};

export default withTranslation(Results);
