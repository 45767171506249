const tasks = {
	alert: {
		success: {
			title: "Task Updated",
			text: "Changes successfully applied",
		},
		error: {
			title: "Something went wrong",
			text: "Check if all fields are correctly filled in",
		},
	},
	"task-button": {
		deleted: "Task no longer exists",
		pending: "Awaiting response",
		accepted: "Task accepted",
		denied: "Not selected",
		"not-signed": "⚠️ Not yet signed",
		messages: "Unread messages",
		// messages_one: "1 unread message",
		// messages_othe: "{{count}} unread messages",
		completed: "Task completed",
		"account-pending": "Your account needs approval",
		expired: "Task expired",
		view: "View application",
		backlog: "Backlog",
	},
	status: {
		open: "Open",
		cancelled: "Cancelled",
		completed: "Completed",
		matched: "Matched",
		"has-messages": "Unread messages",
		"has-registrations": "Open",
		"open-envelope": "Still needs to be signed",
		expired: "Expired",
		concept: "Concept",
		BACKLOG: "Backlog",
		TODO: "To-do",
		IN_PROGRESS: "In progress",
		COMPLETED: "Completed",
		PENDING: "Pending",
		DENIED: "Denied",
		extern: {
			open: "Open",
			cancelled: "Cancelled",
			completed: "Completed",
			matched: "Matched",
			"has-messages": "Unread messages",
			"has-registrations": "Open",
			"open-envelope": "Still needs to be signed",
			expired: "Expired",
			concept: "Concept",
			BACKLOG: "Backlog",
			TODO: "To-do",
			IN_PROGRESS: "In progress",
			COMPLETED: "Completed",
			PENDING: "Pending",
			DENIED: "Denied",
		},
	},
	steps: {
		description: "Task description",
		budget: "Task value",
		location: "Location",
		date: "Start and end date",
		form: "Registration form",
		shifts: "Shifts",
		invite: "Invite professionals",
		interview: "Interview",
		planning: {
			questions: "AI questions",
			budget: "Task value",
			extra: "Extra",
			publish: "Outsource",
		},
	},
	gift: {
		title: "Congratulations",
		description_one: "You get 1 free task gift!",
		description_other: "You get {{count}} free tasks gift!",
		cta_one: "Place task",
		cta_other: "Place tasks",
	},
	"empty-card": {
		title: "Let's make a task!",
		description_one: "The task is easily created in 1 simple step",
		description_other:
			"The task is easily created in {{count}} simple steps",
		instructions: {
			description: "Provide a clear task description",
			budget: "Determine the task value",
			location:
				"Specify the location: from home, in the office, or external",
			interview: "Ask the potential candidate some questions",
			date: "Determine the start date, deadline, and category of the task",
			finish: "We will distribute your task among our professionals",
		},
		cta: "Create first task",
	},
	"delete-task": {
		label: "Delete task",
		confirm: {
			title: "Are you sure",
			description: "You are about to delete {{title}}, are you sure?",
		},
	},
	"denie-button": {
		title: "Deny professional",
		description:
			"Briefly explain why this professional is not suitable, so that we can select better candidates for your tasks in the future.",
		placeholder: "This professional is not suitable because:",
		label: "Motivation",
		confirm: {
			title: "Are you sure",
			description: "You are about to deny {{name}}, are you sure?",
		},
	},
	"publish-draft-modal": {
		title: "Publish or Edit your task",
		"no-results": "No task found",
		keys: {
			"amount-of-students": "Number of professionals",
			budget: "Task value",
			"estimated-hourly-rate": "Estimated hourly rate",
			"per-hour": "Per hour",
			"start-date": "Start date",
			"end-date": "End date",
			date: "Date",
		},
	},
	"publish-invite-task": {
		confirm: {
			title: "Are you sure?",
			description:
				"When you make the task public, all professionals can respond to it",
		},
		success: {
			title: "Task successfully made public",
			description:
				"Keep an eye on your applications to view new applications",
		},
		failed: {
			title: "Something seems to be wrong",
			description: "Try again later",
		},
		button: "Make task public",
		disclaimer: "All professionals can then apply for the task.",
	},
	"student-banner": {
		title: "Create task for:",
	},
	"registration-denie-modal": {
		title: "Reject professional",
		confirm: {
			title: "Are you sure?",
			description:
				"Are you sure you want to reject the registration for the following reason:",
			button: "Confirm rejection",
		},
		description:
			"Briefly explain why this professional is not suitable, this will allow us to better select candidates for your tasks in the future.",
		label: "Motivation",
		placeholder: "This professional is not suitable because:",
	},
	"share-task": {
		title: "Share your task through social media to get more registrations",
		social: {
			title: "I just posted a new task on {{tenant.name}}: {{title}}",
			text: "Do you want to earn €{{budget}}? Then click the link below ({{url}}).",
		},
		labels: {
			facebook: "Share on Facebook",
			linkedin: "Share on LinkedIn",
			twitter: "Share on Twitter",
		},
	},
	"task-card": {
		"unread-messages_one": "{{count}} unread message",
		"unread-messages_other": "{{count}} unread messages",
		home: "Home",
		"estimated-duration": "About {{hour}} hours",
	},
	"task-match-profile": {
		"matched-with": "Matched with",
	},
	"task-match-progress": {
		matched: "Matched",
	},
	"task-table-row": {
		"no-registrations": "No registrations yet",
		"per-hour": "per hour",
		registrations_one: "1 registration",
		registrations_other: "{{count}} registrations",
		types: {
			INTERNAL: "Internal",
			PUBLISHED: "External",
			DRAFT: "Concept",
		},
	},
	"tasks-grid": {
		empty: {
			title: "You have not yet signed up for tasks",
			text: "Search for task",
		},
		header: {
			search: "Search tasks",
			create: "Create task",
		},
		head: {
			student: {
				title: "My tasks",
				subtitle: "You have applied for those tasks",
			},
		},
	},
	"tasks-table": {
		headers: {
			status: "Status",
			task: "Task",
			registrations: "Registrations",
			budget: "Budget",
			date: "Date",
			actions: "Actions",
			type: "Type",
			company: "Company",
		},
		head: {
			company: {
				title: "Tasks",
				subtitle: "All tasks that are made by your organization",
			},
		},
		empty: {
			extern: {
				student: {
					title: "You haven't signed up for any assignments yet",
					text: "Find assignments",
				},
				company: {
					title: "You haven't created any assignments yet",
					text: "Easily create an assignment",
					button: "Place Assignment",
				},
			},
			intern: {
				student: {
					title: "You don't have any tasks yet",
					text: "Create a task",
				},
				company: {
					title: "You don't have any tasks yet",
					text: "Create a task",
				},
			},
		},
	},
	"task-info-card": {
		home: "Home",
		deadline: "Deadline",
		hour: "Hour",
		"per-hour": "/ hour",
	},
	"task-company-head": {
		repeat: "Repeat task",
	},
	"task-invites": {
		empty: {
			title: "You haven't invited anyone yet",
		},
		headers: {
			student: "Professional",
			status: "Status",
			registered: "Registered",
			"invited-on": "Invited on",
		},
		yes: "Yes",
		no: "No",
		status: {
			ACCEPTED: "Accepted",
			READ: "Viewed",
			DENIED: "Denied",
			OPEN: "Open",
		},
	},
	"statistics-pane": {
		"before-release":
			"Since this task was made before April 10, 2022, the display may not be accurate",
		views: "Views",
		"first-registration": "First registration",
		registrations: "Registrations",
	},
};
export default tasks;
