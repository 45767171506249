import React from "react";
import { motion } from "framer-motion";
import useAuth from "src/hooks/selectors/useAuth";
import Button from "src/components/Button";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Card from "src/components/Card";

const SubscriptionPlanCustom = ({ t }: Translation) => {
	const auth = useAuth();
	const { tenant } = useTenant();

	if (tenant.slug === "getcrewdone") {
		return (
			<motion.div
				animate={{
					opacity: 1,
					transition: {
						delay: 1.5,
					},
				}}
				initial={{
					opacity: 0,
				}}
				className="flex justify-center items-center p-12"
			>
				<Card
					className="max-w-md w-full text-white"
					contentClassName="bg-dark flex flex-col gap-4"
				>
					<h2>{t("getcrewdone.title")}</h2>
					<p>{t("getcrewdone.description")}</p>
					<Button
						href={`mailto: j.hopman@${
							tenant?.urls?.domain
						}?subject=${t("subject", {
							name: auth?.company?.name,
						})}`}
					>
						<i className="fas fa-envelope mr-2"></i>
						{t("button")}
					</Button>
				</Card>
			</motion.div>
		);
	}

	return (
		<motion.div
			animate={{
				opacity: 1,
				transition: {
					delay: 1.5,
				},
			}}
			initial={{
				opacity: 0,
			}}
			className="mt-16 bg-dark-1000 p-10 flex flex-col justify-center items-center text-center max-w-[800px] mx-auto rounded-md"
		>
			<h2 className="text-2xl mb-2">{t("title")}</h2>
			<p className="text-center mb-6 max-w-md mx-auto">
				{t("description")}
			</p>
			<Button
				href={`mailto: j.hopman@${tenant?.urls?.domain}?subject=${t(
					"subject",
					{
						name: auth?.company?.name,
					}
				)}`}
			>
				<i className="fas fa-envelope mr-2"></i>
				{t("button")}
			</Button>
		</motion.div>
	);
};

SubscriptionPlanCustom.locale = {
	nl: {
		title: "Toch niet helemaal wat je zoekt?",
		description:
			"Neem dan contact met ons op! Een medewerker van {{tenant.name}} kijkt samen met jou naar de mogelijkheden.",
		button: "Contact opnemen",
		subject: "Abonnement op maat: {{name}}",
		getcrewdone: {
			title: "Abonnement op maat",
			description:
				"Zoek je net wat meer voor je organisatie? We denken graag mee hoe we jullie kunnen ontzorgen. We maken graag een abonnement die volledig bij jouw organisatie past.",
		},
	},
	en: {
		title: "Not quite what you're looking for?",
		description:
			"Feel free to get in touch! An {{tenant.name}} team member will explore potential options with you.",
		button: "Contact opnemen",
		subject: "Custom subscription: {{name}}",
	},
};

export default withTranslation(SubscriptionPlanCustom);
