import { useEffect } from "react";
// import FPSStats from "react-fps-stats";
import ReactDom from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { Provider as JotaiProvider } from "jotai";
// import { createBrowserHistory } from "history";
import { store as jotaiStore } from "./lib/jotai";
import store from "./redux";
import Routes from "./routes";
//Import the global styles
import "./lib/api";
import "./lib/i18n";
import "./lib/sentry";
import "src/lib/mixpanel";
// import "./assets/styles/tailwind.generated.css";
import "./assets/styles/tailwind.scss";
import "./assets/styles/config.scss";
import "./assets/styles/template.scss";
import "./assets/styles/description.scss";
import "./assets/styles/typography.scss";
import "./assets/styles/scrollbar.scss";
import AlertContainer from "./components/AlertContainer";
import useAnalytics from "./hooks/useAnalytics";
import facebookInit from "./lib/facebookInit";
import useRedirect from "./hooks/useRedirect";
import withClearCache from "./lib/withClearCache";
import Head from "src/components/Head";
import useTokenListener from "src/hooks/useTokenListener";
import { useThemeListener } from "src/hooks/useTheme";
import useLatestBuild from "src/hooks/useCurrentVersion";
import DebugTools from "src/components/_debug/DebugTools";
import useNotificationAnalytics from "src/hooks/useNotificationAnalytics";
import MaintenanceView from "src/views/global/MaintenanceView";
import TenantProvider from "src/components/providers/TenantProvider";
import useAuth from "src/hooks/selectors/useAuth";
import TaskFormTitleModal from "src/components/tasks/TaskForm/modals/TaskFormTitleModal";
import ListenerProvider from "src/components/providers/ListenerProvider";
import SubscriptionProtectModal from "src/components/subscription/subscription-protect/SubscriptionProtectModal";
import useStoreSearchParams from "src/hooks/useStoreSearchParams";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import { cn } from "src/lib/utils";
import PosthogProvider from "src/components/providers/PosthogProvider";
import { usePostHog } from "posthog-js/react";

const IS_MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true";

declare global {
	interface Window {
		google: any;
		fbAsyncInit: any;
		FB: any;
		dataLayer: any;
	}
}

if (process.env.NODE_ENV !== "development") {
	facebookInit();
}

const TokenListener = () => {
	useTokenListener();
	return <></>;
};

const AnalyticsProvider = () => {
	useAnalytics();
	useRedirect();
	useNotificationAnalytics();
	useStoreSearchParams();
	const location = useLocation();
	const posthog = usePostHog();

	useEffect(() => {
		// mixpanel.pageview();
		posthog?.capture("$pageview");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return <></>;
};

const ModalListener = () => {
	const location = useLocation();
	useEffect(() => {
		const body = document.querySelector("body");
		const modal = document.querySelector("#modal");
		if (!modal) {
			body?.classList?.remove("overflow-hidden");
		}
		if (window.self !== window.top) {
			body?.classList?.add("overflow-hidden");
		}
	}, [location]);
	return <></>;
};

const AppRoutes = () => {
	useThemeListener();
	const build = useLatestBuild();
	const auth = useAuth();
	const permissions = usePermissions();

	useEffect(() => {
		if (process.env.NODE_ENV !== "development") {
			console.log(`Build version ${build}`);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<main
			className={cn(
				"bg-gray-background dark:bg-background flex flex-col flex-1"
			)}
		>
			<BrowserRouter>
				<Routes>
					{auth?.type === "company" && (
						<>
							<SubscriptionProtectModal />
						</>
					)}
					{permissions.has("tasks.create") && <TaskFormTitleModal />}
					{auth?.id && <ListenerProvider />}
					<AnalyticsProvider />
					<AlertContainer />
					<TokenListener />
					<DebugTools />
					<ModalListener />
				</Routes>
			</BrowserRouter>
		</main>
	);
};

const App = () => {
	return (
		<TenantProvider>
			<AppRoutes />
		</TenantProvider>
	);
};

const ClearedCacheApp = withClearCache(
	IS_MAINTENANCE_MODE ? MaintenanceView : App
);

const element = document.getElementById("root");
if (element) {
	const root = ReactDom.createRoot(element);

	root.render(
		<PosthogProvider>
			<JotaiProvider store={jotaiStore}>
				<Provider store={store}>
					<>
						<Head />

						{process.env.NODE_ENV === "production" ? (
							<ClearedCacheApp />
						) : IS_MAINTENANCE_MODE ? (
							<MaintenanceView />
						) : (
							<App />
						)}
					</>
					{/* {process.env.NODE_ENV === "development" && (
					<FPSStats top="auto" right="0" bottom="0" left="auto" />
				)} */}
				</Provider>
			</JotaiProvider>
		</PosthogProvider>
	);
}
