import Avatar from "src/components/Avatar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeCardContext from "../ResumeCardContext";
import useWindowSize from "src/hooks/useWindowSize";

function ResumePublicHead({ t }: Translation) {
	const { isPhone } = useWindowSize();
	const { resume } = useResumeCardContext();

	return (
		<div className="flex flex-col md:flex-row gap-4">
			<div className="-mt-[60px] md:-mt-[100px] relative">
				<Avatar
					size={isPhone ? "large" : "resume"}
					src={resume.avatar}
				/>
			</div>
			<div className="md:ml-4 flex flex-col gap-2">
				<h1>
					{resume.full_name}
					<span className="absolute ml-2 md:ml-2">👋</span>
				</h1>
				<p>{resume.tagline}</p>
			</div>
		</div>
	);
}

export default withTranslation(ResumePublicHead);
