import moment from "moment";
import { useMemo, useState } from "react";
import { ApiRegistration } from "src/api/types";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tab from "src/components/layout/Tab";
import Tabs from "src/components/layout/Tabs";
import RegistrationButtons from "src/components/tasks/panes/registration/RegistrationButtons";
import RegistrationDetails from "src/components/tasks/panes/registration/RegistrationDetails";
import RegistrationReviews from "src/components/tasks/panes/registration/RegistrationReviews";
import RegistrationTaskDetails from "src/components/tasks/panes/registration/RegistrationTaskDetails";
import RegistrationContactDetails from "src/components/tasks/panes/registration/student/RegistrationContactDetails";
import ChatButton from "src/components/tasks/TaskForm/elements/ChatButton";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useBackUrl from "src/hooks/useBackUrl";
// import useTask from "src/hooks/api/tasks/useTask";

interface RegistrationMatchProps extends Translation {
	registration: ApiRegistration;
}

const RegistrationMatch = ({ t, registration }: RegistrationMatchProps) => {
	const backUrl = useBackUrl();
	const isConnected = registration?.is_connected;
	const { task } = useTask({
		id: registration?.task?.hid,
	});
	const tabs = [t("tabs.information"), t("tabs.registration")];
	const [activeTab, setActiveTab] = useState(tabs[0]);

	const paymentRequestUrl = useMemo(() => {
		if (isConnected) {
			const q = new URLSearchParams();
			q.append("connection_id", task.company?.id);
			q.append("task_id", task.hid);
			q.append(
				"description",
				t("payment-request.description", {
					task: task.parent
						? `${task.parent.title}: ${task.title}`
						: task.title,
				})
			);
			return `/payment-requests/create?${backUrl}&${q.toString()}`;
		}
	}, [
		backUrl,
		isConnected,
		t,
		task.company?.id,
		task.hid,
		task.parent,
		task.title,
	]);

	const renderTab = () => {
		if (activeTab === t("tabs.registration")) {
			return <RegistrationDetails {...{ registration }} />;
		}

		if (activeTab === t("tabs.reviews")) {
			return <RegistrationReviews {...{ registration }} />;
		}

		if (activeTab === t("tabs.contact")) {
			return (
				<RegistrationContactDetails
					contact={task.contact}
					subject={t("contact.subject", {
						title: task.title,
					})}
				/>
			);
		}

		return <RegistrationTaskDetails {...{ registration }} />;
	};

	if (task?.contact) {
		tabs.push(t("tabs.contact"));
	}

	if (registration?.is_accepted) {
		tabs.push(t("tabs.reviews"));
	}

	return (
		<div className="flex flex-col gap-6 mt-8">
			{registration?.is_denied && (
				<Alert type="danger">{t("denied")}</Alert>
			)}

			<div className="flex flex-col md:flex-row gap-4 justify-between">
				<Tabs.Container>
					{tabs.map((tab) => (
						<Tab
							key={tab}
							active={tab === activeTab}
							onClick={() => setActiveTab(tab)}
						>
							{tab}
						</Tab>
					))}
				</Tabs.Container>
				<div className="flex items-center gap-3">
					{task.variant === "HOUR_RATE" &&
						registration.status === "matched" && (
							<Button
								to={`/hours/${task.hid}/show`}
								xsmall
								disabled={
									!moment(task.end_date).isBefore(moment())
								}
							>
								<i className="fas mr-2 fa-alarm-clock"></i>
								{t("tabs.hours")}
							</Button>
						)}
					{isConnected && paymentRequestUrl && (
						<Button
							xsmall
							to={{
								modal: paymentRequestUrl,
							}}
						>
							{t("tabs.payment-request")}
						</Button>
					)}
					<ChatButton
						xsmall
						to={`/tasks/${task.hid}/show/${registration?.hid}/chat`}
						registrationId={registration?.id}
					/>
				</div>
			</div>

			{renderTab()}

			<hr className="border-dark opacity-10" />
			<RegistrationButtons {...{ registration }} />
		</div>
	);
};

RegistrationMatch.locale = {
	nl: {
		tabs: {
			information: "Informatie",
			registration: "Aanmelding",
			contact: "Contactgegevens",
			reviews: "Reviews",
			hours: "Uren opgeven",
			"payment-request": "Betaalverzoek",
		},
		denied: "Helaas ben je niet uitgekozen voor de job",
		contact: {
			subject: "Vraag over {{title}}",
		},
		"payment-request": {
			description: "Opdracht: {{task}}",
		},
	},
	en: {
		tabs: {
			information: "Information",
			registration: "Registration",
			contact: "Contact information",
			reviews: "Reviews",
			hours: "Submit hours",
			"payment-request": "Payment request",
		},
		denied: "Unfortunately, you were not chosen for the job",
		contact: {
			subject: "Question about {{title}}",
		},
		"payment-request": {
			description: "Task: {{task}}",
		},
	},
};

export default withTranslation(RegistrationMatch);
