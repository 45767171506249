import React from "react";
import { ApiStudentTimesheetStatus } from "src/api/types";
import Badge from "src/components/Badge";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

const styles: any = {
	unknown: {
		bg: "gray",
		color: "dark",
	},
	pending: {
		bg: "gray",
		color: "dark",
	},
	disapproved: {
		bg: "red-500",
		color: "red",
	},
	approved: {
		bg: "green-500",
		color: "green",
	},
	processed: {
		bg: "dark",
		color: "white",
	},
};

interface TimesheetStatusProps extends Translation {
	status: ApiStudentTimesheetStatus;
}

const TimesheetStatus = ({ t, status }: TimesheetStatusProps) => {
	return <Badge {...styles[status]}>{t(status)}</Badge>;
};

TimesheetStatus.locale = {
	nl: {
		unknown: "Nog niet ingevuld",
		pending: "In afwachting",
		disapproved: "Afgekeurd",
		approved: "Goedgekeurd",
		processed: "Verwerkt",
	},
	en: {
		unknown: "Not submitted",
		pending: "Pending",
		disapproved: "Denied",
		approved: "Approved",
		processed: "Processed",
	},
};

export default withTranslation(TimesheetStatus);
