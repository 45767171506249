import { useEffect, useState } from "react";
import Button from "src/components/Button";
import Card from "src/components/Card";
import KeyValue from "src/components/KeyValue";
import Loader from "src/components/Loader";
import CheckoutPaymentMethods from "src/components/checkout/CheckoutPaymentMethods";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import SubscriptionFeatureMiddleware from "src/components/middlewares/SubscriptionFeatureMiddleware";
import PaymentRequestCheckoutTable from "src/components/payment-request/visualizations/PaymentRequestCheckoutTable";
import { Switch } from "src/components/ui/switch";
import usePaymentRequestsCheckout from "src/hooks/api/services/payment-requests/usePaymentRequestsCheckout";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";
import useSignature from "src/hooks/selectors/useSignature";
import useQueryString from "src/hooks/useQueryString";
import { formatPrice } from "src/lib/formatters";

const PaymentRequestCheckoutView = ({ t }: Translation) => {
	const qs = useQueryString();
	const ids = qs.ids as any;
	const auth = useAuth();
	const { signature } = useSignature();
	const { modules, loading: loadingModules } = useModules();
	const [isSignAutomatic, setIsSignAutomatic] = useState(
		signature?.id ? true : false
	);
	const { status, totals } = usePaymentRequestsCheckout({ ids });

	useEffect(() => {
		if (signature?.id) {
			setIsSignAutomatic(true);
		}
	}, [signature]);

	if (
		auth.type === "company" &&
		!modules.includes("payment-requests") &&
		!loadingModules
	) {
		return (
			<Layout
				title={t("title")}
				breadcrumb={[
					{
						label: t("processTitle"),
						to: "/payment-requests/process",
					},
					{
						label: t("title"),
						to: "/payment-requests/process/checkout",
					},
				]}
			>
				<SubscriptionFeatureMiddleware plan="premium" />
			</Layout>
		);
	}

	return (
		<Layout
			title={t("title")}
			breadcrumb={[
				{
					label: t("processTitle"),
					to: "/payment-requests/process",
				},
				{
					label: t("title"),
					to: "/payment-requests/process/checkout",
				},
			]}
		>
			{!ids || !Array.isArray(ids) || ids?.length === 0 ? (
				<div className="flex flex-col flex-1 justify-center items-center">
					<div className="flex flex-col text-center">
						<strong>{t("empty.title")}</strong>
						<p className="opacity-70 max-w-[460px] text-center">
							{t("empty.description")}
						</p>
						<div className="flex items-center justify-center gap-3 mt-4">
							<Button to="/payment-requests/process">
								{t("buttons.select-payments")}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className="grid grid-cols-3 gap-12 flex-1">
					<PaymentRequestCheckoutTable
						className="col-span-2"
						{...{ ids }}
					/>
					<div className="flex flex-col gap-4">
						<Card className="">
							<strong>{t("summary.title")}</strong>

							{status === "loading" ? (
								<div className="flex flex-col items-center justify-center p-4">
									<Loader />
								</div>
							) : (
								<div className="flex flex-col flex-1 gap-2">
									<KeyValue.Small
										label={t("keys.hours.label")}
										text={t("keys.hours.value", {
											count: totals.hours,
										})}
									/>
									<KeyValue.Small
										label={t("keys.price.label")}
										text={formatPrice(totals.price)}
									/>
									<KeyValue.Small
										label={t("keys.fee.label", {
											rate: auth.service_rate * 100,
										})}
										text={formatPrice(totals.fee)}
									/>
									<div className="flex flex-col">
										<KeyValue.Small
											label={t("keys.vat.label")}
											text={formatPrice(totals.vat)}
										/>
										<small className="opacity-70">
											{t("keys.vat.help")}
										</small>
									</div>
									<hr className="border-border" />
									<KeyValue.Small
										label={t("keys.total.label")}
										text={formatPrice(totals.total)}
									/>

									<CheckoutPaymentMethods
										className="mt-4"
										methods={[
											"ideal",
											"invoice",
											"mandate",
										]}
										onSubmit={console.log}
										extra={
											<>
												<div
													onClick={() =>
														setIsSignAutomatic(
															!isSignAutomatic
														)
													}
													className="flex gap-2 items-center"
												>
													<Switch
														checked={
															isSignAutomatic
														}
													/>
													<div className="flex flex-1">
														<p>
															{t(
																"sign-direct.title"
															)}
														</p>
													</div>
												</div>
											</>
										}
									/>

									<p className="opacity-70">{t("help")}</p>

									{/* <div className="flex flex-col gap-6 mt-4">
										<div
											onClick={() =>
												setIsSignAutomatic(
													!isSignAutomatic
												)
											}
											className="flex gap-2 items-center"
										>
											<Switch checked={isSignAutomatic} />
											<div className="flex flex-1">
												<p>{t("sign-direct.title")}</p>
											</div>
										</div>
										<Button className="">
											{t("buttons.continue")}
										</Button>
										<p className="opacity-70">
											{t("help")}
										</p>
									</div> */}
								</div>
							)}
						</Card>
					</div>
				</div>
			)}
		</Layout>
	);
};

PaymentRequestCheckoutView.locale = {
	nl: {
		processTitle: "Verzoeken verwerken",
		title: "Betaling verwerken",
		empty: {
			title: "Geen verzoeken geselecteerd",
			description:
				"Selecteer eerst een aantal verzoeken om een betaling aan te maken.",
		},
		buttons: {
			"select-payments": "Selecteer verzoeken",
		},
		summary: {
			title: "Overzicht",
		},
		"sign-direct": {
			title: "Overeenkomsten automatisch ondertekenen",
		},
		help: 'Wanneer je op "Afrekenen" klikt, worden alle opdrachtnemers op een gezamenlijke betaling gebundeld.',
		keys: {
			hours: {
				label: "Totale uren",
				value: "{{count}} uren",
			},
			price: {
				label: "Uitbetaalbedrag",
			},
			fee: {
				label: "Succesfee ({{rate}}%)",
			},
			vat: {
				label: "BTW (21%)",
				help: "BTW wordt berekend over de succesfee",
			},
			total: {
				label: "Totaal",
			},
		},
	},
	en: {
		processTitle: "Process Requests",
		title: "Process Payment",
		empty: {
			title: "No Requests Selected",
			description: "First select some requests to create a payment.",
		},
		buttons: {
			"select-payments": "Select Requests",
		},
		summary: {
			title: "Summary",
		},
		"sign-direct": {
			title: "Automatically Sign Agreements",
		},
		help: 'When you click "Checkout," all contractors will be bundled into a single payment.',
		keys: {
			hours: {
				label: "Total Hours",
				value: "{{count}} hours",
			},
			price: {
				label: "Payout Amount",
			},
			fee: {
				label: "Success Fee ({{rate}}%)",
			},
			vat: {
				label: "VAT (21%)",
				help: "VAT is calculated on the success fee",
			},
			total: {
				label: "Total",
			},
		},
	},
};

export default withTranslation(PaymentRequestCheckoutView);
