import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Label from "src/components/Label";
import SkillBadge from "src/components/SkillBadge";
import useDelete from "src/hooks/rest/useDelete";
import useGet from "src/hooks/rest/useGet";
import usePost from "src/hooks/rest/usePost";

interface ResumeSkillsProps {
	query: string;
	type?: string;
	skills?: any;
	variant?: "small";
}

const ResumeSkills = ({ type, skills, query, variant }: ResumeSkillsProps) => {
	const { t } = useTranslation("resume", {
		keyPrefix: "resume-skills",
	});
	const [skill, setSkill] = useState("");
	const [result, loading, { setResult }] = useGet(!skills ? query : "", {
		data: [],
	});
	const { data } = result;
	const [postSkill, fetching] = usePost("/student/skills");
	const items = skills || data;

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (!skill) return;
		postSkill({ name: skill }).then(({ data }) => {
			setResult({
				...result,
				data,
			});
			setSkill("");
		});
	};

	const handleDelete = (id: number) => {
		setResult({
			...result,
			data: result.data.filter((i: any) => i.id !== id),
		});
	};

	const renderAddForm = () => (
		<form
			onSubmit={handleSubmit}
			className="flex md:flex-row flex-col items-center gap-4 mt-4"
		>
			<Input
				name="skill"
				onChange={(_, value) => setSkill(value)}
				value={skill}
				placeholder={t("form.label")}
				autoFill="skills"
				className="mb-0"
			/>
			<Button
				loading={fetching}
				submit
				className="gtm-student-dashboard-mijn-cv--button-vaardigheden-toevoegen h-[50px] w-full md:w-auto"
			>
				{t("form.button")}
			</Button>
		</form>
	);

	if (variant === "small") {
		return (
			<>
				{!loading && !items?.length ? (
					<div className="flex p-4 justify-center items-center flex-col">
						<span>{t("empty.title")}</span>
					</div>
				) : (
					<div className="flex gap-2 flex-wrap">
						{items.map((item: any) => (
							<SkillBadge
								hideEndorsements
								key={`skill-${item.id}`}
								skill={item}
							/>
						))}
					</div>
				)}
			</>
		);
	}

	return (
		<>
			{!loading && !items?.length ? (
				<div className="flex p-4 justify-center items-center flex-col">
					<span>{t("empty.title")}</span>
				</div>
			) : (
				<div className="flex gap-2 flex-wrap">
					{items.map((item: any) => (
						<Label
							key={item.id}
							endorsements={
								type !== "add" ? item.endorsements : undefined
							}
						>
							<div className="flex items-center">
								{item.name}
								{type === "add" && (
									<DeleteSkill
										id={item.id}
										onDelete={handleDelete}
										className="gtm-student-dashboard-mijn-cv--button-vaardigheden-verwijderen"
									/>
								)}
							</div>
						</Label>
					))}
				</div>
			)}

			{type === "add" && renderAddForm()}
		</>
	);
};

ResumeSkills.defaultProps = {
	query: "/student/skills",
};

const DeleteSkill = ({
	id,
	onDelete,
	className,
}: {
	id: number;
	onDelete: (i: number) => void;
	className?: string;
}) => {
	const classes = ["fal fa-times cursor-pointer"];
	if (className) classes.push(className);
	const [remove, loading] = useDelete(`/student/skills/${id}`);

	if (loading) return <span className="base-loader"></span>;

	return (
		<i
			onClick={() => {
				remove().then(() => onDelete(id));
			}}
			className={classes.join(" ")}
		></i>
	);
};

export default ResumeSkills;
