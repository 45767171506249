import axios from "axios";
import { useEffect, useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Loader from "src/components/Loader";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { Switch } from "src/components/ui/switch";
import Text from "src/components/ui/text";
import useAuthSession from "src/hooks/api/services/session/useAuthSession";
import useAuth from "src/hooks/selectors/useAuth";
import { usePollIframeHeight } from "src/hooks/useIframeEvents";
import useWindowSize from "src/hooks/useWindowSize";
import { formatPrice } from "src/lib/formatters";
import { cn } from "src/lib/utils";
import untruncateJson from "untruncate-json";

type Task = {
	id: string | number;
	title: string;
	description: string;
	hour_rate: number;
	estimated_duration: number;
};

const JobOfferConverterView = ({ t }: Translation) => {
	usePollIframeHeight();
	const auth = useAuth();
	const { isPhone } = useWindowSize();
	const turnstile = useTurnstile();
	const [description, setDescription] = useState("");
	const disabled = !description;
	const [turnstileResponse, setTurnstileResponse] = useState<string>();
	const [error, setError] = useState<"NO_TASKS_CREATED">();
	const [jobOfferId, setJobOfferId] = useState<string>();
	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [disabledIds, setDisabledIds] = useState<any[]>([]);
	const {
		session,
		actions: sessionActions,
		status: sessionStatus,
	} = useAuthSession();

	useEffect(() => {
		localStorage.setItem("referral_type", "JOB_OFFER_CONVERTER");
	}, []);

	const scrollToId = (id: string) => {
		const output = document.getElementById(id);
		if (output && isPhone) {
			output.scrollIntoView({ behavior: "smooth" });
		}
	};

	// const handleClear = () => {
	// 	setDescription("");
	// 	setTasks([]);
	// 	setLoading(false);
	// 	setError(undefined);
	// };

	const handleSubmit = async () => {
		if (disabled || loading) return;

		//Get the turnstile response
		if (!turnstileResponse && process.env.NODE_ENV === "production") return;
		setError(undefined);
		setLoading(true);
		setSubmitted(true);
		scrollToId("output");
		setTasks([]);

		axios
			.post(
				`/tools/job-offer-to-tasks`,
				{
					turnstile:
						process.env.NODE_ENV === "production"
							? turnstileResponse
							: "123",
					description,
				},
				{
					responseType: "stream",
					onDownloadProgress: function (progressEvent) {
						const response = progressEvent.currentTarget.response;
						const data = JSON.parse(untruncateJson(response));
						setTasks(data?.tasks);
						setJobOfferId(data?.job_offer_id);
					},
				}
			)
			.then((result) => {
				const { data } = result;
				if (!data) {
					console.log("No data");
					return;
				}
				if (!data?.tasks || !data?.tasks?.length) {
					setError("NO_TASKS_CREATED");
				}
				setJobOfferId(data?.job_offer_id);
				scrollToId("output-bottom");
				setTasks(data?.tasks);
				return data?.data;
			})
			.finally(() => {
				if (turnstile) {
					turnstile.reset();
				}
				setLoading(false);
			});
	};

	const onCreateAuthSession = async () => {
		const referral_type = "JOB_OFFER_CONVERTER";
		const value = {
			type: "COMPANY" as const,
			referral_type,
			job_offer_id: jobOfferId,
			task: tasks
				.filter((task: Task) => !disabledIds.includes(task.id))
				.map((task: Task) => ({
					...task,
					budget: task.estimated_duration * task.hour_rate,
				})),
		};
		if (!session?.id) {
			const newSession = await sessionActions.create(value);
			if (!newSession?.id) {
				return null;
			}
			return sessionActions.redirect(newSession.id);
		}
		await sessionActions.update(session?.id, value);
		return sessionActions.redirect(session.id);
	};

	return (
		<>
			<div className="flex flex-col flex-1 p-3 md:p-8 bg-purple-light">
				<div className="flex flex-col max-w-xl gap-8 mx-auto w-full">
					<Card>
						<form
							className="flex flex-col"
							onSubmit={(event) => {
								event.preventDefault();
								if (disabled) {
									return;
								}
								handleSubmit();
							}}
						>
							<div className="mb-6 flex justify-between items-center">
								<Text.Eyebrow>
									{t("inputs.description.label")}
								</Text.Eyebrow>
								{auth?.id && (
									<span className="opacity-60 text-sm">
										{t("loggedin", {
											name: auth?.first_name,
										})}
									</span>
								)}
							</div>
							<Input
								name={"description"}
								value={description}
								onChange={(key, value) => {
									setDescription(value);
									setError(undefined);
								}}
								className="flex-1 max-h-full textarea-full w-full mb-6"
								textareaClassName="flex-1 h-full"
								placeholder={t(
									"inputs.description.placeholder"
								)}
								multiline
							/>
							{process.env.REACT_APP_CLOUDFLARE_SITE_KEY && (
								<Turnstile
									sitekey={
										process.env
											.REACT_APP_CLOUDFLARE_SITE_KEY
									}
									onVerify={setTurnstileResponse}
								/>
							)}

							<Button
								{...{ disabled, loading }}
								onClick={handleSubmit}
							>
								{t("buttons.submit")}
							</Button>
						</form>
					</Card>
					<div
						className={cn(
							"flex flex-col items-center p-4",
							!submitted && "opacity-0 hidden"
						)}
					>
						<p className="transition-all text-purple-dark">
							{t("output.title")}
						</p>
					</div>
					<Card
						id="output"
						className={cn(
							"flex transition-all relative",
							!submitted && "opacity-0 hidden"
						)}
					>
						{submitted && (
							<div className="flex flex-col gap-6">
								<Text.Eyebrow>
									{t("output.subtitle")}
								</Text.Eyebrow>
								{error && (
									<div className="flex flex-col flex-1 gap-4 p-8">
										<h3>{t(`errors.${error}.title`)}</h3>
										<p>
											{t(`errors.${error}.description`)}
										</p>
									</div>
								)}
								{loading && tasks?.length === 0 && (
									<div className="flex flex-1 flex-col justify-center items-center p-8">
										<Loader />
									</div>
								)}

								{tasks?.length > 0 && (
									<div className="flex flex-col gap-5">
										{(tasks || []).map(
											(task: Task, index) => (
												<div
													key={index}
													className="flex items-center gap-4"
												>
													<div className="flex flex-col">
														{task.title && (
															<p className="line-clamp-2">
																{task.title}
															</p>
														)}
														{task.description && (
															<p className="opacity-60">
																{
																	task.description
																}
															</p>
														)}

														<div className="flex justify-between flex-wrap gap-6 items-center">
															<div className="flex gap-2 mt-3">
																{task.estimated_duration &&
																task.hour_rate ? (
																	<Badge>
																		{formatPrice(
																			task.estimated_duration *
																				task.hour_rate
																		)}
																	</Badge>
																) : (
																	false
																)}
																{task.estimated_duration ? (
																	<Badge variant="gray">
																		{
																			task.estimated_duration
																		}{" "}
																		{t(
																			"card.hour"
																		)}
																	</Badge>
																) : (
																	false
																)}
															</div>
															{!loading &&
																!auth?.id && (
																	<Switch
																		checked={
																			!disabledIds?.includes(
																				task.id
																			)
																		}
																		onClick={() => {
																			setDisabledIds(
																				(
																					prev
																				) => {
																					if (
																						prev.includes(
																							task.id
																						)
																					) {
																						return prev.filter(
																							(
																								id
																							) =>
																								id !==
																								task.id
																						);
																					}
																					return [
																						...prev,
																						task.id,
																					];
																				}
																			);
																		}}
																	/>
																)}
														</div>
													</div>
												</div>
											)
										)}
									</div>
								)}
							</div>
						)}
						{!loading && tasks?.length > 0 && !auth?.id ? (
							<>
								<div className="flex flex-col gap-3 justify-center items-center mt-6">
									<Button
										className="w-full"
										loading={sessionStatus !== "idle"}
										onClick={() => {
											onCreateAuthSession();
										}}
									>
										{t("buttons.signup")}
									</Button>
									<div className="flex gap-2 pl-12">
										<img
											className=""
											src="/arrow.svg"
											alt={t("buttons.disclaimer")}
										/>
										<span className="flex-1">
											{t("buttons.disclaimer")}
										</span>
									</div>
								</div>
							</>
						) : (
							false
						)}
						<div
							className="absolute bottom-0 left-0 right-0 h-10"
							id="output-bottom"
						></div>
					</Card>
				</div>
			</div>
		</>
	);
};

JobOfferConverterView.locale = {
	nl: {
		loggedin: "Ingelogd als {{name}}",
		title: "Vacature tekst",
		description:
			"Laten we een beter oplossing zoeken voor je openstaande vacature",
		inputs: {
			description: {
				placeholder: "Plak hier je vacature tekst...",
				label: "Vacature",
			},
		},
		buttons: {
			submit: "Omzetten naar opdrachten",
			clear: "Legen",
			signup: "Opdrachten plaatsen",
			disclaimer: "Geen betaling verplicht",
		},
		output: {
			title: "Wij denken dat deze opdrachten bij je vacature passen",
			subtitle: "Opdrachten",
		},
		errors: {
			NO_TASKS_CREATED: {
				title: "Geen opdrachten gevonden",
				description:
					"Er zijn geen opdrachten gevonden in de gegeven vacature tekst. Probeer het opnieuw met een andere vacature tekst.",
			},
		},
		card: {
			hour: "uur",
		},
		"modal-title": "Bijna klaar",
		fields: {
			first_name: {
				placeholder: "Voornaam",
			},
			last_name: {
				placeholder: "Achternaam",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Telefoonnummer",
			},
			password: {
				placeholder: "Wachtwoord",
			},
			company_name: {
				placeholder: "Bedrijfsnaam",
			},
		},
		empty: {
			title: "Geen opdrachten gevonden",
			description:
				"Vul je vacature tekst in en klik op 'Converteer' om opdrachten te genereren.",
		},
	},
	en: {
		loggedin: "Logged in as {{name}}",
		title: "Job Description",
		description: "Let's find a better solution for your open vacancy",
		inputs: {
			description: {
				placeholder: "Paste your job description here...",
				label: "Vacancy",
			},
		},
		buttons: {
			submit: "Convert to tasks",
			clear: "Clear",
			signup: "Post tasks",
			disclaimer: "No payment required",
		},
		output: {
			title: "We think these tasks match your vacancy",
			subtitle: "Tasks",
		},
		errors: {
			NO_TASKS_CREATED: {
				title: "No tasks found",
				description:
					"No tasks were found in the given job description. Try again with a different job description.",
			},
		},
		card: {
			hour: "hour",
		},
		"modal-title": "Almost done",
		fields: {
			first_name: {
				placeholder: "First name",
			},
			last_name: {
				placeholder: "Last name",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Phone number",
			},
			password: {
				placeholder: "Password",
			},
			company_name: {
				placeholder: "Company name",
			},
		},
		empty: {
			title: "No tasks found",
			description:
				"Enter your job description and click 'Convert' to generate tasks.",
		},
	},
};

export default withTranslation(JobOfferConverterView);
