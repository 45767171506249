import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiCategory } from "src/api/types";
import Button from "src/components/Button";
import Tag from "src/components/Tag";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResume from "src/hooks/api/services/resume/useResume";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface InterestsFormProps extends Translation {
	type?: string;
}

function InterestsForm({ t }: InterestsFormProps) {
	const auth = useAuth();
	const { tenant } = useTenant();
	const { resume, status, actions } = useResume({
		rememberKey: auth.id,
	});
	const [interests, setInterests] = useState<ApiCategory[]>(
		resume?.interests
	);
	const navigate = useNavigate();
	const [{ data }, loading] = useGet(
		`/categories?variant=interests${
			tenant.slug === "getjobsdone" ? "&type=professional" : ""
		}`
	);

	const isActive = (item: ApiCategory) =>
		(interests || []).some((category) => category.id === item.id);

	useEffect(() => {
		if (!(interests || []).length) {
			setInterests(resume?.interests);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resume]);

	const handleSubmit = () => {
		actions
			.update({
				interests,
			})
			.then(() => {
				navigate("/profile/resume");
			});
	};

	return (
		<>
			<div className="flex flex-col flex-1">
				<div className="flex flex-col">
					<strong>{t("title")}</strong>
					<small>{t("description")}</small>
				</div>
				<hr className="border-border mt-4" />
				<div className="flex gap-2 flex-wrap mb-2 empty:hidden items-center mt-4">
					{(data || []).map((item: ApiCategory) => (
						<Tag
							className={cn(
								"bg-background text-background-foreground font-regular cursor-pointer hover:bg-primary-200 transition-colors px-2 py-1",
								isActive(item) &&
									"bg-primary text-primary-foreground"
							)}
							onClick={() =>
								isActive(item)
									? setInterests((state) =>
											state.filter(
												(category) =>
													category.id !== item.id
											)
									  )
									: setInterests((state) => [...state, item])
							}
							key={`category-${item.name}`}
						>
							{item.name}
						</Tag>
					))}
				</div>
			</div>
			<div className="flex justify-between">
				<Button
					type="gray"
					disabled={status !== "idle" && !loading}
					small
					to={"/profile/resume/create/work"}
				>
					{t("back")}
				</Button>
				<Button
					type="dark"
					small
					onClick={handleSubmit}
					loading={status !== "idle" && !loading}
				>
					{t("continue")}
				</Button>
			</div>
		</>
	);
}

InterestsForm.locale = {
	nl: {
		title: "In welke categorieën ben je geïnteresseerd?",
		description:
			"Op basis van deze categorieën kunnen er in de toekomst taken / opdrachten worden aangeraden",
		add: "Toevoegen",
		input: {
			label: "Vaardigheden",
		},
		back: "Vorige",
		continue: "Afronden",
	},
	en: {
		title: "In which categories are you interested?",
		description:
			"Based on these categories, tasks/assignments can be recommended in the future.",
		add: "Add",
		input: {
			label: "Vaardigheden",
		},
		back: "Back",
		continue: "Finish",
	},
};

export default withTranslation(InterestsForm as any);
