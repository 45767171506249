import { NavLink } from "react-router-dom";
import "./label.scss";

interface LabelProps {
	children: any;
	to?: string;
	type?: string;
	small?: boolean;
	onClick?: () => void;
	className?: string;
	endorsements?: number;
	showEndorsements?: boolean;
	onEndorseToggle?: (skillId: number) => void;
	skillId?: number;
	hasEndorsed?: boolean;
	disabled?: boolean;
	skillLoading?: boolean;
	active?: boolean;
}

const Label = ({
	children,
	to,
	type,
	small,
	onClick,
	className,
	active,
}: LabelProps) => {
	const classes = ["label-button"];
	if (type) classes.push(`label-button-${type}`);
	if (className) classes.push(className);
	if (small) classes.push("small");
	if (onClick) classes.push("label-button-clickable");
	if (active) classes.push("label-button-active");

	if (!to) {
		return (
			<div {...{ onClick }} className={classes.join(" ")}>
				<div className="label-button-content flex-1">{children}</div>
			</div>
		);
	}

	return (
		<NavLink className={classes.join(" ")} {...{ to, onClick }}>
			{children}
		</NavLink>
	);
};

export default Label;
