import { useState } from "react";
import { useTranslation } from "react-i18next";
import KeyValue from "src/components/KeyValue";
import MultipleCompaniesMiddleware from "src/components/middlewares/MultipleCompaniesMiddleware";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";
import Button from "src/components/Button";
import Card from "src/components/Card";
import FlatList from "src/components/FlatList";
import useGet from "src/hooks/rest/useGet";
import ProfileTabs from "src/components/layout/profile-settings-layout/ProfileTabs";
import CompanyCreateModal from "src/views/authenticated/profile/companies/CompanyCreateModal";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";

export default function CompaniesView() {
	const { t } = useTranslation("profile-view", {
		keyPrefix: "companies-view",
	});
	const [{ data, hasMore }, loading, { fetchNext, fetchGet }] = useGet(
		`/company/companies`,
		{ data: [] },
		true
	);
	const { modules, loading: modulesLoading } = useModules();
	const [visible, setVisible] = useState(false);

	if (!modules?.includes("multiple-companies") && !loading) {
		return (
			<MultipleCompaniesMiddleware
				tabs={<ProfileTabs />}
				loading={modulesLoading}
			/>
		);
	}

	return (
		<ProfileSettingsLayout title={t("title")}>
			<CompanyCreateModal
				refetch={fetchGet}
				{...{ visible, setVisible }}
			/>
			{!loading && (
				<>
					<div className="companies-view-head">
						<Button
							className="gtm-werkgever-dashboard-profiel-vestigingen--button-vestiging-toevoegen"
							onClick={() => setVisible(true)}
							small
						>
							{t("add")}
						</Button>
					</div>
					<FlatList
						columns={2}
						{...{ hasMore }}
						onNext={() => {
							if (hasMore) fetchNext();
						}}
						empty={{
							title: t("empty"),
						}}
						renderItem={(item: any) => (
							<CompanyCard company={item} key={item.id} />
						)}
						{...{ data, loading }}
					/>
				</>
			)}
		</ProfileSettingsLayout>
	);
}

const CompanyCard = ({ company }: { company: any }) => {
	const { t } = useTranslation("profile-view", {
		keyPrefix: "companies-view.card",
	});
	const auth = useAuth();
	const { address, parent_id } = company;
	return (
		<Card title={company.name}>
			<div className="flex flex-col">
				<KeyValue.Small label={t("kvk")} text={company.kvk} />
				<KeyValue.Small
					label={t("branche")}
					text={`${address.street} ${address.house_number}, ${address.city}`}
				/>
				{(!parent_id || company.id === auth?.company?.id) && (
					<div className="flex justify-between items-center gap-4 mt-2 flex-wrap">
						{!parent_id && (
							<strong className="text-green">
								{t("primary")}
							</strong>
						)}
						{company.id === auth.company.id && (
							<span className="opacity-20">{t("current")}</span>
						)}
					</div>
				)}
			</div>
		</Card>
	);
};
