import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import Layout from "src/components/layout/Layout";
import SubscriptionCheckoutHead from "src/components/subscription/SubscriptionCheckoutHead";
import SubscriptionStatus from "src/components/subscription/SubscriptionsStatus";
import useGet from "src/hooks/rest/useGet";
import NotFound from "src/views/auth/NotFoundView";
import { useTranslation } from "react-i18next";
import Card from "src/components/Card";

export default function SubscriptionView() {
	const { t } = useTranslation("subscription-view", {
		keyPrefix: "thanks-view",
	});
	const params = useParams() as any;
	let [{ data: plan }, loading] = useGet(
		`/auth/subscriptions/plans?slug=${params.slug}`
	);

	if (loading) {
		return (
			<Layout hideNav>
				<div className="flex flex-col flex-1 justify-center items-center">
					<span className="base-loader"></span>
				</div>
			</Layout>
		);
	}

	if (!plan) {
		return <NotFound />;
	}

	return (
		<Layout hideNav>
			<SubscriptionCheckoutHead
				title={t("title")}
				subtitle={t("subtitle")}
			/>
			<div className="flex flex-col flex-1 justify-center items-center">
				<motion.div
					animate={{
						opacity: 1,
						x: 0,
						transition: {
							delay: 0.2,
							type: "timing",
						},
					}}
					initial={{
						opacity: 0,
						x: -200,
					}}
				>
					<Card>
						<SubscriptionStatus {...{ plan }} />
					</Card>
				</motion.div>
			</div>
			{/* <SubscriptionGrid columns={2} selected={plan}>
				<SubscriptionPlan
					key={plan.slug}
					isRecommended={plan.slug === "premium"}
					isSelected={true}
					{...{ plan }}
				/>
				<motion.div
					animate={{
						opacity: 1,
						x: 0,
						transition: {
							delay: 0.2,
							type: "timing",
						},
					}}
					initial={{
						opacity: 0,
						x: -200,
					}}
					className="rounded-md md:rounded-l-none md:mt-24 md:mb-4 flex flex-col gap-8 bg-background dark:bg-accent border border-border shadow relative px-10 md:pt-16 p-8"
				>
					<SubscriptionStatus {...{ plan }} />
				</motion.div>
			</SubscriptionGrid> */}
		</Layout>
	);
}
