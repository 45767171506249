import { useEffect } from "react";
import useTenant from "src/hooks/api/services/tenants/useTenant";

function useTenantTheme() {
	const { tenant } = useTenant();
	useEffect(() => {
		const root = window.document.documentElement;
		if (!root?.classList.contains(tenant.slug)) {
			root?.classList.add(tenant.slug);
		}
	}, [tenant]);
}

export default useTenantTheme;
