import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import EditInterests from "src/components/forms/profile/EditInterests";
import Modal from "src/components/Modal";

const DashboardEditInterestsModal = () => {
	const { t } = useTranslation("resume-view", {
		keyPrefix: "edit-interests-modal",
	});
	const navigate = useNavigate();
	const onClose = () => {
		navigate("/dashboard");
	};

	return (
		<Modal
			title={t("title")}
			className="max-w-[460px] w-full"
			visible
			{...{ onClose }}
		>
			<p>{t("description")}</p>
			<EditInterests
				className="mt-8 flex-1"
				onDone={onClose}
				renderButtons={({ onSubmit, isSubmitting, isValid }: any) => (
					<Modal.Buttons>
						<Button
							type="gray"
							disabled={isSubmitting}
							onClick={onClose}
						>
							{t("buttons.cancel")}
						</Button>
						<Button
							type="primary"
							// disabled={!isValid}
							loading={isSubmitting}
							onClick={onSubmit}
						>
							{t("buttons.save")}
						</Button>
					</Modal.Buttons>
				)}
			/>
		</Modal>
	);
};

export default DashboardEditInterestsModal;
