import { atom } from "jotai";
import { useEffect, useState } from "react";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useEditProfile from "src/hooks/api/useEditProfile";
import useForm from "src/hooks/schemes/useForm";
import useAuth from "src/hooks/selectors/useAuth";
import useModal from "src/hooks/useModal";
const formAtom: any = atom({
	// kvk: company.kvk,
	// bio: company.bio,
	// website: company.website,
	// branche: company.branche,
});
const validateAtom: any = atom({});

type Information = {
	bio?: string;
	kvk?: string;
	website?: string;
	branche?: string;
};

interface OnboardingInformationModalProps extends Translation {
	children?: any;
}

const OnboardingInformationModal = ({ t }: OnboardingInformationModalProps) => {
	const auth = useAuth();
	const company = auth.company;
	const [update, loading] = useEditProfile();
	const [error, setError] = useState<string>();
	const {
		form,
		actions: { getField, resetForm },
		validate,
	} = useForm({
		atoms: { form: formAtom, validate: validateAtom },
		scheme: {
			kvk: ["string", "required", "length:8"],
			branche: ["string", "required"],
			website: ["string", "required"],
			bio: ["string", "required"],
		},
	});
	const modal = useModal();
	const disabled = !validate.isValid || loading;

	useEffect(() => {
		resetForm({
			kvk: company.kvk,
			bio: company.bio,
			website: company.website,
			branche: company.branche,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company]);

	useEffect(() => {
		modal.setTitle(t("title"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInput = (name: keyof Information) => ({
		...getField(name),
		valid: false,
		noMarginBottom: true,
		label: t(`fields.${name}.label`),
		placeholder: t(`fields.${name}.placeholder`),
	});

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (disabled) return;

		update({
			company: form,
		})
			.then(() => {
				modal.onClose();
			})
			.catch(({ response: { data } }) => {
				if (data?.errors.kvk_in_use) {
					setError(
						t("error.kvk_in_use", {
							kvk: form.kvk || "",
						})
					);
				}
				console.log(data);
			});
	};

	return (
		<form className="flex flex-col gap-4" {...{ onSubmit }}>
			<Input {...getInput("kvk")} />
			<Input {...getInput("website")} />
			<Input {...getInput("branche")} />
			<Input
				multiline
				{...getInput("bio")}
				placeholder={t("fields.bio.placeholder", {
					name: company.name,
				})}
			/>

			<Button {...{ loading, disabled }} type="dark" submit>
				{t("save")}
			</Button>

			{error && <p className="text-red">{error}</p>}
		</form>
	);
};

OnboardingInformationModal.locale = {
	nl: {
		title: "Informatie",
		save: "Opslaan",
		fields: {
			kvk: {
				label: "KVK",
				placeholder: "12345678",
				help: "Via de KVK kunnen wij extra gegevens ophalen",
				"to-long": "KVK nummers moeten 8 cijfers zijn",
			},
			website: {
				label: "Website",
				placeholder: "{{tenant.domain}}",
			},
			branche: {
				label: "Branche",
				placeholder: "Software",
			},

			bio: {
				label: "Omschrijving",
				placeholder: "{{name}} is gespecialiseerd in...",
			},
		},
		error: {
			kvk_in_use: "KVK {{kvk}} is al in gebruik",
		},
	},
	en: {
		title: "Information",
		save: "Save",
		fields: {
			kvk: {
				label: "KVK",
				placeholder: "12345678",
				help: "We can retrieve additional data through the KVK",
				"to-long": "KVK numbers must be 8 digits",
			},
			website: {
				label: "Website",
				placeholder: "{{tenant.domain}}",
			},
			branche: {
				label: "Industry",
				placeholder: "Software",
			},
			bio: {
				label: "Description",
				placeholder: "{{name}} specializes in...",
			},
		},
		error: {
			kvk_in_use: "KVK {{kvk}} is already in use",
		},
	},
};

export default withTranslation(OnboardingInformationModal);
