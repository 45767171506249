import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { cn } from "src/lib/utils";
import { clearAlert } from "src/redux/alert";

export type AlertProps = {
	type?: string;
	title: string;
	text?: any;
	stayOpen?: boolean;
	button?: string;
	onConfirm?: () => void;
};

const variants = {
	container: {
		enter: {
			opacity: 1,
			transition: {
				when: "beforeChildren",
				staggerChildren: 0.5,
			},
		},
		exit: { opacity: 0 },
	},
	alert: {
		enter: {
			y: 0,
			opacity: 1,
			scale: 1,
		},
		exit: {
			y: 200,
			scale: 0.2,
			opacity: 0,
		},
	},
};

const AlertContainer = () => {
	const dispatch = useDispatch();
	const alert = useSelector((state: any) => state.alert);

	useEffect(() => {
		if (alert.title !== "" && !alert.stayOpen && alert.type !== "confirm") {
			setTimeout(() => {
				dispatch(clearAlert());
			}, 2000);
		}
	}, [alert, dispatch]);
	const test = 1;

	if (test) {
		return (
			<Modal
				onClose={() => {
					dispatch(clearAlert());
				}}
				visible={!!alert.title}
			>
				<Alert {...{ ...alert }} />
			</Modal>
		);
	}

	return (
		<AnimatePresence>
			{alert.title && (
				<motion.div
					initial="exit"
					animate="enter"
					exit="exit"
					variants={variants.container}
					className="fixed inset-0 bg-[#00000020] z-[999] flex items-center justify-center"
				>
					<Alert {...{ ...alert }} />
				</motion.div>
			)}
		</AnimatePresence>
	);
};

const Alert = ({ title, type, text, button, onConfirm }: AlertProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const close = () => dispatch(clearAlert());

	return (
		<div
			className={cn(
				"flex flex-col gap-6 rounded-lg bg-background mx-2 md:mx-0 max-w-[460px]"
			)}
		>
			<div className="flex flex-col gap-1">
				<p className="font-bold">{title}</p>
				{text && <p className="opacity-70">{text}</p>}
			</div>
			{type === "confirm" ? (
				<div className="flex-col flex gap-3">
					<Button
						className="flex-1 md:flex-auto"
						onClick={() => {
							close();
							if (onConfirm) {
								onConfirm();
							}
						}}
					>
						{button || t("confirm", { ns: "buttons" })}
					</Button>
					<Button
						className="flex-1 md:flex-auto"
						type="ghost"
						onClick={close}
					>
						{t("cancel", { ns: "buttons" })}
					</Button>
				</div>
			) : (
				button && <Button onClick={close}>{button}</Button>
			)}
		</div>
	);
};

export default AlertContainer;
