import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import CreditView from "src/views/company/CreditView";

const CreditsRoutes = [
	<Route
		element={
			<ProtectedRoute type="company">
				<CreditView />
			</ProtectedRoute>
		}
		path="/credits/upgrade"
		key="/credits/upgrade"
	/>,
];

export default CreditsRoutes;
