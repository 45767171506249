import React, { useEffect } from "react";
import StudentCanRegister from "src/components/promotion/banners/StudentCanRegister";
import Card from "src/components/Card";
import Onboarding from "src/components/dashboard/student/Onboarding";
import PaymentBalance from "src/components/dashboard/student/PaymentBalance";
import Layout from "src/components/layout/Layout";
import StudentOnboarding from "src/components/Onboarding/Student/StudentOnboarding";
import useAuth from "src/hooks/selectors/useAuth";
import BankCard from "src/components/cards/BankCard";
import { useTranslation } from "react-i18next";
// import SurveyCard from "src/components/promotion/cards/SurveyCard";
import useResume from "src/hooks/api/services/resume/useResume";
import ResumeCard from "src/components/ResumeCompanyUser/resume-card/ResumeCard";
import Loader from "src/components/Loader";

export default function DashboardStudentView() {
	const { t } = useTranslation("dashboard-view", {
		keyPrefix: "student",
	});
	const auth = useAuth();
	const { resume, actions, status } = useResume({
		rememberKey: auth.id,
	});

	useEffect(() => {
		actions.get(auth.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout container="xxl" title={t("title")}>
			<div className="flex flex-col-reverse md:flex-row gap-6">
				<div className="flex flex-col flex-1 gap-6">
					<StudentCanRegister />
					<Onboarding />
					{!auth?.status?.is_onboarded && <StudentOnboarding />}
					{status === "loading" && !resume?.id && (
						<div className="flex flex-col p-8 items-center">
							<Loader />
						</div>
					)}
					{resume?.id && (
						<ResumeCard
							options={{
								title: {
									variant: "uppercase",
								},
							}}
							resume={resume}
						>
							<div className="flex flex-col gap-6">
								<Card>
									<ResumeCard.Head
										variant="small"
										className="mb-0"
										editable
									/>
								</Card>
								<Card>
									<ResumeCard.Bio editable />
								</Card>
								<ResumeCard.Skills editable />
								<Card>
									<ResumeCard.Education
										variant="timeline"
										editable
									/>
								</Card>
								<Card>
									<ResumeCard.Work
										variant="timeline"
										editable
									/>
								</Card>
								<Card>
									<ResumeCard.Reviews variant="list" />
								</Card>
								<Card>
									<ResumeCard.Badge />
								</Card>
								<Card>
									<ResumeCard.SocialMedia editable />
								</Card>
							</div>
						</ResumeCard>
					)}
					{/* <Resume.Header
						{...{ editable }}
						backgroundColor={color}
						avatar={auth.avatar}
						name={auth.full_name}
						description={auth?.bio?.bio}
						rating={auth?.rating?.rating}
					/>
					<Card
						id="education"
						title={t("cards.education")}
						actions={
							editable && (
								<Link
									to={`/profile/resume/create/education?${backUrl}`}
								>
									<small>
										<i className="fas fa-pencil mr-2"></i>
										{t("edit")}
									</small>
								</Link>
							)
						}
					>
						<ResumeExperiencesList
							type="education"
							query={`/resume/${auth.hid}/experiences?type=education`}
							{...{ color }}
						/>
					</Card>

					<Card
						id="jobs"
						title={t("cards.job")}
						actions={
							editable && (
								<Link
									to={`/profile/resume/create/work?${backUrl}`}
								>
									<small>
										<i className="fas fa-pencil mr-2"></i>
										{t("edit")}
									</small>
								</Link>
							)
						}
					>
						<ResumeExperiencesList
							type="job"
							query={`/resume/${auth.hid}/experiences?type=job`}
							{...{ color }}
						/>
					</Card>

					<Card
						id="skills"
						title={t("cards.skills")}
						actions={
							editable && (
								<Link
									to={`/profile/resume/create/skill?${backUrl}`}
								>
									<small>
										<i className="fas fa-pencil mr-2"></i>
										{t("edit")}
									</small>
								</Link>
							)
						}
					>
						<div className="mt-4">
							<ResumeSkills
								query={`/resume/${auth.hid}/skills`}
							/>
						</div>
					</Card>
					<Card
						id="social-media"
						title={t("cards.socials")}
						actions={
							editable && (
								<Link to="/dashboard/resume/extra#social-media">
									<small>
										<i className="fas fa-pencil mr-2"></i>
										{t("edit")}
									</small>
								</Link>
							)
						}
					>
						<div className="mt-4">
							<ResumeSocialMedia
								query={`/resume/${auth.hid}/social-media`}
							/>
						</div>
					</Card>
					<Card id="badges" title={t("cards.badges")}>
						<div className="mt-4">
							<ResumeBadges
								query={`/resume/${auth.hid}/badges`}
							/>
						</div>
					</Card>
					<Card id="reviews" title={t("cards.reviews")}>
						<div className="mt-4">
							<ResumeReviews
								query={`/resume/${auth.hid}/reviews`}
							/>
						</div>
					</Card> */}
				</div>
				<div className="flex flex-col md:max-w-[320px] w-full gap-6">
					<>
						{/* <div className="">
							<SurveyCard />
						</div> */}
						<PaymentBalance />
						{/* <ReferralCard /> */}
						<div className="">
							<BankCard />
						</div>

						{/* <div className="">
						<CourseCard />
					</div> */}
					</>
				</div>
			</div>
		</Layout>
	);
}
