import { motion } from "framer-motion";
import React from "react";
import useElementSize from "src/hooks/useElementSize";
import { cn } from "src/lib/utils";

interface StepsProps {
	max: number;
	current: number;
	className?: string;
	onChange?: (step: number) => void;
}

const Steps = ({ max, current, className, onChange }: StepsProps) => {
	const wrapper = useElementSize();
	const gap = 8 * max;
	const activeWidth = (wrapper.size.width - gap) / max;
	return (
		<div
			ref={wrapper.ref}
			className={cn("flex gap-2 items-center", className)}
		>
			{new Array(max).fill("").map((value, index) => (
				<Item
					key={`steps-${index}`}
					{...{ index, current, activeWidth }}
					onClick={onChange ? () => onChange(index) : undefined}
				/>
			))}
		</div>
	);
};

interface ItemProps {
	index: number;
	current: number;
	activeWidth: number;
	onClick?: () => void;
}

const Item = ({ index, current, activeWidth, onClick }: ItemProps) => {
	return (
		<motion.div
			className={cn(
				"h-2 w-8 flex rounded-full bg-accent overflow-hidden transition-all",
				onClick && "cursor-pointer hover:bg-accent-dark"
			)}
			{...{ onClick }}
			transition={{
				ease: "linear",
			}}
			animate={index === current ? "active" : "inactive"}
			variants={{
				active: {
					width: activeWidth,
				},
				inactive: {
					width: "32px",
				},
			}}
		>
			<motion.div
				animate={current >= index ? "visible" : "hidden"}
				className="flex-1 bg-dark"
				variants={{
					visible: {
						opacity: 1,
					},
					hidden: {
						opacity: 0,
					},
				}}
			/>
		</motion.div>
	);
};

export default Steps;
