import { useParams } from "react-router-dom";
import BackButton from "src/components/BackButton";
import SkillSelector from "src/components/form/skill-selector/SkillSelector";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import RegistrationsSidebar from "src/components/tasks/panes/registration/RegistrationsSidebar";
import SelectedRegistrationPane from "src/components/tasks/panes/registration/SelectedRegistrationPane";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useWindowSize from "src/hooks/useWindowSize";

const SelectRegistrationPane = ({ t }: Translation) => {
	const { id, registration } = useParams() as any;
	const { task } = useTask({ id });
	const { isTablet } = useWindowSize();

	return (
		<div className="flex gap-8 md:flex-row flex-col flex-1 min-h-[80vh] h-full">
			<RegistrationsSidebar />
			{registration && (
				<div className="flex flex-1 flex-col h-full">
					{isTablet && (
						<BackButton
							to={`/tasks/${id}/show`}
							label={t("back")}
							className="mb-4"
						/>
					)}
					<SelectedRegistrationPane id={registration} />
				</div>
			)}
			{!registration && !isTablet && (
				<div className="flex-1 flex flex-col h-full justify-center items-center">
					<div className="flex flex-col gap-6">
						{task?.skills?.length > 0 && (
							<div className="flex flex-col gap-2">
								<p className="max-w-[400px]">
									{t("skills.title")}
								</p>
								<SkillSelector value={task.skills}>
									<SkillSelector.Results />
								</SkillSelector>
							</div>
						)}
						<p className="opacity-60">{t("empty.title")}</p>
					</div>
				</div>
			)}
		</div>
	);
};

SelectRegistrationPane.locale = {
	nl: {
		back: "Terug naar aanmeldingen",
		empty: {
			title: "Selecteer een aanmelding",
		},
		skills: {
			title: "Volgens onze software heb je de volgende skills nodig voor deze opdracht",
		},
	},
	en: {
		back: "Back to registrations",
		empty: {
			title: "Select a registration",
		},
		skills: {
			title: "According to our software you need the following skills for this task",
		},
	},
};

export default withTranslation(SelectRegistrationPane);
