import { ApiEndorseSkill } from "src/api/types";
import Badge from "src/components/Badge";

interface SkillBadgeProps {
	skill: ApiEndorseSkill;
	onClick?: () => void;
	hideEndorsements?: boolean;
}

const SkillBadge = ({ skill, onClick, hideEndorsements }: SkillBadgeProps) => {
	return (
		<Badge
			variant={skill.has_endorsed ? "primary" : "dark"}
			className={`${
				onClick && "cursor-pointer"
			} transition-all gap-2 whitespace-normal`}
			{...{ onClick }}
		>
			<span>{skill.name}</span>
			{!hideEndorsements && (
				<div className="px-2 py-1 rounded-sm relative overflow-hidden">
					<div
						className={`${
							skill.has_endorsed ? "bg-dark" : "bg-primary"
						} opacity-20 transition-all absolute inset-0`}
					></div>
					<div className="div flex gap-1 items-center relative z-10">
						<i
							className={`${
								skill.has_endorsed ? "fas" : "far"
							} fa-thumbs-up text-[12px]`}
						></i>
						<span>{skill.endorsements}</span>
					</div>
				</div>
			)}
		</Badge>
	);
};

export default SkillBadge;
