import React from "react";
import { ApiMedia } from "src/api/types";
import useAuth from "src/hooks/selectors/useAuth";

interface MessageFileProps {
	media: ApiMedia;
}

const MessageFile = ({ media }: MessageFileProps) => {
	const auth = useAuth();

	return (
		<a
			href={media.download_url}
			target="_blank"
			download
			className={`rounded-md p-4 flex flex-col gap-2 justify-center items-center ${
				auth.type === "company" ? "bg-dark" : "bg-accent-900"
			}`}
			rel="noreferrer"
		>
			<div
				className={`w-40 h-40 flex justify-center items-center rounded-md ${
					auth.type === "company" ? "bg-dark-accent" : "bg-accent"
				}`}
			>
				{media.mime_type.startsWith("image") ? (
					<img
						src={media.url}
						className="w-full h-full object-contain"
						alt=""
					/>
				) : (
					<i className="fas fa-file text-[40px]"></i>
				)}
			</div>

			<div className="flex justify-between gap-2 items-center">
				<div className="">
					<div className="max-w-[120px] md:max-w-[160px] relative overflow-hidden">
						<strong className="whitespace-nowrap">
							{media.filename}
						</strong>
						<div
							className={`absolute top-0 right-0 bottom-0 w-16 bg-gradient-to-l ${
								auth.type === "company"
									? "from-dark"
									: "from-accent"
							}`}
						></div>
					</div>
					<span className="opacity-60">{media.size}</span>
				</div>

				<i className="fas fa-download"></i>
			</div>
		</a>
	);
};

export default MessageFile;
