import axios from "axios";
import { useEffect, useState } from "react";
import { ApiCouponCode } from "src/api/types";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useQueryString from "src/hooks/useQueryString";

interface CouponCodeCheckerProps extends Translation {
	couponCode?: ApiCouponCode | null;
	onChange: (couponCode: ApiCouponCode | null) => void;
	plan?: {
		id: string | number;
		interval: "MONTHLY" | "YEARLY";
	};
}

const CouponCodeChecker = ({
	t,
	plan,
	couponCode,
	onChange,
}: CouponCodeCheckerProps) => {
	const [code, setCode] = useState<string>(couponCode?.name || "");
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isNotFound, setIsNotFound] = useState(false);

	const qs = useQueryString();

	useEffect(() => {
		if (qs.code) {
			validateCode(qs.code.toString());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateCode = async (code: string) => {
		if (!code) return;
		setIsNotFound(false);
		setIsVisible(true);
		setIsLoading(true);
		setCode(code);

		try {
			const {
				data: { data },
			} = await axios
				.post(`/coupon-codes/validate`, {
					code,
					plan_id: plan?.id,
					interval: plan?.interval,
				})
				.finally(() => setIsLoading(false));
			if (data?.id) {
				onChange(data);
			}
		} catch (error) {
			setIsNotFound(true);
			setIsLoading(false);
			onChange(null);
		}
	};

	const handleValidateCode = async (event: any) => {
		if (event) {
			event.preventDefault();
		}
		await validateCode(code);
	};

	const handleCancel = () => {
		setIsVisible(false);
		setCode("");
		setIsNotFound(false);
		onChange(null);
	};

	if (isVisible) {
		return (
			<div className="flex flex-col">
				<form onSubmit={handleValidateCode} className="flex gap-3">
					<Input
						autoFocus
						name="code"
						onChange={(key, value) => setCode(value)}
						value={code}
						placeholder={t("placeholder")}
						className="flex-1"
					/>
					<Button
						loading={isLoading}
						submit
						xsmall
						type="accent"
						disabled={!code}
					>
						{t("check")}
					</Button>
				</form>
				<p
					onClick={handleCancel}
					className="opacity-60 cursor-pointer text-sm mt-1"
				>
					{t("cancel")}
				</p>
				{isNotFound && (
					<div className="mt-2">
						<p className="text-sm">{t("not-found")}</p>
					</div>
				)}
				{couponCode && (
					<div className="mt-3 p-3 rounded-md border border-green text-green">
						<p className="text-sm">{t("applied.title")}</p>
						<p className="text-sm opacity-60">
							{t(
								couponCode.is_lifetime
									? "applied.description-lifetime"
									: "applied.description"
							)}
						</p>
					</div>
				)}
			</div>
		);
	}

	return (
		<div className=" ">
			<Button onClick={() => setIsVisible(true)} xsmall type="accent">
				{t("button")}
				<i className="far fa-plus"></i>
			</Button>
		</div>
	);
};

CouponCodeChecker.locale = {
	nl: {
		button: "Kortingscode toepassen",
		check: "Controleren",
		cancel: "Annuleren",
		placeholder: "Voer een kortingscode in",
		"not-found": "Kortingscode niet gevonden",
		applied: {
			title: "Kortingscode toegepast!",
			"description-lifetime":
				"Deze kortingscode blijft van toepassing zolang je abonnee bent.",
			description: "Deze kortingscode is geldig voor de eerste betaling",
		},
	},
	en: {
		button: "Apply coupon code",
		check: "Check",
		cancel: "Cancel",
		placeholder: "Enter a coupon code",
		"not-found": "Coupon code not found",
		applied: {
			title: "Coupon code applied!",
			"description-lifetime":
				"This coupon code will remain applied as long as you are a subscriber.",
			description: "This coupon code is valid for the first payment",
		},
	},
};

export default withTranslation(CouponCodeChecker);
