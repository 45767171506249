import axios from "axios";
import { useEffect, useState } from "react";
import useFetchProfile from "./useFetchProfile";

function useSubscriptionStatus(query?: any) {
	const { fetchProfile } = useFetchProfile();
	const [subscription, setSubscription] = useState({
		mandate: undefined,
		subscription: undefined,
	});

	useEffect(() => {
		fetchStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchStatus = async () => {
		const { data } = await axios
			.get(`/auth/subscriptions/plans/status`, {
				params: query,
			})
			.then(({ data }) => data);
		setSubscription(data);
		if (data.mandate !== "completed" && !data.subscription) {
			setTimeout(() => {
				fetchStatus();
			}, 4000);
		}
		if (data.mandate === "completed" && data.subscription) {
			fetchProfile();
		}
	};

	return subscription;
}

export default useSubscriptionStatus;
