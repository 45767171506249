import { createContext, useContext } from "react";
import { TagSize } from "src/components/Tag";

export type Skill = {
	name: string;
	slug: string;
	score?: number;
};

export type Options = {
	methods?: {
		delete?: boolean;
	};
	limit?: number;
};

export type Theme = {
	active?: {
		size?: TagSize;
		className?: string;
	};
	inactive?: {
		size?: TagSize;
		className?: string;
	};
	default?: {
		size?: TagSize;
		className?: string;
	};
};

type SkillSelectorContextType = {
	value: Skill[];
	onChange?: (value: Skill[]) => void;
	search?: string;
	onSearch: React.Dispatch<React.SetStateAction<string>>;
	skills: Skill[];
	setSkills: React.Dispatch<React.SetStateAction<Skill[]>>;
	status: "idle" | "loading";
	setStatus: React.Dispatch<React.SetStateAction<"idle" | "loading">>;
	options?: Options;
	theme?: Theme;
	disabled?: boolean;
};

export const SkillSelectorContext = createContext<SkillSelectorContextType>(
	{} as SkillSelectorContextType
);

export function useSkillSelector() {
	const props = useContext(SkillSelectorContext);
	return props;
}
