import moment from "moment";
import { ApiRegistration } from "src/api/types";
import Badge from "src/components/Badge";
import Indicator from "src/components/layout/Indicator";
import ListItem from "src/components/lists/list-items/ListItem";
import RegistrationStatus from "src/components/RegistrationStatus";
import Stars from "src/components/Stars";
import RegistrationScoreBadge from "src/components/tasks/registrations/RegistrationScoreBadge";
import useWindowSize from "src/hooks/useWindowSize";

interface RegistrationListItemProps {
	registration: ApiRegistration;
}

const RegistrationListItem = ({ registration }: RegistrationListItemProps) => {
	const { isTablet } = useWindowSize();
	const user: any = registration?.user;
	const status = registration.status;

	return (
		<ListItem
			to={`/tasks/${registration?.task?.hid}/show/${registration?.hid}`}
			bordered
			align="center"
			key={registration.id + registration.hid}
		>
			<div className="relative">
				<ListItem.Avatar
					className="rounded-[6px] bg-accent"
					size="medium"
					src={registration?.user?.avatar}
				/>
			</div>
			<ListItem.Content className="gap-1 md:gap-0">
				<div className="flex gap-1 justify-between">
					<ListItem.Title className="line-clamp-1 flex-1">
						{user?.full_name}
					</ListItem.Title>
					<div className="flex items-center gap-2">
						{user?.review > 0 && (
							<Stars
								className="gap-[2px]"
								rating={registration?.user?.review || 0}
							/>
						)}
						{registration?.unread_messages > 0 && (
							<Indicator>
								{registration.unread_messages}
							</Indicator>
						)}
					</div>
				</div>

				{isTablet && (
					<ListItem.Text
						className="line-clamp-2 text-sm opacity-70"
						variant={!isTablet ? "small" : undefined}
					>
						{registration?.excerpt}
					</ListItem.Text>
				)}
				<small className="opacity-70">
					{moment(registration.created_at).fromNow()}
				</small>
				<div className="flex items-center justify-between mt-1">
					{(registration?.is_connected ||
						registration?.is_favorite) && (
						<div className="flex items-center gap-2">
							{registration?.is_connected && (
								<Badge
									size="xsmall"
									variant="primary"
									className="p-0"
								>
									<div className="flex justify-center items-center w-4 h-4 leading-1">
										🤝🏻
									</div>
								</Badge>
							)}
							{registration?.is_favorite && (
								<Badge size="xsmall" className="p-0">
									<div className="flex justify-center items-center w-4 h-4 leading-1">
										<i className="fas fa-star"></i>
									</div>
								</Badge>
							)}
						</div>
					)}
					{status === "pending" && registration?.score ? (
						<RegistrationScoreBadge
							score={registration.score.score}
						/>
					) : (
						<RegistrationStatus size="small" {...{ status }} />
					)}
				</div>
			</ListItem.Content>
		</ListItem>
	);
};

export default RegistrationListItem;
