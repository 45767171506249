import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader";
import TenantIcons from "src/components/tenant/TenantIcons";
import TenantManifest from "src/components/tenant/TenantManifest";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePrefetch from "src/hooks/api/usePrefetch";
import useTenantTheme from "src/hooks/useTenantTheme";

interface TenantProviderProps {
	children: any;
}

const TenantProvider = ({ children }: TenantProviderProps) => {
	const { t } = useTranslation("providers", {
		keyPrefix: "tenant-provider",
	});
	const { status, actions, tenant } = useTenant();
	const prefetch = usePrefetch();
	useTenantTheme();

	useEffect(() => {
		actions.current().then((tenant) => {
			prefetch.run(tenant);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if ((status === "loading" || prefetch.loading) && !tenant?.slug) {
		return (
			<div className="flex justify-center items-center h-screen w-screen">
				<Loader />
			</div>
		);
	}

	if (prefetch.loading) {
		return (
			<div className="flex justify-center items-center h-screen w-screen">
				<Loader />
			</div>
		);
	}

	if (status === "error") {
		return (
			<div className="flex flex-col gap-2 justify-center items-center h-screen w-screen">
				<h2>{t("error.title")}</h2>
				<p>{t("error.description")}</p>
			</div>
		);
	}

	return (
		<>
			<TenantIcons />
			<TenantManifest />
			{children}
		</>
	);
};

export default TenantProvider;
