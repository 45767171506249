import { Route } from "react-router-dom";
import DashboardProfile from "src/views/authenticated/profile/ProfileView";
import DashboardBillingUpcomingView from "src/views/authenticated/profile/billing/BillingUpcomingView";
import DashboardBillingView from "src/views/authenticated/profile/billing/BillingView";
import DashboardChangeMandateView from "src/views/authenticated/profile/billing/ChangeMandateView";
import DashboardChangePlanView from "src/views/authenticated/profile/billing/ChangePlanView";
import DashboardPausePlanView from "src/views/authenticated/profile/billing/PausePlanView";
import DashboardResumePlanView from "src/views/authenticated/profile/billing/ResumePlanView";
import DashboardCompaniesView from "src/views/authenticated/profile/companies/CompaniesView";
import DashboardCompanyView from "src/views/authenticated/profile/company-view/CompanyView";
import UsersView from "src/views/authenticated/profile/users/UsersView";
// import DashboardViewProfileView from "src/views/authenticated/view-profile/ViewProfileView";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import TeamFormModal from "src/views/authenticated/profile/teams/TeamFormModal";
import EditUserModal from "src/views/authenticated/profile/users/EditUserModal";
import InviteUserModal from "src/views/authenticated/profile/users/InviteUserModal";
import TeamsView from "src/views/authenticated/profile/teams/TeamsView";
import ResumeProfile from "src/views/authenticated/profile/resume/ResumeView";
import CreateResumeView from "src/views/authenticated/profile/resume/create/CreateResumeView";
import EducationFormModal from "src/components/ResumeCompanyUser/elements/EducationFormModal";
import WorkExperienceFormModal from "src/components/ResumeCompanyUser/elements/WorkExperienceFormModal";
import ResumeEditThemeModal from "src/components/ResumeCompanyUser/resume-theme/ResumeEditThemeModal";
import ResumePublicView from "src/views/authenticated/profile/resume/public/ResumePublicView";
import ResumeSocialMedia from "src/components/resume/modules/ResumeSocialMedia";
import ProgressView from "src/views/authenticated/profile/progress/ProgressView";

const ProfileRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<ResumePublicView />
			</ProtectedRoute>
		}
		path="/view-profile/:id"
		key="/view-profile/:id"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<ProgressView />
			</ProtectedRoute>
		}
		path="/profile/progress"
		key="/profile/progress"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardProfile />
			</ProtectedRoute>
		}
		path="/profile/show"
		key="/profile/show"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<ResumeProfile />
			</ProtectedRoute>
		}
		path="/profile/resume"
		key="/profile/resume"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<CreateResumeView />
			</ProtectedRoute>
		}
		path="/profile/resume/create/:step?"
		key="/profile/resume/create/:step?"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardCompanyView />
			</ProtectedRoute>
		}
		path="/profile/company"
		key="/profile/company"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardCompaniesView />
			</ProtectedRoute>
		}
		path="/profile/companies"
		key="/profile/companies"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<UsersView />
			</ProtectedRoute>
		}
		path="/profile/users"
		key="/profile/users"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<TeamsView />
			</ProtectedRoute>
		}
		path="/profile/teams"
		key="/profile/teams"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardBillingView />
			</ProtectedRoute>
		}
		path="/profile/billing"
		key="/profile/billing"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardChangePlanView />
			</ProtectedRoute>
		}
		path="/profile/billing/change"
		key="/profile/billing/change"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardChangeMandateView />
			</ProtectedRoute>
		}
		path="/profile/billing/change/mandate"
		key="/profile/billing/change/mandate"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardPausePlanView />
			</ProtectedRoute>
		}
		path="/profile/billing/change/pause"
		key="/profile/billing/change/pause"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardResumePlanView />
			</ProtectedRoute>
		}
		path="/profile/billing/change/resume"
		key="/profile/billing/change/resume"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<DashboardBillingUpcomingView />
			</ProtectedRoute>
		}
		path="/profile/billing/upcoming"
		key="/profile/billing/upcoming"
	/>,
];

export const ProfileModalRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<ResumeEditThemeModal />
			</ProtectedRoute>
		}
		path="/profile/resume/theme/edit"
		key="/profile/resume/theme/edit"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/resume/create/education",
					expanded: false,
					size: "default",
					className: "w-full md:max-w-[340px]",
				}}
			>
				<EducationFormModal />
			</ProtectedRoute>
		}
		path="/profile/resume/create/education"
		key="/profile/resume/create/education"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/resume/create/education",
					expanded: false,
					size: "default",
					className: "w-full md:max-w-[340px]",
				}}
			>
				<EducationFormModal />
			</ProtectedRoute>
		}
		path="/profile/resume/create/education/:id"
		key="/profile/resume/create/education/:id"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/resume/create/work",
					expanded: false,
					size: "default",
					className: "w-full md:max-w-[340px]",
				}}
			>
				<WorkExperienceFormModal />
			</ProtectedRoute>
		}
		path="/profile/resume/create/work"
		key="/profile/resume/create/work"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/resume/create/work",
					expanded: false,
					size: "default",
					className: "w-full md:max-w-[340px]",
				}}
			>
				<WorkExperienceFormModal />
			</ProtectedRoute>
		}
		path="/profile/resume/create/work/:id"
		key="/profile/resume/create/work/:id"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/resume",
					expanded: false,
					size: "default",
					className: "w-full md:max-w-[340px]",
				}}
			>
				<ResumeSocialMedia editable modal />
			</ProtectedRoute>
		}
		path="/profile/resume/edit/social"
		key="/profile/resume/edit/social"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/users",
					expanded: false,
					size: "default",
				}}
				type="company"
			>
				<TeamFormModal />
			</ProtectedRoute>
		}
		path="/profile/users/teams/create"
		key="/profile/users/teams/create"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/users",
					expanded: false,
					size: "default",
				}}
				type="company"
			>
				<TeamFormModal />
			</ProtectedRoute>
		}
		path="/profile/users/teams/:id/edit"
		key="/profile/users/teams/:id/edit"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/users",
					expanded: false,
					size: "default",
				}}
				type="company"
			>
				<EditUserModal />
			</ProtectedRoute>
		}
		path="/profile/users/:id/edit"
		key="/profile/users/:id/edit"
	/>,
	<Route
		element={
			<ProtectedRoute
				variant="modal"
				modal={{
					backUrl: "/profile/users",
					expanded: false,
					size: "default",
				}}
				type="company"
			>
				<InviteUserModal />
			</ProtectedRoute>
		}
		path="/profile/users/invite"
		key="/profile/users/invite"
	/>,
];

export default ProfileRoutes;
