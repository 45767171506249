import axios from "axios";
import { useEffect, useState } from "react";
import Button from "src/components/Button";
import QuestionForm, {
	Question,
} from "src/components/forms/QuestionForm/QuestionForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { TaskFormOptions } from "src/components/tasks/TaskForm";
import Form from "src/components/tasks/TaskForm/Form";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTaskForm from "src/hooks/api/tasks/useTaskForm";
import useQueryString from "src/hooks/useQueryString";
import untruncateJson from "untruncate-json";

type Mode = "MANUAL" | "AI" | undefined;

interface InterviewProps extends TaskFormOptions, Translation {
	defaultMode?: Mode;
}

const InterviewForm = ({ t, hideButtons, defaultMode }: InterviewProps) => {
	const qs = useQueryString();
	const { task, actions, current } = useTaskForm();
	const { tenant } = useTenant();

	const [mode, setMode] = useState<Mode>(
		!tenant.modules.includes("ai-description-creation") || qs?.repeat
			? "MANUAL"
			: defaultMode
	);
	const [isGenerating, setIsGenerating] = useState(false);
	const questions = task?.questions || [];
	const isEmpty = questions?.length === 0 || questions[0].title === "";

	const getAutoFillQuery = () => {
		if (task?.category_id) {
			return `category_id=${task?.category_id}`;
		}
		if (task?.category) {
			return "blank=1";
		}
		return "";
	};

	const handleAddNewQuestion = () => {
		actions.set({
			questions: [
				...(task.questions || []),
				{
					title: "",
					answers: [],
				} as Question,
			],
		});
	};

	const formatQuestion = (question: { question: string; answers: any[] }) => {
		return {
			title: question.question,
			create_type: "AI",
			answers: (question?.answers || []).map((answer: any) => ({
				answer: answer.title,
				is_right: answer.is_correct,
			})),
		};
	};

	const handleGenerateQuestions = async () => {
		if (!task.id) return;
		setIsGenerating(true);
		setMode("AI");
		axios
			.post(
				`/ai/task/${task.id}/interview-questions`,
				{},
				{
					responseType: "stream",
					onDownloadProgress: function (progressEvent) {
						const response = progressEvent.currentTarget.response;
						const data = JSON.parse(untruncateJson(response));
						const questions = (data?.questions || []).map(
							formatQuestion
						);
						actions.set({
							questions,
						});
					},
				}
			)
			.then(({ data }: any) => {
				const questions = data?.questions;
				actions.set({
					questions: [
						...questions.map(formatQuestion),
						{
							title: t("defaults.closing-question"),
							create_type: "AI",
							answers: [],
						},
					],
				});
			})
			.finally(() => setIsGenerating(false));
	};

	useEffect(() => {
		if (!task?.questions || !task?.questions?.length) {
			handleAddNewQuestion();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form
			{...{ hideButtons }}
			title={t("title")}
			onSubmit={async () => {
				actions.updateCurrent(current + 1);
			}}
			value={{}}
			nextDisabled={false}
			nextButtonLabel={!isEmpty ? t("next-step") : t("skip")}
		>
			{() => {
				if (!mode) {
					return (
						<div className="flex flex-col justify-center items-center gap-4">
							<div className="flex flex-col gap-2 text-center max-w-md">
								<strong>{t("mode-less.title")}</strong>
								<p className="opacity-70">
									{t("mode-less.description")}
								</p>
							</div>
							<div className="flex flex-col items-center gap-2">
								{tenant.modules.includes(
									"ai-description-creation"
								) && (
									<Button onClick={handleGenerateQuestions}>
										{t("mode-less.ai")}
									</Button>
								)}
								<Button
									xsmall
									type="transparent"
									onClick={() => setMode("MANUAL")}
								>
									{t("mode-less.manual")}
								</Button>
							</div>
						</div>
					);
				}

				return (
					<>
						<p>{t("description")}</p>
						<div className="flex flex-col gap-2">
							{(questions || []).map(
								(question: Question, index: number) => (
									<QuestionForm
										key={`question-${index}`}
										input={{
											label: t("question-label", {
												count: index + 1,
											}),
										}}
										settings={{
											addOnFocus: task?.id ? false : true,
										}}
										autoFillQuery={getAutoFillQuery()}
										value={question}
										onChange={(value) => {
											if (!value) {
												const questions =
													task?.questions?.filter(
														(_: any, i: number) =>
															i !== index
													);
												actions.set({
													questions,
												});
												return;
											}
											actions.set({
												create_type: "MANUAL",
												questions: task.questions.map(
													(
														item: Question,
														i: number
													) => {
														if (i === index)
															return {
																...value,
																create_type:
																	"MANUAL",
															};

														return item;
													}
												),
											});
										}}
									/>
								)
							)}
						</div>

						<div className="flex justify-center">
							<Button
								disabled={
									(questions &&
										questions.length &&
										!questions[questions.length - 1]
											.title) ||
									isGenerating
								}
								loading={isGenerating}
								onClick={handleAddNewQuestion}
							>
								<i className="fas fa-plus mr-2"></i>
								{t("add-question")}
							</Button>
						</div>
					</>
				);
			}}
		</Form>
	);
};

InterviewForm.locale = {
	nl: {
		title: "Aanmeldformulier",
		"title-badge": "Nieuw",
		description:
			"Stel de opdrachtnemer wat vragen, dit kan vrije invoer zijn of een meerkeuze vraag.",
		instruction:
			"Indien je de vragen leeg laat worden er aanmeldingen gedaan op basis van motivatie.",
		"add-question": "Vraag toevoegen",
		"question-label": "Vraag {{count}}",
		"next-step": "Volgende stap",
		skip: "Overslaan",
		"mode-less": {
			title: "Aanmeldformulier maken",
			description:
				"Hoe wil je het aanmeldformulier maken? Je kunt kiezen voor handmatig of automatisch d.m.v. AI. Als je enkel een motivatie belangrijk vindt, kun je deze stap overslaan.",
			manual: "Handmatig",
			ai: "Vragen genereren",
		},
		defaults: {
			"closing-question": "Waarom wil je deze opdracht uitvoeren?",
		},
	},
	en: {
		title: "Interview form",
		"title-badge": "New",
		description:
			"Ask the task executor some questions, this can be free text or a multiple choice question.",
		instruction:
			"If you leave the questions empty, registrations will be made based on motivation.",
		"add-question": "Add question",
		"question-label": "Question {{count}}",
		"next-step": "Next step",
		skip: "Skip",
		"mode-less": {
			title: "Create interview form",
			description:
				"How do you want to create the interview form? You can choose manual or automatic through AI. If you only find motivation important, you can skip this step.",
			manual: "Manual",
			ai: "Generate questions",
		},
		defaults: {
			"closing-question": "Why do you want to perform this task?",
		},
	},
};

export default withTranslation(InterviewForm);
