import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import FacebookButton from "src/components/FacebookButton";
import Link from "src/components/Link";
import LoginForm from "src/components/forms/auth/LoginForm";
import OAuthProviders from "src/components/forms/auth/OAuthProviders";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";
import { useParseRedirectUrl } from "src/hooks/useRedirectUrl";

const LoginView = ({ t }: Translation) => {
	const qs = useQueryString();
	const url = useParseRedirectUrl();
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const { tenant } = useTenant();
	const hasMultiProviders = ["odylyt", "getjobsdone"].includes(tenant.slug);
	useEffect(() => {
		if (auth?.id) {
			navigate(url || "/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth, location]);

	return (
		<AuthLayout head={{ title: t("title"), description: t("description") }}>
			{qs?.password_reset === "success" && (
				<div className="bg-green-200 mb-4 p-4 flex flex-col text-green px-6 rounded-md">
					<p>{t("password-reset.success")}</p>
				</div>
			)}

			{auth?.id ? (
				<>
					<h2>{t("already-signed-in.title")}</h2>
					<div className="flex items-center mt-4">
						<Button to="/dashboard">
							{t("already-signed-in.button")}
							<i className="fas fa-arrow-right ml-4"></i>
						</Button>
					</div>
				</>
			) : (
				<div className="flex flex-col gap-8">
					{hasMultiProviders && <OAuthProviders />}
					<hr className="border-border" />
					<div className="flex flex-col flex-1 gap-4">
						<LoginForm redirectUrl={url || undefined} />
					</div>
					{!hasMultiProviders && (
						<FacebookButton
							hideDisclaimer
							redirectUrl={url || undefined}
							type="login"
							params={{
								is_type_later: true,
							}}
						/>
					)}
					<div className="flex justify-center items-center flex-col">
						<Link className="text-center" to="/signup">
							<span>{t("no-account.label")}</span>
							<strong className="ml-2">
								{t("no-account.cta")}
							</strong>
						</Link>
					</div>
				</div>
			)}
		</AuthLayout>
	);
};

LoginView.locale = {
	nl: {
		"already-signed-in": {
			title: "Je bent al ingelogd",
			button: "Naar dashboard",
		},
		or: "of via email",
		title: "Inloggen",
		description:
			"Welkom terug, vul de onderstaande velden in om in te loggen.",
		"no-account": {
			label: "Nog geen account?",
			cta: "Maak deze gemakkelijk aan.",
		},
		"password-reset": {
			success:
				"Je wachtwoord is aangepast, je kan nu d.m.v. je nieuwe wachtwoord inloggen",
		},
	},
	en: {
		"already-signed-in": {
			title: "You are already signed in",
			button: "Go to dashboard",
		},

		or: "via email",
		title: "Login",
		description: "Welcome back, fill in the fields below to sign in",
		"no-account": {
			label: "No account?",
			cta: "Create it easily",
		},
		"password-reset": {
			success:
				"The password has changed, you are now able to login with your new credentials",
		},
	},
};

export default withTranslation(LoginView);
