const subscription = {
	"thanks-view": {
		title: "Your subscription is chosen",
		subtitle: "One moment please, we're preparing your account...",
	},
	"index-view": {
		already: {
			title: "You already have an active subscription",
			button: "Continue to my dashboard",
		},
		back: "Back to dashboard",
		loyal: {
			title: "Special subscription for loyal customers!",
			description:
				"Thank you for already being a customer with us for <1>{{days}}</1> days! That's why we have a special discount for you.",
		},
		head: {
			title: "Choose package",
			subtitle: "Choose the package that fits your organization.",
		},
	},
};

export default subscription;
