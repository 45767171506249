const registrations = {
	status: {
		open: "Open",
		pending: "Pending",
		completed: "Completed",
		deleted: "Deleted",
		expired: "Expired",
		matched: "Matched",
		denied: "Denied",
		canceled: "Canceled",
	},
	"recent-list": {
		title: "Recent Registrations",
		empty: "You have no recent registrations for your tasks",
	},
	card: {
		cta: "View Registration",
	},
	"accept-modal": {
		title: "Approve Registration",
		"payment-description":
			"This payment will be held on a third-party account, when you provide a positive review the amount will be transferred to the professional.",
		"sign-direct": "Automatically sign agreement",
		"sign-direct-sublabel": "to view the agreement",
	},
	buttons: {
		review: "Post Review",
		denie: "Deny",
		accept: "Accept",
		"cancel-task": "Cancel Task",
		payout: "Payout",
	},
	"registration-details": {
		registration: "Registration",
		"reason-of-deny": "Reason of Denial",
		telephone: "Telephone",
		"about-me": "About Me",
		"current-education": "Current Education",
		"current-education-text": "{{title}} at {{subtitle}}",
		bio: "Bio",
		skills: "Skills",
	},
	"registration-head": {
		"full-cv": "Full CV",
	},
	"registration-match-details": {
		envelope: "Agreement",
		payment: "Payment",
		"no-payment":
			"No payment has been made for this match. Create a payment so that the task can be completed.",
		"create-payment": "Create Payment",
	},
	"registration-reviews": {
		client: "Client",
		contractor: "Contractor",
		review: {
			title: "Review",
			"not-created": "No review received yet",
			"created-by": "Created by",
		},
	},
	"task-details": {
		title: "Task",
		date: {
			title: "Date",
			between: "Between {{start}} and {{end}}",
			at: "On {{date}}",
		},
		description: "Description",
		address: {
			title: "Address",
			home: "This task can be done at home",
		},
		budget: "Task value",
		"per-hour": "/ hour",
		"estimated-duration": {
			title: "Estimated duration",
			hour: "{{hour}} hours",
		},
		client: {
			title: "Client",
			"company-name": "Company name",
			"created-by": "Created by",
			reviews: "Reviews",
		},
		envelope: {
			title: "Agreement",
		},
		payment: {
			title: "Payment",
		},
	},
	"select-registration-pane": {
		back: "Back to registrations",
		empty: {
			title: "First select a registration",
		},
	},
	"registrations-sidebar": {
		tabs: {
			all: "All",
			accepted: "Matched",
			open: "Open",
			denied: "Rejected",
			favorites: "Favorites",
		},
	},
	"selected-registration-pane": {
		tabs: {
			match: "Match info",
			registration: "Registration",
			reviews: "Reviews",
			resume: "Resume",
		},
		empty: {
			title: "No registration found",
		},
		"not-finishable": {
			title: "Complete task",
			description:
				"It is not yet possible to pay out the task, a payment is still missing.",
			button: "Back",
		},
	},
	"registration-contact-details": {
		title: "Contact information",
		telephone: "Telephone number",
		email: "Email",
		website: "Website",
	},
};

export default registrations;
