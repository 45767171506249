import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeCardContext from "../ResumeCardContext";
import { ApiSocial } from "src/api/types";
import useNavigateModal from "src/hooks/useNavigateModal";
import ResumeSectionTitle from "src/components/ResumeCompanyUser/resume-card/ResumeSectionTitle";
import useBackUrl from "src/hooks/useBackUrl";

interface socialIcon {
	name: string;
	icon: string;
}

interface ResumeSocialMediaProps extends Translation {
	editable?: boolean;
}

const VALID_SOCIALS: socialIcon[] = [
	{
		name: "INSTAGRAM",
		icon: "fab fa-instagram",
	},
	{
		name: "LINKEDIN",
		icon: "fab fa-linkedin-in",
	},
	{
		name: "FACEBOOK",
		icon: "fab fa-facebook-f",
	},
	{
		name: "TWITTER",
		icon: "fab fa-twitter",
	},
	{ name: "YOUTUBE", icon: "fab fa-youtube" },
	{ name: "DRIBBBLE", icon: "fab fa-dribbble" },
	{ name: "BEHANCE", icon: "fab fa-behance" },
];

function ResumeSocialMedia({ t, editable }: ResumeSocialMediaProps) {
	const { resume } = useResumeCardContext();
	const navigateModal = useNavigateModal();
	const backUrl = useBackUrl();
	let updatedSocialMedia: ApiSocial[] = [];

	if (resume.social_media.length) {
		updatedSocialMedia = resume.social_media.map((profile) => {
			const matchingSocial = VALID_SOCIALS.find(
				(social) => social.name === profile.platform
			);

			if (matchingSocial) {
				return {
					...profile,
					icon: matchingSocial.icon,
				};
			} else {
				return profile;
			}
		});
	}

	const handleClick = (link: string) => {
		window.open(link);
	};

	return (
		<div className="flex flex-col gap-4">
			{editable ? (
				<div className="flex justify-between">
					<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>
					<button
						onClick={(event) => {
							event.preventDefault();
							navigateModal(
								`/profile/resume/edit/social?${backUrl}`
							);
						}}
					>
						<i className="fas fa-pencil"></i>
					</button>
				</div>
			) : (
				<>
					<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>
				</>
			)}
			{!updatedSocialMedia.length ? (
				<p>{t("empty")}</p>
			) : (
				<div className="flex flex-row gap-8">
					{updatedSocialMedia.map((social: any) => (
						<div
							key={social.id}
							onClick={() => handleClick(social.direct_link)}
							className="flex flex-row gap-1 hover:border p-2 cursor-pointer"
						>
							<div className="block min-w-[18px]">
								<i className={social.icon}></i>
							</div>
							<span className="flex-1">
								{social.platform.toLowerCase()}
							</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

ResumeSocialMedia.locale = {
	nl: {
		title: "Social media",
		empty: "Voeg je social media profielen toe aan je CV",
	},
	en: {
		title: "Social media",
		empty: "Add your social media profiles to your resume",
	},
};

export default withTranslation(ResumeSocialMedia);
