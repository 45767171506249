import { Route } from "react-router-dom";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import OnboardingView from "src/views/authenticated/onboarding/OnboardingView";

const OnboardingRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<OnboardingView />
			</ProtectedRoute>
		}
		path="/onboarding"
		key="/onboarding"
	/>,
];

export default OnboardingRoutes;
