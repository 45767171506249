import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
// import "./credit-view.scss";
import Layout from "src/components/layout/Layout";
import Card from "src/components/Card";
import Input from "src/components/form/Input";
import Button from "src/components/Button";
import Checkout from "./Checkout";
import KeyValue from "src/components/KeyValue";
import useQueryString from "src/hooks/useQueryString";
import useAuth from "src/hooks/selectors/useAuth";
import { useTranslation } from "react-i18next";
// import DecemberBanner from "src/components/DecemberBanner";

const CreditView = () => {
	const { t } = useTranslation("credit-view");
	const { p } = useQueryString();
	const [price, setPrice] = useState(p || undefined) as any;

	return (
		<Layout className="credit-view" title={t("title")}>
			<div className="container-small">
				{!price ? (
					<PriceForm {...{ price, setPrice }} />
				) : (
					<Checkout
						{...{ price }}
						btw={0.21}
						onCancel={() => setPrice(undefined)}
					/>
				)}
			</div>
		</Layout>
	);
};

const PriceForm = ({
	price,
	setPrice,
}: {
	price?: number;
	setPrice: (price?: number) => void;
}) => {
	const { t } = useTranslation("credit-view", {
		keyPrefix: "price-form",
	});

	const CreditScheme = Yup.object().shape({
		price: Yup.number().required(t("required")).min(1),
	});
	const auth = useAuth();
	const credit_fee = parseFloat(auth.service_rate) || 0.2;

	const calculatePrices = (
		p: number
	): {
		subtotal: number;
		btw: number;
		total: number;
		partner_fee: number;
	} => {
		const partner_fee = 1;
		const price = Number(p);
		return {
			subtotal: price,
			btw: (price + partner_fee) * 0.21,
			total: (price + partner_fee) * 1.21,
			partner_fee,
		};
	};

	const renderTotal = (p: number) => {
		return (
			<div className="flex flex-col">
				<KeyValue.Small
					label={t("subtotal")}
					text={`€ ${calculatePrices(p).subtotal.toFixed(2)}`}
				/>
				<KeyValue.Small
					label={t("service-fee")}
					text={`€ ${calculatePrices(p).partner_fee.toFixed(2)}`}
				/>
				<KeyValue.Small
					label={t("vat")}
					text={`€ ${calculatePrices(p).btw.toFixed(2)}`}
				/>

				<KeyValue.Small
					label={t("total")}
					text={`€ ${calculatePrices(p).total.toFixed(2)}`}
				/>
			</div>
		);
	};

	return (
		<Card>
			<strong>{t("title")}</strong>
			<p>
				{t("description", {
					fee: credit_fee * 100,
				})}
			</p>
			<Formik
				initialValues={{ price: price || undefined }}
				validationSchema={CreditScheme}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					if (values.price) {
						setSubmitting(false);
						setPrice(values.price);
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit}>
						<Input
							label={t("label")}
							name="price"
							type="price"
							value={values.price}
							valid={
								values.price &&
								values.price !== 0 &&
								!errors.price &&
								values.price !== undefined
							}
							error={
								errors.price && touched.price && errors.price
							}
							{...{ handleChange, handleBlur }}
						/>
						{values?.price && renderTotal(values.price)}

						<div className="flex gap-4 justify-between mt-8">
							<Button
								type="dark"
								to="/dashboard"
								className="gtm-werkgever-dashboard-opwaarderen--button-annuleren"
							>
								{t("cancel")}
							</Button>
							<Button
								submit
								loading={isSubmitting}
								className="gtm-werkgever-dashboard-opwaarderen--cta-afrekenen"
							>
								{t("checkout")}
							</Button>
						</div>
					</form>
				)}
			</Formik>
		</Card>
	);
};

export default CreditView;
