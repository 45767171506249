import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "src/components/layout/Layout";
import SubscriptionChangedBanner from "src/components/promotion/banners/SubscriptionChangedBanner";
import BillingHead from "src/components/subscription/billing/BillingHead";
import MandateList from "src/components/subscription/billing/MandateList";
import MinimalSubscriptionPlans from "src/components/subscription/MinimalSubscriptionPlans";
import useRedirectToCustomerPortal from "src/hooks/useRedirectToCustomerPortal";
import useSubscription from "src/hooks/selectors/useSubscription";

export default function ChangePlanView() {
	const { t } = useTranslation("payment-view", {
		keyPrefix: "change-plan-view",
	});
	const { isLoading } = useRedirectToCustomerPortal();
	const subscription = useSubscription();

	return (
		<Layout
			className="dashboard-profile"
			container="xxl"
			title={t("title")}
			loading={isLoading}
		>
			<Layout.BackButton to="/profile/billing" label={t("back")} />
			<div className="flex flex-col pb-16 flex-1 gap-8">
				{subscription?.next_plan && <SubscriptionChangedBanner />}

				<BillingHead
					title={t("subscription-head.title")}
					subtitle={t("subscription-head.subtitle")}
				/>
				<MinimalSubscriptionPlans />

				<BillingHead
					className="mt-8"
					title={t("mandate-head.title")}
					subtitle={t("mandate-head.subtitle")}
				/>
				<MandateList />

				<div className="flex flex-1 justify-center items-center">
					<NavLink
						className="underline opacity-80"
						to="/profile/billing/change/pause"
					>
						<small>{t("pause")}</small>
					</NavLink>
				</div>
			</div>
		</Layout>
	);
}
