import {
	Options,
	Skill,
	SkillSelectorContext,
	Theme,
} from "src/components/form/skill-selector/Provider";
import Results from "src/components/form/skill-selector/elements/Results";
import Input from "src/components/form/skill-selector/elements/Input";
import { ComponentProps, useEffect, useState } from "react";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";
import axios from "axios";
import Description from "src/components/form/skill-selector/elements/Description";
import { cn } from "src/lib/utils";
import Loading from "src/components/form/skill-selector/elements/Loading";

interface SkillSelectorProps {
	defaultSearch?: string;
	value?: Skill[];
	onChange?: (value: Skill[]) => void;
	options?: Options;
	theme?: Theme;
	disabled?: boolean;
}

const SkillSelector = ({
	defaultSearch,
	value,
	onChange,
	className,
	children,
	options,
	theme,
	disabled,
	...rest
}: SkillSelectorProps & Omit<ComponentProps<"div">, "onChange">) => {
	const [search, setSearch] = useState<string>("");
	const [skills, setSkills] = useState<Skill[]>([]);
	const [status, setStatus] = useState<"idle" | "loading">("loading");

	const getSkills = async (s: string) => {
		const { data } = await axios
			.get(`/skilllens/skills?q=${s}`)
			.then(({ data }) => data);
		setSkills(data);
	};

	useUpdateEffectDebounce(search, 500, (search) => {
		if (search) {
			setStatus("loading");
			getSkills(search).finally(() => setStatus("idle"));
		}
	});

	useEffect(() => {
		if (defaultSearch) {
			getSkills(defaultSearch).finally(() => setStatus("idle"));
		} else {
			setStatus("idle");
		}
		return () => {
			setSkills([]);
			setStatus("loading");
		};
	}, [defaultSearch]);

	return (
		<SkillSelectorContext.Provider
			value={{
				value: value || [],
				onChange,
				search,
				onSearch: setSearch,
				skills,
				setSkills,
				status,
				setStatus,
				options,
				theme,
				disabled,
			}}
		>
			<div {...rest} className={cn("flex flex-col gap-1", className)}>
				{children}
			</div>
		</SkillSelectorContext.Provider>
	);
};

export { SkillSelector as Root, Results, Input, Description };

SkillSelector.Root = SkillSelector;
SkillSelector.Results = Results;
SkillSelector.Input = Input;
SkillSelector.Description = Description;
SkillSelector.Loading = Loading;

export default SkillSelector;
