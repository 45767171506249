import { ReactNode } from "react";
import { ApiResume } from "src/api/types";
import { ResumeCardContext } from "./ResumeCardContext";
import ResumeHead from "./elements/ResumeHead";
import ResumeBio from "./elements/ResumeBio";
import ResumeSkills from "./elements/ResumeSkills";
import ResumeEducation from "./elements/ResumeEducation";
import ResumeWork from "./elements/ResumeWork";
import ResumeReviews from "./elements/ResumeReviews";
import ResumeSocialMedia from "./elements/ResumeSocialMedia";
import ResumeBadge from "./elements/ResumeBadge";
import ResumePublicHead from "./elements/ResumePublicHead";

export type ResumeOptions = {
	title?: {
		variant?: "large" | "uppercase";
	};
};

interface UserCardProps {
	resume: ApiResume;
	children: ReactNode;
	options?: ResumeOptions;
}

const ResumeCard = ({ resume, children, options }: UserCardProps) => {
	return (
		<ResumeCardContext.Provider value={{ resume, options }}>
			{children}
		</ResumeCardContext.Provider>
	);
};

ResumeCard.Head = ResumeHead;
ResumeCard.PublicHead = ResumePublicHead;
ResumeCard.Bio = ResumeBio;
ResumeCard.Skills = ResumeSkills;
ResumeCard.Education = ResumeEducation;
ResumeCard.Work = ResumeWork;
ResumeCard.Reviews = ResumeReviews;
ResumeCard.SocialMedia = ResumeSocialMedia;
ResumeCard.Badge = ResumeBadge;

export default ResumeCard;
