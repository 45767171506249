import { ApiDay } from "src/api/types";

interface EnvelopeStatusesProps {
	[key: string]: {
		icon?: string;
		color: {
			bg: string;
			color: string;
		};
	};
}

export const ENVELOPE_STATUSES: EnvelopeStatusesProps = {
	SIGNED: {
		icon: "fas fa-check",
		color: {
			bg: "green",
			color: "text",
		},
	},
	VIEWED: {
		icon: "fas fa-eye",
		color: {
			bg: "gray",
			color: "dark",
		},
	},
	CREATED: {
		color: {
			bg: "gray",
			color: "dark",
		},
	},
	SEND: {
		icon: "fas fa-paper-plane",
		color: {
			bg: "gray",
			color: "dark",
		},
	},
};

export const ADVERTISEMENTS_PRICE = {
	tasks: 9.95,
};

export const BANK_ICONS = {
	"abn-amro": "/assets/images/payment-methods/abn-amro.png",
	"asn-bank": "/assets/images/payment-methods/asn-bank.png",
	bunq: "/assets/images/payment-methods/bunq.png",
	handelsbanken: "/assets/images/payment-methods/handelsbanken.png",
	ing: "/assets/images/payment-methods/ing.png",
	knab: "/assets/images/payment-methods/knab.png",
	rabobank: "/assets/images/payment-methods/rabobank.png",
	regiobank: "/assets/images/payment-methods/regiobank.png",
	revolut: "/assets/images/payment-methods/revolut.png",
	"sns-bank": "/assets/images/payment-methods/sns-bank.png",
	sns: "/assets/images/payment-methods/sns.png",
	"triodos-bank": "/assets/images/payment-methods/triodos-bank.png",
	"van-lanschot-bankiers":
		"/assets/images/payment-methods/van-lanschot-bankiers.png",
	unknown: "/assets/images/payment-methods/unknown.png",
} as any;

export const RESUME_ITEMS = ["education", "jobs", "skills"];

export const RESUME_EXPERIENCES_ICONS = {
	job: {
		icon: "/assets/images/emoji/office-worker.png",
	},
	education: {
		icon: "/assets/images/emoji/student.png",
	},
} as any;

export const SHARE_TYPES = {
	facebook: {
		icon: "fab fa-facebook-square",
		color: "#1877F2",
	},
	linkedin: {
		icon: "fab fa-linkedin",
		color: "#0A66C2",
	},
	whatsapp: {
		icon: "fab fa-whatsapp",
		color: "#25D366",
	},
	twitter: {
		icon: "fab fa-twitter",
		color: "#1DA1F2",
	},
	mail: {
		icon: "fas fa-envelope",
		color: "#18383B",
	},
} as any;

export const ALLOWED_EXTENSIONS = [
	".jpg",
	".jpeg",
	".svg",
	".webp",
	".png",
	".pdf",
	".docx",
	".mp4",
	".xlsx",
	".csv",
	".doc",
];

export const EMAIL_DOMAINS = [
	"gmail.com",
	"yahoo.com",
	"hotmail.com",
	"outlook.com",
	"aol.com",
	"icloud.com",
	"protonmail.com",
	"zoho.com",
	"mail.com",
	"yandex.com",
	"gmx.com",
	"fastmail.com",
	"inbox.com",
	"hotmail.co.uk",
	"live.com",
	"msn.com",
	"yahoo.co.uk",
	"rocketmail.com",
	"tutanota.com",
	"mail.ru",
	"qq.com",
	"163.com",
	"126.com",
	"sina.com",
	"sohu.com",
	"outlook.jp",
	"yahoo.co.jp",
	"hotmail.fr",
	"live.fr",
	"orange.fr",
	"laposte.net",
	"free.fr",
	"gmx.de",
	"web.de",
	"t-online.de",
	"freenet.de",
	"hotmail.de",
	"yahoo.de",
	"mail.bg",
	"abv.bg",
	"mail.bg",
	"dir.bg",
];

export const ALLOWED_FILES =
	"image/jpeg, image/png, text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/gif, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf";

export const DEFAULT_COLORS = [
	"#FF0000",
	"#0000FF",
	"#FF00FF",
	"#FFA500",
	"#800080",
	"#008000",
	"#FF4500",
	"#000000",
];

export const DAYS_IN_WEEK: ApiDay[] = [
	"MONDAY",
	"TUESDAY",
	"WEDNESDAY",
	"THURSDAY",
	"FRIDAY",
	"SATURDAY",
	"SUNDAY",
];
