import { createContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal, { ModalHeadVariant, ModalSize } from "src/components/Modal";
import { useParseBackUrl } from "src/hooks/useBackUrl";

export const ModalViewContext = createContext({
	isModal: false,
	title: "",
	actions: <></>,
	setTitle: (title: string) => {},
	setOnClose: (cb: () => void) => {},
	setActions: (cb: React.ReactNode) => {},
	onClose: () => {},
} as any);

// type Modify<T, R> = Omit<T, keyof R> & R;

export type ModalElementProps = {
	onClose?: () => void;
	backUrl: string;
	expanded?: boolean;
	size: ModalSize;
	className?: string;
	head?: ModalHeadVariant;
};

type ModalState = {
	title?: string;
	onClose?: () => void;
	actions?: any;
};

interface ModalViewProps {
	children?: any;
	visible: boolean;
	modal: ModalElementProps;
}

const ModalView = ({ children, visible: _visible, modal }: ModalViewProps) => {
	const [visible, setVisible] = useState(_visible);
	const [modalState, setModalState] = useState<ModalState>();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const parsedBackUrl = useParseBackUrl();
	const back = location?.state?.back;

	const handleClose = () => {
		if (!visible) return;
		setVisible(false);

		setTimeout(() => {
			if (parsedBackUrl) {
				navigate(parsedBackUrl);
				return;
			}
			if (modalState?.onClose) {
				modalState.onClose();
			}
			if (modal?.onClose) {
				modal.onClose();
				return;
			}
			if (back?.to) {
				navigate(back.to);
				return;
			}
			if (modal?.backUrl) {
				let path = modal.backUrl;

				Object.keys(params || {}).map((key) => {
					path = path.replace(`:${key}`, params[key] || "");
					return key;
				});

				navigate(path);
			}
		}, 600);
	};

	return (
		<ModalViewContext.Provider
			value={{
				isModal: true,
				title: modalState?.title || "",
				actions: undefined,
				setTitle: (title: string) =>
					setModalState((state: any) => ({
						...state,
						title,
					})),
				setOnClose: (cb: () => void) =>
					setModalState((state: any) => ({
						...state,
						onClose: cb,
					})),
				setActions: (actions: any) => {
					setModalState((state: any) => ({
						...state,
						actions,
					}));
				},
				onClose: handleClose,
			}}
		>
			<Modal
				{...modal}
				actions={modalState?.actions}
				{...{ visible }}
				onClose={handleClose}
			>
				{children}
			</Modal>
		</ModalViewContext.Provider>
	);
};

export default ModalView;
