import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Company } from "src/api/types";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Switch from "src/components/form/Switch";
import { useTranslation } from "react-i18next";

type CompanyFormProps = {
	company: Company;
	onSubmit: (c: Company) => any;
	prevButton?: any;
	disableButton?: boolean;
	buttonLabel?: string;
	type?: string;
	withKvk?: boolean;
};

const CompanyForm = ({
	company,
	onSubmit,
	prevButton,
	disableButton,
	buttonLabel,
	type,
	withKvk,
}: CompanyFormProps) => {
	const { t } = useTranslation(["forms", "schemes", "buttons"]);

	const companyScheme: any = {
		name: Yup.string().required(t("required", { ns: "schemes" })),
		street: Yup.string().required(t("required", { ns: "schemes" })),
		house_number: Yup.string().required(t("required", { ns: "schemes" })),
		city: Yup.string().required(t("required", { ns: "schemes" })),
		zipcode: Yup.string().required(t("required", { ns: "schemes" })),
		branche: Yup.string().required(t("required", { ns: "schemes" })),
		website: Yup.string(),
		bio: Yup.string(),
		invoice_email: Yup.string()
			.email(t("email", { ns: "schemes" }))
			.nullable(),
	};

	if (withKvk) {
		companyScheme.kvk = Yup.string().required(
			t("required", { ns: "schemes" })
		);
	}

	return (
		<Formik
			initialValues={{
				name: company?.name || "",
				kvk: company?.kvk || "",
				street: company?.street || "",
				house_number: company?.house_number || "",
				city: company?.city || "",
				zipcode: company?.zipcode || "",
				website: company?.website || "",
				branche: company?.branche || "",
				bio: company?.bio || "",
				invoice_email: company?.invoice_email,
				invoice_send_copy: company?.invoice_send_copy,
			}}
			validationSchema={Yup.object().shape(companyScheme)}
			onSubmit={(values: any, { setSubmitting }) => {
				onSubmit(values).then(() => setSubmitting(false));
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValid,
			}) => (
				<form className="flex flex-col gap-4" onSubmit={handleSubmit}>
					{type === "edit" && (
						<>
							<Input
								noMarginBottom
								label={t("invoice-mail", {
									ns: "forms",
									keyPrefix: "company-form",
								})}
								name="invoice_email"
								value={values.invoice_email || ""}
								valid={
									values.invoice_email &&
									!errors.invoice_email
								}
								error={
									errors.invoice_email &&
									touched.invoice_email &&
									errors.invoice_email
								}
								{...{ handleChange, handleBlur }}
							/>

							<Switch
								label={t("personal-invoice-mail", {
									ns: "forms",
									keyPrefix: "company-form",
								})}
								onChange={(value) => {
									handleChange({
										target: {
											name: "invoice_send_copy",
											value,
										},
									});
								}}
								value={values.invoice_send_copy}
							/>
						</>
					)}

					<Input
						noMarginBottom
						label={t("company-name", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="name"
						value={values.name}
						valid={values.name && !errors.name}
						error={errors.name && touched.name && errors.name}
						{...{ handleChange, handleBlur }}
					/>
					{withKvk && (
						<Input
							noMarginBottom
							label={t("chamber-of-commerce", {
								ns: "forms",
								keyPrefix: "company-form",
							})}
							name="kvk"
							value={values.kvk}
							valid={values.kvk && !errors.kvk}
							error={errors.kvk && touched.kvk && errors.kvk}
							{...{ handleChange, handleBlur }}
						/>
					)}
					<Input
						noMarginBottom
						label={t("street", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="street"
						value={values.street}
						valid={values.street && !errors.street}
						error={errors.street && touched.street && errors.street}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						noMarginBottom
						label={t("house-number", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="house_number"
						value={values.house_number}
						valid={values.house_number && !errors.house_number}
						error={
							errors.house_number &&
							touched.house_number &&
							errors.house_number
						}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						noMarginBottom
						label={t("zipcode", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="zipcode"
						value={values.zipcode}
						valid={values.zipcode && !errors.zipcode}
						error={
							errors.zipcode && touched.zipcode && errors.zipcode
						}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						noMarginBottom
						label={t("city", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="city"
						value={values.city}
						valid={values.city && !errors.city}
						error={errors.city && touched.city && errors.city}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						noMarginBottom
						label={t("industry", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="branche"
						value={values.branche}
						valid={values.branche && !errors.branche}
						error={
							errors.branche && touched.branche && errors.branche
						}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						noMarginBottom
						label={t("website", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="website"
						optional
						value={values.website}
						valid={values.website && !errors.website}
						error={
							errors.website && touched.website && errors.website
						}
						{...{ handleChange, handleBlur }}
					/>
					<Input
						noMarginBottom
						multiline
						label={t("bio", {
							ns: "forms",
							keyPrefix: "company-form",
						})}
						name="bio"
						optional
						value={values.bio}
						valid={values.bio && !errors.bio}
						error={errors.bio && touched.bio && errors.bio}
						{...{ handleChange, handleBlur }}
					/>
					{!disableButton && (
						<div className="buttons">
							{prevButton}
							<Button
								disabled={!isValid || isSubmitting}
								submit
								loading={isSubmitting}
								className={
									buttonLabel === t("save", { ns: "buttons" })
										? "gtm-werkgever-dashboard-profiel-bedrijfsprofiel--button-opslaan"
										: "gtm-aanmelden-werkgever-2--button-volgende-stap"
								}
							>
								{buttonLabel ||
									t("next-step", { ns: "buttons" })}
							</Button>
						</div>
					)}
				</form>
			)}
		</Formik>
	);
};

export default CompanyForm;
