import { useEffect, useState } from "react";
import { ApiSubscriptionPlan } from "src/api/types";
import SubscriptionGrid from "./SubscriptionGrid";
import SubscriptionPlan from "./SubscriptionPlan";
import SubscriptionPlanCustom from "./SubscriptionPlanCustom";
import SubscriptionPlanSelected from "./SubscriptionPlanSelected";
import SubscriptionIntervalSwitch from "src/components/subscription/SubscriptionIntervalSwitch";
import usePlans from "src/hooks/api/usePlans";
import useTenant from "src/hooks/api/services/tenants/useTenant";

const SubscriptionPlans = () => {
	const { plans: _plans, actions, status, intervalEnabled } = usePlans();
	const { tenant } = useTenant();
	let plans = _plans;
	// let [{ data: plans }, loading] = useGet("/auth/subscriptions/plans");
	const [selected, setSelected] = useState(() => {
		if (tenant.slug === "getjobsdone") {
			return plans[0];
		}
		return {} as ApiSubscriptionPlan;
	});

	const loading = status !== "idle";
	const isYoungMKB = plans?.some((plan: any) => plan.slug === "jong-mkb");
	if (selected?.slug) {
		plans = plans.filter(
			(p: ApiSubscriptionPlan) => p.slug === selected.slug
		);
	}

	useEffect(() => {
		actions.list().then((res: ApiSubscriptionPlan[]) => {
			if (res.length && tenant.slug === "getjobsdone") {
				setSelected(res[0]);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (tenant.slug === "getjobsdone") {
		return (
			<div className="flex flex-col mt-12">
				<SubscriptionGrid
					columns={plans?.length || 3}
					{...{ selected }}
				>
					{!loading &&
						(plans || []).map(
							(plan: ApiSubscriptionPlan, index: number) => (
								<SubscriptionPlan
									key={plan.slug}
									onSelect={(plan) =>
										setSelected(plan as ApiSubscriptionPlan)
									}
									isRecommended={plan.is_most_common}
									isSelected={selected?.slug === plan.slug}
									{...{ plan }}
								/>
							)
						)}
					{selected?.slug && (
						<SubscriptionPlanSelected
							plan={selected}
							showIntervalSwitch={!isYoungMKB}
							onCancel={() =>
								setSelected({} as ApiSubscriptionPlan)
							}
						/>
					)}
				</SubscriptionGrid>
			</div>
		);
	}

	return (
		<>
			{intervalEnabled && !isYoungMKB ? (
				<div className="mt-8 mb-6">
					<SubscriptionIntervalSwitch
						hideDiscountRate={(plans || []).some((plan: any) =>
							plan.promos.includes("blackfriday")
						)}
					/>
				</div>
			) : (
				<div className="mb-8" />
			)}
			<SubscriptionGrid columns={plans?.length || 3} {...{ selected }}>
				{!loading &&
					(plans || []).map(
						(plan: ApiSubscriptionPlan, index: number) => (
							<SubscriptionPlan
								key={plan.slug}
								onSelect={(plan) =>
									setSelected(plan as ApiSubscriptionPlan)
								}
								isRecommended={plan.is_most_common}
								isSelected={selected?.slug === plan.slug}
								{...{ plan }}
							/>
						)
					)}
				{selected?.slug && (
					<SubscriptionPlanSelected
						plan={selected}
						onCancel={() => setSelected({} as ApiSubscriptionPlan)}
					/>
				)}
			</SubscriptionGrid>
			{!selected?.slug && <SubscriptionPlanCustom />}
		</>
	);
};

export default SubscriptionPlans;
