import { Route } from "react-router-dom";
import SignupStudentView from "src/views/auth/signup/SignupStudentView";
import SignupCompanyView from "src/views/auth/signup/company/SignupCompanyView";
import SignupCompanySearchView from "src/views/auth/signup/company/SignupCompanySearchView";
import SignupCompanyInformationView from "src/views/auth/signup/company/SignupCompanyInformationView";
import SignupCompanyUserView from "src/views/auth/signup/company/SignupCompanyUserView";
import SingupCompanyInviteView from "src/views/auth/signup/company/invite/SingupCompanyInviteView";
import SignupView from "src/views/auth/signup/SignupView";
import ReferralJoinView from "src/views/auth/ReferralJoinView";
import SignupCompanyAIView from "src/views/auth/signup/company/experiments/ai/SignupCompanyAIView";

const SignupRoutes = [
	<Route element={<SignupView />} path="/signup/:session?" key="/signup" />,
	<Route
		element={<SignupCompanyAIView />}
		path="/signup/ai"
		key="/signup/ai"
	/>,
	<Route
		element={<SignupStudentView />}
		path="/signup/professional"
		key="/signup/professional"
	/>,
	<Route
		element={<SignupCompanyView />}
		path="/signup/company"
		key="/signup/company"
	/>,
	<Route
		element={<SignupCompanySearchView />}
		path="/signup/company/:slug"
		key="/signup/company/:slug"
	/>,

	<Route
		element={<SignupCompanyInformationView />}
		path="/signup/company/:slug/information"
		key="/signup/company/:slug/information"
	/>,
	<Route
		element={<SignupCompanyUserView />}
		path="/signup/company/:slug/information/contact"
		key="/signup/company/:slug/information/contact"
	/>,
	<Route
		element={<SingupCompanyInviteView />}
		path="/signup/company/:hid/invite"
		key="/signup/company/:hid/invite"
	/>,
	<Route
		element={<ReferralJoinView />}
		path="/join/:code"
		key="/join/:code"
	/>,
];

export default SignupRoutes;
