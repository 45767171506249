import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiTaskTableRow } from "src/api/types";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import OnlineIndicator from "src/components/OnlineIndicator";
import Skeleton from "src/components/Skeleton";
import UserAvatars from "src/components/UserAvatars";
import Indicator from "src/components/layout/Indicator";
import TaskMatchProfile from "src/components/tasks/TaskMatchProfile";
import TaskMatchProgress from "src/components/tasks/TaskMatchProgress";
import TaskStatus from "src/components/tasks/TaskStatus";
import List from "src/components/ui/list";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import useModules from "src/hooks/selectors/useModules";
import useAlert from "src/hooks/useAlert";
import useWindowSize from "src/hooks/useWindowSize";
import { formatPrice } from "src/lib/formatters";

interface TaskTableRowProps {
	task: ApiTaskTableRow;
	setModalTask?: (hid: string) => void;
	variant?: "sub-task";
	onDelete?: () => Promise<any>;
}

const TaskTableRow = ({
	task,
	setModalTask,
	variant,
	onDelete,
}: TaskTableRowProps) => {
	const permissions = usePermissions();
	const { isTablet } = useWindowSize();
	const [sendAlert] = useAlert();
	const auth = useAuth();
	const { taskType } = useTenant();
	const { t } = useTranslation("tasks", {
		keyPrefix: "task-table-row",
	});
	const [isDeleting, setIsDeleting] = useState(false);
	const { modules } = useModules();
	const [visible, setVisible] = useState(false);
	const start = moment(task.start_date).format("DD-MM-YYYY");
	const end = task.end_date
		? moment(task.end_date).format("DD-MM-YYYY")
		: null;
	const students = task.amount_of_students;
	const hasSubtasks =
		(task.has_subtasks || task.links?.import) &&
		variant !== "sub-task" &&
		permissions.has("tasks.create")
			? true
			: false;
	const match =
		task?.matches && students === 1 ? task.matches.users[0] : null;
	const isParentImport = task?.links?.import && variant !== "sub-task";

	const handleDelete = async () => {
		if (!onDelete) return;
		sendAlert({
			type: "confirm",
			title: t("delete.title"),
			text: t("delete.description"),
			onConfirm: async () => {
				setIsDeleting(true);
				try {
					await onDelete();
					setIsDeleting(false);
				} catch (error) {
					setIsDeleting(false);
				}
			},
		});
	};

	let date = `${start} - ${end}`;
	if (start === end || !end) {
		date = start;
	}
	return (
		<>
			<List.Row
				className={`${variant === "sub-task" && "bg-accent"}`}
				key={`table-task-${task.hid}`}
			>
				<List.Item className="w-40">
					<div className="relative flex w-max">
						{(students > 1 || hasSubtasks) &&
						task.type !== "INTERNAL" &&
						!task?.links?.import ? (
							<div className="w-40">
								{task.type === "DRAFT" && (
									<span className="text-sm opacity-70">
										{t("types.DRAFT")}
									</span>
								)}

								{task.status === "COMPLETED" ? (
									<TaskStatus status={task.status} />
								) : (
									<TaskMatchProgress
										{...{ students }}
										matched={task.matches?.count}
									/>
								)}
							</div>
						) : isParentImport ? (
							<div className="">
								<Badge>Import</Badge>
							</div>
						) : (
							<TaskStatus status={task.status} />
						)}

						{task.unread_messages > 0 && (
							<Indicator
								style={{
									position: "absolute",
									top: -8,
									right: -8,
								}}
							>
								{task.unread_messages}
							</Indicator>
						)}
					</div>
				</List.Item>
				<List.Item>
					<p className="w-[200px] md:w-[260px] line-clamp-2">
						{task.title}
					</p>
				</List.Item>
				{taskType === "extern" && auth.type === "student" && (
					<List.Item className="w-full md:w-auto">
						<p className="line-clamp-1 w-max max-w-[200px] md:w-auto opacity-70">
							{task?.company?.name}
						</p>
					</List.Item>
				)}

				{permissions.has("tasks.create") && (
					<List.Item>
						{task.type !== "INTERNAL" && !isParentImport && (
							<>
								{task.registrations?.users && !match && (
									<>
										{task.registrations?.count > 20 ? (
											<p>
												{t("registrations", {
													count: task.registrations
														?.count,
												})}
											</p>
										) : (
											<UserAvatars
												users={task.registrations.users}
												overlay={
													<div className="absolute inset-0 bg-primary backdrop-blur-sm rounded-md flex justify-center items-center text-white">
														<strong>
															{
																task
																	.registrations
																	?.count
															}
														</strong>
													</div>
												}
											/>
										)}
									</>
								)}
								{task.registrations?.count === 0 && (
									<small>{t("no-registrations")}</small>
								)}
								{match && <TaskMatchProfile user={match} />}
							</>
						)}
					</List.Item>
				)}
				<List.Item>
					{!hasSubtasks && task.type !== "INTERNAL" ? (
						<span className="whitespace-nowrap">
							{formatPrice(
								task.budget_per_hour
									? task.budget_per_hour
									: task.budget
							)}{" "}
							{task.amount_of_students > 1 &&
								!task?.budget_per_hour &&
								"p.p."}
							{task?.budget_per_hour ? t("per-hour") : null}
						</span>
					) : (
						false
					)}
				</List.Item>
				<List.Item>
					{task.type !== "INTERNAL" && (
						<p className="whitespace-nowrap">{date}</p>
					)}
				</List.Item>

				<List.Item
					className="w-[50px]"
					sticky={isTablet ? { position: "right" } : undefined}
				>
					<div className="flex items-center justify-end gap-2 sticky right-0">
						{permissions.has("tasks.create") && (
							<OnlineIndicator
								state={task.is_visible ? "online" : "offline"}
							/>
						)}
						{hasSubtasks ? (
							<>
								{task.type === "DRAFT" ? (
									<Button
										iconOnly
										icon="fas fa-pencil"
										type="gray"
										to={`/tasks/${task?.hid}/publish`}
									/>
								) : (
									<>
										<Button
											iconOnly
											icon={`fas fa-angle-${
												visible ? "up" : "down"
											}`}
											type="gray"
											onClick={() => setVisible(!visible)}
										/>
										{!isParentImport && (
											<>
												<Button
													iconOnly
													icon="fas fa-retweet"
													type="gray"
													to={`/tasks/create?repeat=${task?.hid}`}
												/>

												<Button
													iconOnly
													icon="fas fa-eye"
													to={`/tasks/${task.hid}/show`}
												/>
											</>
										)}
									</>
								)}
							</>
						) : (
							<>
								{task.type === "DRAFT" &&
									setModalTask &&
									permissions.has("tasks.create") && (
										<>
											<Button
												onClick={handleDelete}
												iconOnly
												icon="fas fa-trash"
												type="gray"
												loading={isDeleting}
											/>
											<Button
												onClick={() =>
													setModalTask(task.hid)
												}
												iconOnly
												icon="fas fa-arrow-right"
												type="primary"
											></Button>
										</>
									)}

								{task.type === "INTERNAL" &&
									permissions.has("tasks.create") && (
										<>
											{onDelete && (
												<Button
													onClick={handleDelete}
													iconOnly
													icon="fas fa-trash"
													type="gray"
													loading={isDeleting}
												/>
											)}
											<Button
												iconOnly
												icon="fas fa-pencil"
												type="gray"
												to={
													!modules.includes(
														"intern-task"
													)
														? `/tasks/${task?.hid}/publish`
														: {
																modal: `/tasks/${task?.hid}/create`,
														  }
												}
											/>
										</>
									)}

								{task.type === "PUBLISHED" && (
									<>
										{permissions.has("tasks.create") && (
											<Button
												iconOnly
												icon="fas fa-retweet"
												type="gray"
												to={`/tasks/create?repeat=${task?.hid}`}
											/>
										)}
										<Button
											iconOnly
											icon="fas fa-eye"
											to={`/tasks/${task.hid}/show${
												task?.links?.registration &&
												task.variant !== "HOUR_RATE"
													? `/${task?.links?.registration}`
													: ""
											}`}
										/>
									</>
								)}
							</>
						)}
					</div>
				</List.Item>
			</List.Row>
			{hasSubtasks && visible && (
				<SubtaskRows
					type={task?.links?.import ? "import" : "subtasks"}
					id={task?.links?.import || task.id}
				/>
			)}
		</>
	);
};

interface SubtaskRowsProps {
	id: number | string;
	type?: "subtasks" | "import";
}

const SubtaskRows = ({ id, type = "subtasks" }: SubtaskRowsProps) => {
	const query: any = {
		variant: "table",
		recent: 1,
		...(type === "subtasks" && { sub_parent_id: id }),
		...(type === "import" && { import_id: id }),
	};
	const searchParams = new URLSearchParams(query);
	const queryString = searchParams.toString();
	const [{ data: rows }, loading] = useGet(`/company/tasks?${queryString}`);

	if (loading) {
		return (
			<List.Row>
				<List.Item colSpan={6}>
					<div className="p-6 flex justify-center items-center">
						<span className="base-loader"></span>
					</div>
				</List.Item>
			</List.Row>
		);
	}

	return (
		<>
			{(rows || []).length === 0 && (
				<List.Row>
					<List.Item colSpan={6}>
						<div className="p-6 flex justify-center items-center">
							<strong>Geen shifts gevonden</strong>
						</div>
					</List.Item>
				</List.Row>
			)}
			{(rows || []).map((item: ApiTaskTableRow) => (
				<TaskTableRow
					key={`task-${item.id}`}
					variant="sub-task"
					task={item}
				/>
			))}
		</>
	);
};

const Loading = () => {
	return (
		<List.Row className=" ">
			<List.Item>
				<Skeleton className="h-6 w-full" />
			</List.Item>
			<List.Item>
				<Skeleton className="h-6 w-full" />
			</List.Item>
			<List.Item>
				<UserAvatars.Loading />
			</List.Item>
			<List.Item>
				<Skeleton className="h-6 w-full" />
			</List.Item>
			<List.Item>
				<div className="flex gap-2 max-w-[80%]">
					<Skeleton className="h-6 w-full" />
					<Skeleton className="h-6 w-full" />
				</div>
			</List.Item>
			<List.Item>
				<div className="flex gap-2">
					<Skeleton className="h-10 w-10" />
					<Skeleton className="h-10 w-10" />
				</div>
			</List.Item>
		</List.Row>
	);
};

TaskTableRow.Loading = Loading;
export default TaskTableRow;
