import { useEffect, useState } from "react";

function useIframeEvents() {
	useEffect(() => {
		window.addEventListener("load", sendHeightToIframe);
		window.addEventListener("resize", sendHeightToIframe);
		const observer = new ResizeObserver(sendHeightToIframe);
		sendHeightToIframe();
		return () => {
			window.removeEventListener("load", sendHeightToIframe);
			window.removeEventListener("resize", sendHeightToIframe);
			observer.disconnect();
		};
	}, []);
}

export function usePollIframeHeight() {
	const [contentHeight, setContentHeight] = useState<number>();

	useEffect(() => {
		const isInIframe = window.self !== window.top;
		if (!isInIframe) return;
		const intervalId = setInterval(() => {
			const newHeight = document.body.scrollHeight;
			if (newHeight !== contentHeight) {
				setContentHeight(newHeight);
				sendHeightToIframe();
			}
		}, 500);

		// Clean up the interval when the component unmounts
		return () => {
			clearInterval(intervalId);
		};
	}, [contentHeight]);
}

export function sendHeightToIframe() {
	const height = document.body.scrollHeight;
	const width = document.body.scrollWidth;
	window.parent.postMessage({ height, width }, "*");
}

export default useIframeEvents;
