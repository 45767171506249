import { formatPrice, ucFirst } from "src/lib/formatters";
import { ApiSubscriptionPlan } from "src/api/types";
import { useTranslation } from "react-i18next";
import usePlans from "src/hooks/api/usePlans";

interface SubscriptionPlanPriceProps {
	plan: ApiSubscriptionPlan;
	discount?: {
		price?: number;
	};
}

const SubscriptionPlanPrice = ({
	plan,
	discount,
}: SubscriptionPlanPriceProps) => {
	const { interval } = usePlans();
	const { t } = useTranslation("subscription", {
		keyPrefix: "subscription-plan-price",
	});
	const discount_fee = plan.discount_service_fee;
	const isDiscountedFee = discount_fee !== null && discount_fee !== undefined;
	const isYoungMKB = plan.slug === "jong-mkb";

	if (discount?.price) {
		return (
			<>
				<div className="">
					<strong className="block mb-2">{plan.name}</strong>
					{discount?.price ? (
						<div className="flex text-orange items-end mb-2">
							<h2 className="text-5xl">
								{formatPrice(discount.price)}
							</h2>
							<small>{t("first-month")}</small>
						</div>
					) : (
						false
					)}

					<strong>
						{t("after-discount-price", {
							price: formatPrice(plan.price),
						})}
					</strong>
				</div>
				{plan.service_fee > 0 && (
					<div className="border-t-[1px] border-b-[1px] border-primary-300 py-2 flex justify-between items-center">
						<strong>{t("service-fee")}</strong>
						<span>{plan.service_fee * 100}%</span>
					</div>
				)}
			</>
		);
	}

	if (plan.discount && interval === "MONTHLY") {
		return (
			<div className="">
				<strong className="block mb-2">{plan.name}</strong>
				<div className="flex items-center gap-2">
					<h2 className="text-4xl mb-0">{formatPrice(plan.price)}</h2>
					<h2 className="text-3xl mb-0 opacity-50 line-through">
						{formatPrice(plan.discount.original_price)}
					</h2>
				</div>
				<small className="opacity-50">
					{ucFirst(t("per-month"))} & {t("excl-vat")}
				</small>
			</div>
		);
	}

	if (interval === "YEARLY") {
		return (
			<>
				<div className="">
					<strong className="block mb-2">{plan.name}</strong>
					<div className="flex items-center gap-2">
						<h2 className="text-4xl mb-0">
							{formatPrice(plan.price_per_year)}
						</h2>
						{plan.price > 0 &&
							plan.price !== plan.price_per_year / 12 && (
								<h2 className="text-3xl mb-0 opacity-50 line-through">
									{formatPrice(plan.price * 12)}
								</h2>
							)}
					</div>
					<small className="opacity-50">{t("excl-vat")}</small>
					{/* {discount_fee !== null && discount_fee !== undefined && (
					<span className="block ml-4 text-primary font-bold">
						<strong>+ {Number(discount_fee) * 100}%</strong>{" "}
						{t("service-fee")} {plan.fee_text}
					</span>
				)} */}
				</div>
				{plan.service_fee > 0 && (
					<div className="border-t-[1px] border-b-[1px] border-primary-300 py-2 flex justify-between items-center">
						<div className="flex flex-col">
							<strong>{t("service-fee")}</strong>
							{plan?.fee_text && isDiscountedFee && (
								<small>{plan.fee_text}</small>
							)}
						</div>

						{plan.service_fee_per_year < plan.service_fee ? (
							<div className="flex gap-2 items-center">
								<span className="line-through">
									{plan.service_fee * 100}%
								</span>
								<strong className="text-orange">
									{Number(plan.service_fee_per_year) * 100}%
								</strong>
							</div>
						) : (
							<div className="flex gap-2 items-center">
								<strong>
									{Number(plan.service_fee_per_year) * 100}%
								</strong>
							</div>
						)}
					</div>
				)}
			</>
		);
	}

	return (
		<>
			<div className="">
				<strong className="block mb-2">{plan.name}</strong>
				<div className={`flex items-end gap-1`}>
					<h2 className="text-4xl">{formatPrice(plan.price)}</h2>
					{isYoungMKB ? (
						<small>{t("first-x-months", { months: 3 })}</small>
					) : (
						<small>{t("per-month")}</small>
					)}
				</div>

				{discount_fee !== null && discount_fee !== undefined && (
					<span className="block ml-4 text-primary font-bold">
						<strong>+ {Number(discount_fee) * 100}%</strong>{" "}
						{t("service-fee")} {plan.fee_text}
					</span>
				)}
				<small className="opacity-50">{t("excl-vat")}</small>

				{isYoungMKB && (
					<div className="flex opacity-50 italic items-end gap-1 mt-2">
						<span>
							{t("after", {
								price: formatPrice(79.95),
							})}
						</span>
					</div>
				)}
			</div>
			{plan.service_fee > 0 && (
				<div className="border-t-[1px] border-b-[1px] border-primary-300 py-2 flex justify-between items-center">
					<div className="flex flex-col">
						<strong>{t("service-fee")}</strong>
						{plan?.fee_text && isDiscountedFee && (
							<small> {plan.fee_text}</small>
						)}
					</div>

					{isDiscountedFee ? (
						<div className="flex gap-2 items-center">
							<span className="line-through">
								{plan.service_fee * 100}%
							</span>
							<strong className="text-orange">
								{Number(discount_fee) * 100}%
							</strong>
						</div>
					) : (
						<span>{plan.service_fee * 100}%</span>
					)}
				</div>
			)}
		</>
	);
};

export default SubscriptionPlanPrice;
