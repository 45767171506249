import { useMemo } from "react";
import { ApiPaymentRequest } from "src/api/types";
import Badge from "src/components/Badge";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";

export const PAYMENT_REQUEST_STATUSES: ApiPaymentRequest["status"][] = [
	"PENDING",
	"OPEN",
	"DENIED",
	"APPROVED",
	"COMPLETED",
];

interface PaymentRequestStatusProps extends Translation {
	status: ApiPaymentRequest["status"];
}

const PaymentRequestStatus = ({ t, status }: PaymentRequestStatusProps) => {
	const variant = useMemo(() => {
		if (status === "COMPLETED") return "green";
		if (["APPROVED", "FINALIZING"].includes(status)) return "blue";
		if (status === "DENIED") return "red";
		return "gray";
	}, [status]);

	const tooltip = useMemo(() => {
		if (status === "OPEN") return { text: t("PENDING.help") };
		if (status === "DENIED") return { text: t("DENIED.help") };
		return undefined;
	}, [status, t]);

	return <Badge {...{ variant, tooltip }}>{t(`${status}.label`)}</Badge>;
};

PaymentRequestStatus.locale = {
	nl: {
		OPEN: {
			label: "Open",
			help: "Je kan dit verzoek nog wijzigen",
		},
		PENDING: {
			label: "In afwachting",
			help: "In afwachting van goedkeuring of afwijzing",
		},
		APPROVED: {
			label: "Goedgekeurd",
		},
		DENIED: {
			label: "Afgewezen",
			help: "Betaling is afgewezen door de opdrachtgever",
		},
		FINALIZING: {
			label: "Afronden",
		},
		COMPLETED: {
			label: "Afgerond",
		},
	},
	en: {
		OPEN: {
			label: "Open",
			help: "You can still edit this request",
		},
		PENDING: {
			label: "Pending",
			help: "Pending approval or rejection",
		},
		APPROVED: {
			label: "Approved",
		},
		DENIED: {
			label: "Denied",
			help: "Payment has been rejected by the client",
		},
		FINALIZING: {
			label: "Finalizing",
		},
		COMPLETED: {
			label: "Completed",
		},
	},
};

export default withTranslation(PaymentRequestStatus);
