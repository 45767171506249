import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import useAuth from "src/hooks/selectors/useAuth";
import useEditProfile from "src/hooks/api/useEditProfile";
import { useTranslation } from "react-i18next";

export default function DashboardEditThemeModal() {
	const { t } = useTranslation("resume-view", {
		keyPrefix: "theme-modal",
	});
	const navigate = useNavigate();
	const auth = useAuth();
	const [color, setColor] = useState(auth.primary_color);
	const [patchProfile, loading] = useEditProfile();
	const handleSave = () => {
		if (color !== auth.primary_color) {
			patchProfile({
				primary_color: color,
			}).then(() => {
				navigate("/dashboard");
			});
		}
	};

	return (
		<Modal
			title={t("title")}
			visible
			onClose={() => navigate("/dashboard")}
			className="md:max-w-[300px]"
		>
			<HexColorPicker color={color} onChange={setColor} />
			<Modal.Buttons>
				<Button
					disabled={color === auth.primary_color}
					{...{ loading }}
					type="primary"
					className="gtm-student-dashboard-mijn-cv--button-kleur-aanpassen flex-1"
					onClick={handleSave}
				>
					{t("button")}
				</Button>
			</Modal.Buttons>
		</Modal>
	);
}