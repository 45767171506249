import React from "react";
import { ApiSubscriptionPlan } from "src/api/types";
import useGet from "src/hooks/rest/useGet";
import MinimalSubscriptionPlan, {
	MinimalSubscriptionPlanLoader,
} from "./MinimalSubscriptionPlan";

const MinimalSubscriptionPlans = () => {
	const [{ data: plans }, loading] = useGet("/auth/subscriptions/plans");
	if (loading) {
		return (
			<div className="flex flex-col md:flex-row gap-8">
				<MinimalSubscriptionPlanLoader />
				<MinimalSubscriptionPlanLoader />
				<MinimalSubscriptionPlanLoader />
			</div>
		);
	}

	return (
		<div className="flex flex-col md:flex-row gap-8">
			{(plans || []).map((plan: ApiSubscriptionPlan) => (
				<MinimalSubscriptionPlan key={plan.slug} {...{ plan }} />
			))}
		</div>
	);
};

export default MinimalSubscriptionPlans;
