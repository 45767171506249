import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Layout from "src/components/layout/Layout";
import Link from "src/components/Link";
import SubscriptionCheckoutHead from "src/components/subscription/SubscriptionCheckoutHead";
import SubscriptionPlans from "src/components/subscription/SubscriptionPlans";
import usePlans from "src/hooks/api/usePlans";
import useSubscription from "src/hooks/selectors/useSubscription";
import { useParseBackUrl } from "src/hooks/useBackUrl";
import useQueryString from "src/hooks/useQueryString";

export default function SubscriptionView() {
	const { t } = useTranslation("subscription-view", {
		keyPrefix: "index-view",
	});
	const subscription = useSubscription();
	const qs = useQueryString();
	const parseBackUrl = useParseBackUrl();
	const { actions } = usePlans();

	useEffect(() => {
		if (qs.interval) {
			actions.setInterval(qs.interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (subscription && !subscription?.is_paused) {
		return (
			<Layout hideNav>
				<div className="flex flex-col justify-center items-center flex-1">
					<h2 className="mb-8">{t("already.title")}</h2>
					<Button to="/dashboard">
						{t("already.button")}
						<i className="fas fa-arrow-right ml-4"></i>
					</Button>
				</div>
			</Layout>
		);
	}

	return (
		<Layout hideNav>
			<div className="flex flex-col flex-1">
				<div className="mb-4 md:mb-0 block">
					<Link
						className="underline"
						to={parseBackUrl || "/dashboard"}
					>
						{t("back")}
					</Link>
				</div>
				<SubscriptionCheckoutHead
					title={t("head.title")}
					subtitle={t("head.subtitle")}
				/>
				<SubscriptionPlans />
			</div>
		</Layout>
	);
}
