import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader";
import ResumeCard from "src/components/ResumeCompanyUser/resume-card/ResumeCard";
import ResumeHeader from "src/components/resume/ResumeHeader";
import useResume from "src/hooks/api/services/resume/useResume";
import useSubscription from "src/hooks/selectors/useSubscription";
import { cn } from "src/lib/utils";

interface ResumeProps {
	id: string;
	exclude: string[];
	variant: "default" | "registration";
}

const Resume = ({ id, exclude, variant }: ResumeProps) => {
	const subscription = useSubscription();
	const { t } = useTranslation("resume", {
		keyPrefix: "resume",
	});
	const { resume, actions, status } = useResume({
		rememberKey: id,
	});
	const modules: string[] = resume?.resume_modules || [];
	const checkModule = (name: string) => {
		return modules.includes(name) && !exclude.includes(name);
	};

	useEffect(() => {
		actions.get(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!resume?.id && status !== "idle") {
		return (
			<div className="flex flex-col flex-1 justify-center items-center">
				<Loader />
			</div>
		);
	}

	if (!resume?.id) {
		return (
			<div className="flex flex-col flex-1 justify-center items-center">
				<strong className="text-background-foreground">
					{t("empty.title")}
				</strong>
			</div>
		);
	}

	if (variant === "registration") {
		return (
			<ResumeCard
				{...{ resume }}
				options={{
					title: {
						variant: "uppercase",
					},
				}}
			>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					{(checkModule("bio") || checkModule("video-pitch")) && (
						<div
							className={cn(
								"md:col-span-2"
								// !checkModule("bio") &&
								// 	checkModule("video-pitch") &&
								// 	"md:col-span-1"
							)}
						>
							<ResumeCard.Bio />
						</div>
					)}
					{checkModule("experiences.education") && (
						<ResumeCard.Education variant="timeline" />
					)}
					{checkModule("experiences.job") && (
						<ResumeCard.Work variant="timeline" />
					)}
					{checkModule("social-media") && subscription?.id && (
						<ResumeCard.SocialMedia />
					)}
					{checkModule("reviews") && (
						<div className="md:col-span-2">
							<ResumeCard.Reviews />
						</div>
					)}
					<div className="md:col-span-2">
						<ResumeCard.Badge />
					</div>
				</div>
			</ResumeCard>
		);
	}

	return (
		<ResumeCard
			{...{ resume }}
			options={{
				title: {
					variant: "uppercase",
				},
			}}
		>
			<div className="flex flex-col gap-8">
				{!exclude?.includes("header") && <ResumeCard.Head />}

				{(checkModule("bio") || checkModule("video-pitch")) && (
					<ResumeCard.Bio />
				)}
				{checkModule("experiences.education") && (
					<ResumeCard.Education variant="timeline" />
				)}
				{checkModule("experiences.job") && (
					<ResumeCard.Work variant="timeline" />
				)}
				{checkModule("social-media") && <ResumeCard.SocialMedia />}
				{checkModule("reviews") && <ResumeCard.Reviews />}
				<ResumeCard.Badge />
			</div>
		</ResumeCard>
	);
};

Resume.defaultProps = {
	exclude: [],
	variant: "default",
};

Resume.Header = ResumeHeader;

export default Resume;
