import React from "react";

interface SubscriptionCheckoutHeadProps {
	title: string;
	subtitle: any;
}

const SubscriptionCheckoutHead = ({
	title,
	subtitle,
}: SubscriptionCheckoutHeadProps) => {
	return (
		<div className="flex flex-col justify-center items-center max-w-md mx-auto">
			<h1 className="mb-2 text-3xl md:text-4xl text-center">{title}</h1>
			<p className="text-center">{subtitle}</p>
		</div>
	);
};

export default SubscriptionCheckoutHead;
