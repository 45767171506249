import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ProjectFormTitle from "./ProjectFormTitle";
import ProjectForm from "./ProjectForm";
import useProjects from "src/hooks/api/services/projects/useProjects";
import moment from "moment";
import { useEffect } from "react";

interface CreateProjectModalProps extends Translation {
	defaultVisible: boolean;
	backUrl: string;
}

function CreateProjectModal({
	t,
	defaultVisible,
	backUrl,
}: CreateProjectModalProps) {
	const {
		formState,
		setFormState,
		clearFormState,
		actions,
		status,
		getRandomIcon,
	} = useProjects();
	const visible = defaultVisible;
	const navigate = useNavigate();
	const iconButtonClassName = "w-[40px] h-[40px] text-[20px]";

	useEffect(() => {
		const icon = getRandomIcon();
		setFormState({
			icon: icon,
			name: "",
			start_date: moment().format("YYYY-MM-DD").toString(),
			end_date: "",
			members: [],
			skills: [],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClose = () => {
		navigate(backUrl);
		clearFormState();
	};

	const onSubmit = () => {
		if (status !== "idle") return;
		actions.create(formState).then(() => {
			actions.list({
				page: 1,
			});
			onClose();
		});
	};

	const errorDate =
		formState.start_date !== "" &&
		formState.end_date !== "" &&
		(moment(formState.start_date).isAfter(moment(formState.end_date)) ||
			moment(formState.end_date).isBefore(moment(formState.start_date)));

	const errorCheck =
		formState.name.trim() === "" ||
		formState.start_date === "" ||
		formState.members.length === 0 ||
		errorDate
			? true
			: false;

	return (
		<Modal
			head="disabled"
			size="default"
			className="max-w-[540px] w-full"
			bodyClassName="p-6 w-full"
			{...{ onClose, visible }}
		>
			<div className="flex justify-between items-center mt-2">
				<ProjectFormTitle />
				<Button
					tabIndex="-1"
					type="transparent"
					iconOnly
					className={iconButtonClassName}
					onClick={onClose}
					small
				>
					<i className="fal fa-times text-placeholder text-2xl"></i>
				</Button>
			</div>
			<div className="my-6">
				<ProjectForm />
				{errorDate && (
					<div className="input_has-error">{t("error_date")}</div>
				)}
			</div>
			<Button
				disabled={errorCheck}
				loading={status !== "idle"}
				type="dark"
				className="w-full mb-2"
				onClick={onSubmit}
			>
				{t("create")}
			</Button>
		</Modal>
	);
}

CreateProjectModal.locale = {
	nl: {
		create: "Project aanmaken",
		error_date: "Start datum kan niet voor eind datum of andersom",
	},
	en: {
		create: "Create project",
		error_date: "Start date cannot be before end date or vice versa",
	},
};

export default withTranslation(CreateProjectModal);
