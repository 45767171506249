const onboarding = {
	student: {
		title: "Maak je profiel compleet ({{current}} / {{total}})",
		information: {
			title: "Vul je basisgegevens in",
			description:
				"Deze gegevens hebben we nodig om later de overeenkomsten voor de opdrachten op te maken. We hebben je woonadres, geboortedatum & telefoonnummer nodig.",
		},
		interests: {
			title: "Geef je interesses aan",
			description:
				"Doormiddel van deze interesses kunnen wij makkelijker jobs aan je aanbieden",
		},
		avatar: {
			title: "Stel een profielfoto in",
			description:
				"92% van de bedrijven kiest eerder een professional met een profielfoto",
		},
		experiences: {
			title: "Opleiding & werkervaring",
			description:
				"Doormiddel van deze info kan de opdrachtgever je ervaring zien",
		},
		skills: {
			title: "Bio, vaardigheden & verbind je socials",
			description:
				"Vertel wat over jezelf, wat je graag doet, wat je doelen zijn en waar je echt in uitblinkt",
		},

		compliance: {
			contact: "Contactgegevens",
			bank_account: "Bankrekening",
			pending: "In afwachting",
			cta: "Bekijk account status",
		},
	},
};

export default onboarding;
