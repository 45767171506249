import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ApiSubscription } from "src/api/types";
import useGet from "src/hooks/rest/useGet";
import { formatPrice } from "src/lib/formatters";
import Badge from "src/components/Badge";
import Card from "src/components/Card";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface CurrentSubscriptionProps {
	subscription: ApiSubscription;
}

const CurrentSubscription = ({ subscription }: CurrentSubscriptionProps) => {
	const {
		tenant: { modules },
	} = useTenant();
	const { t } = useTranslation("subscription", {
		keyPrefix: "current-subscription",
	});
	const [{ data }] = useGet("/auth/subscriptions/plans/current");
	return (
		<Card
			contentClassName="p-6 flex flex-col gap-4"
			className="md:col-span-2"
		>
			<div className="flex flex-row items-center">
				<Badge>{subscription?.name}</Badge>
				<p className="mb-0 font-bold ml-2">{t("current")}</p>
			</div>
			<div className="flex gap-2 items-end">
				<h2 className="text-4xl">{formatPrice(subscription.price)}</h2>
				<small>
					{subscription?.interval === "YEARLY"
						? t("per-year")
						: t("per-month")}
				</small>
			</div>
			{data?.upcoming?.fees > 0 &&
				!modules.includes("fee-in-payment") && (
					<>
						<h2 className="text-2xl mb-0">
							{t("service-fee.title", {
								price: formatPrice(data?.upcoming?.fees),
							})}
						</h2>
						<NavLink
							className="underline"
							to="/profile/billing/upcoming"
						>
							{t("service-fee.button")}
						</NavLink>
					</>
				)}
		</Card>
	);
};

export default CurrentSubscription;
