import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Input from "src/components/form/Input";
import Button from "src/components/Button";
import { ApiExperience } from "src/api/types";
import usePost from "src/hooks/rest/usePost";
import usePatch from "src/hooks/rest/usePatch";
import Modal from "src/components/Modal";
import Loader from "src/components/Loader";
import axios from "axios";
import useDelete from "src/hooks/rest/useDelete";
import { useTranslation } from "react-i18next";
import useResumeExperienceScheme from "src/hooks/schemes/useResumeExperienceScheme";

const DashboardEditExperienceModal = () => {
	const scheme = useResumeExperienceScheme();
	const { t } = useTranslation("resume-view", {
		keyPrefix: "edit-experience-modal",
	});
	const navigate = useNavigate();
	const { type, id } = useParams() as any;
	const [experience, setExperience] = useState({} as ApiExperience);
	const [addExperience, loadingAdd] = usePost("/student/experiences");
	const detailRoute = `/student/experiences/${id}`;
	const [updateExperience, loadingUpdate] = usePatch(detailRoute);
	const [deleteExperience, loadingDelete] = useDelete(detailRoute);
	const loading = loadingAdd || loadingUpdate;

	useEffect(() => {
		if (id && !experience.id) {
			axios.get(`/student/experiences/${id}`).then(({ data }) => {
				setExperience(data.data);
			});
		}
	}, [id, experience]);

	const handleDelete = () => {
		deleteExperience().then(() =>
			navigate("/dashboard/resume/experiences")
		);
	};

	const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
		if (experience.id) {
			updateExperience({ id: experience.id, ...values, type })
				.then(() => navigate("/dashboard/resume/experiences"))
				.finally(() => {
					setSubmitting(false);
				});
		} else {
			addExperience({ ...values, type })
				.then(() => navigate("/dashboard/resume/experiences"))
				.finally(() => {
					setSubmitting(false);
				});
		}
	};

	return (
		<Modal
			visible
			onClose={() => {
				navigate("/dashboard/resume/experiences");
			}}
			title={!id ? t(`${type}.title`) : t(`${type}.title-edit`)}
		>
			{!experience?.id && id ? (
				<div className="p-6 flex justify-center pb-12">
					<Loader />
				</div>
			) : (
				<Formik
					validateOnMount
					initialValues={{
						subtitle: experience.subtitle,
						title: experience.title,
						started: experience.started,
						ended: experience.ended,
					}}
					validationSchema={Yup.object().shape(scheme)}
					onSubmit={handleSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						isValid,
					}) => (
						<form onSubmit={handleSubmit}>
							<Input
								label={t(`${type}.form.title`)}
								name="title"
								value={values.title}
								valid={values.title && !errors.title}
								error={
									errors.title &&
									touched.title &&
									errors.title
								}
								autoFill={
									type === "education"
										? "education"
										: undefined
								}
								{...{ handleChange, handleBlur }}
							/>
							<Input
								label={t(`${type}.form.subtitle`)}
								name="subtitle"
								value={values.subtitle}
								valid={values.subtitle && !errors.subtitle}
								autoFill={
									type === "education" ? "school" : undefined
								}
								error={
									errors.subtitle &&
									touched.subtitle &&
									errors.subtitle
								}
								{...{ handleChange, handleBlur }}
							/>
							<Input
								label={t("form.started")}
								name="started"
								type="number"
								value={values.started}
								valid={values.started && !errors.started}
								error={
									errors.started &&
									touched.started &&
									errors.started
								}
								{...{ handleChange, handleBlur }}
							/>
							<Input
								label={t("form.ended")}
								name="ended"
								value={values.ended}
								valid={values.ended && !errors.ended}
								error={
									errors.ended &&
									touched.ended &&
									errors.ended
								}
								{...{ handleChange, handleBlur }}
							/>
							<Modal.Buttons>
								{id ? (
									<Button
										onClick={handleDelete}
										loading={loadingDelete}
										type="red"
										className="text-white"
									>
										{t("buttons.delete")}
									</Button>
								) : (
									<Button
										type="gray"
										to="/dashboard/resume/experiences"
									>
										{t("buttons.cancel")}
									</Button>
								)}
								<Button
									disabled={
										!isValid || loading || loadingDelete
									}
									submit
									className={`gtm-student-dashboard-mijn-cv--button-${type}-toevoegen`}
									loading={isSubmitting}
								>
									{id ? t("buttons.edit") : t("buttons.add")}
								</Button>
							</Modal.Buttons>
						</form>
					)}
				</Formik>
			)}
		</Modal>
	);
};

export default DashboardEditExperienceModal;
