import React from "react";
import { ApiSubscriptionPlan } from "src/api/types";
import useSubscription from "src/hooks/selectors/useSubscription";
import { formatPrice } from "src/lib/formatters";
import ReactTooltip from "react-tooltip";
import usePatch from "src/hooks/rest/usePatch";
import useFetchProfile from "src/hooks/api/useFetchProfile";
import Skeleton from "src/components/Skeleton";
import useAlert from "src/hooks/useAlert";
import useModules from "src/hooks/selectors/useModules";
import SettingCard from "src/components/cards/SettingCard";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";

interface MinimalSubscriptionPlanProps {
	plan: ApiSubscriptionPlan;
}

const MinimalSubscriptionPlan = ({ plan }: MinimalSubscriptionPlanProps) => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "minimal-subscription-plan",
	});
	const subscription = useSubscription();
	const [sendAlert] = useAlert();
	const isSelected = subscription.plan?.id === plan?.id;
	const canUpgrade = plan.statuses.can_upgrade;
	const [changeSubscription, loading] = usePatch(
		`/auth/subscriptions/plans/${plan.id}/subscribe`
	);
	const { fetchModules } = useModules();
	const { fetchProfile, loading: fetching } = useFetchProfile();
	const isLoading = fetching || loading;

	const handleUpgrade = () => {
		if (!plan.statuses.can_upgrade || isSelected) return;
		sendAlert({
			type: "confirm",
			title: t("confirm.title"),
			text: t("confirm.text", {
				name: plan.name,
			}),
			onConfirm: () => {
				changeSubscription({}).then(() => {
					fetchProfile();
					fetchModules();
				});
			},
		});
	};

	return (
		<SettingCard
			active={isSelected}
			{...{ isLoading }}
			data-tip
			data-for={`tooltip-subscription-${plan.slug}`}
		>
			{!canUpgrade && !isSelected && (
				<ReactTooltip
					id={`tooltip-subscription-${plan.slug}`}
					effect="solid"
				>
					<div className="max-w-[200px] text-center font-bold">
						{t("cant-change")}
					</div>
				</ReactTooltip>
			)}
			<div className="flex text-md items-center gap-4 justify-between">
				<strong>{plan.name}</strong>

				<p>
					<strong>{formatPrice(plan.price)}</strong>
					<small className="opacity-50">{t("per-month")}</small>
				</p>
			</div>
			<div className="flex-1"></div>
			<div>
				<Button
					small
					type={isSelected ? "primary" : "gray"}
					disabled={
						!plan.statuses.can_upgrade ||
						plan.id === subscription?.next_plan?.id ||
						plan.price === 0
					}
					loading={isLoading}
					onClick={handleUpgrade}
				>
					{isSelected ? t("button.current") : t("button.upgrade")}
				</Button>
			</div>
		</SettingCard>
	);
};

export const MinimalSubscriptionPlanLoader = () => (
	<Skeleton className="min-h-[160px] bg-background w-full block" />
);

export default MinimalSubscriptionPlan;
