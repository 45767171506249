import React, { useCallback, useEffect, useState } from "react";
import "./checkout.scss";
import Card from "src/components/Card";
import Button from "src/components/Button";
import axios from "src/lib/api";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import { useTranslation } from "react-i18next";

type CheckoutProps = {
	price: number;
	btw?: number;
	onCancel: () => void;
	task_id?: number;
	return_url?: string;
};

const Checkout = ({ onCancel, price, task_id }: CheckoutProps) => {
	const { t } = useTranslation("credit-view", {
		keyPrefix: "checkout",
	});
	const auth = useAuth();
	const permissions = auth?.company?.permissions || [];
	const [failed, setFailed] = useState(false);
	const [type] = useState("ideal");
	const [pending, setPending] = useState(undefined) as any;
	const [sendAlert] = useAlert();

	const fetchPayment = useCallback(
		async (t?: string) => {
			setPending(t);
			axios
				.post("/credit", {
					type: t || type,
					price,
					task_id,
				})
				.then(({ data }: any) => {
					const result = data?.data || {};

					if (result?.redirect_url)
						window.location = result.redirect_url;
				})
				.catch(() => {
					setPending(undefined);
					setFailed(true);
				});
		},
		[price, setPending, task_id, type]
	);

	useEffect(() => {
		if (!permissions.includes("pay_with_invoice")) {
			fetchPayment();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [price, fetchPayment]);

	if (failed) {
		return (
			<Card className="checkout checkout-error">
				<strong>{t("failed.title")}</strong>
				<Button onClick={onCancel} type="dark">
					{t("failed.button")}
				</Button>
			</Card>
		);
	}

	if (permissions.includes("pay_with_invoice")) {
		return (
			<Card className="checkout checkout-center">
				<strong>{t("title")}</strong>

				<div className="buttons-stacked">
					<Button
						onClick={() =>
							sendAlert({
								type: "confirm",
								title: "Weet je het zeker?",
								onConfirm: () => fetchPayment("ideal"),
							})
						}
						type="green"
						loading={pending === "ideal"}
					>
						<i className="fab fa-ideal"></i>
						{t("ideal")}
					</Button>
					<Button
						onClick={() =>
							sendAlert({
								type: "confirm",
								title: "Weet je het zeker?",
								onConfirm: () => fetchPayment("invoice"),
							})
						}
						loading={pending === "invoice"}
					>
						<i className="far fa-file"></i>
						{t("invoice")}
					</Button>
				</div>
			</Card>
		);
	}

	return (
		<Card className="checkout checkout-center">
			<span className="base-loader"></span>
		</Card>
	);
};

export default Checkout;
