import { AxiosInstance } from "axios";
import useApi, { ApiStatus, ApiActions } from "src/hooks/api/utils/useApi";
import { ApiTenant, ApiTenantSettings } from "src/api/types";
import { getCurrentDomainName } from "src/lib/domain";
import { useMemo } from "react";
// import { useNavigate } from "react-router-dom";
// TODO: Enable use navigate

interface TenantApiActions extends ApiActions {
	current: () => Promise<any>;
	linkCurrent: () => Promise<any>;
}

type State = {
	tenant: ApiTenant;
	settings: ApiTenantSettings;
	status: ApiStatus;
	taskType: "intern" | "extern" | "both" | undefined;
	actions: TenantApiActions;
	api: AxiosInstance;
};

function useTenant(): State {
	// const navigate = useNavigate();
	const { state, actions, api } = useApi(
		{ id: "TENANT" },
		{
			baseUrl: "/tenant",
		}
	);
	const taskType = useMemo(() => {
		if (!state?.data || !state?.data?.modules?.length) return undefined;
		if (
			state?.data.modules.includes("intern-task") &&
			!state?.data.modules.includes("extern-task")
		) {
			return "intern";
		}
		if (
			state?.data.modules.includes("extern-task") &&
			!state?.data.modules.includes("intern-task")
		) {
			return "extern";
		}

		if (
			state?.data.modules.includes("extern-task") &&
			state?.data.modules.includes("intern-task")
		) {
			return "both";
		}

		return undefined;
	}, [state?.data]);

	const getCurrent = async () => {
		const host = getCurrentDomainName();
		try {
			return await actions.get("current", {
				host,
			});
		} catch (error: any) {
			console.log("Error ->", error);
			actions.set({ status: "error" });
		}
	};

	const linkCurrent = async () => {
		actions.set((state: any) => ({
			...state,
			status: "linking",
		}));
		try {
			const tenant = await api
				.post("current/link", {})
				.then(({ data }) => data?.data);
			actions.set((state: any) => ({
				...state,
				status: "idle",
				data: tenant,
			}));
			if (tenant?.has_access) {
				// navigate("/dashboard");
			}
			return tenant;
		} catch (error: any) {
			console.log("Error ->", error);
			actions.set({ status: "error" });
		}
	};

	return {
		tenant: state?.data || {},
		settings: state?.data?.settings || {},
		status: state.status,
		taskType,
		actions: {
			...actions,
			current: getCurrent,
			linkCurrent,
		},
		api,
	};
}

export default useTenant;
