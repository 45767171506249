import React from "react";
import LoaderComp from "src/components/Loader";
import Skeleton from "src/components/Skeleton";
import Icon from "src/components/icon/Icon";
import { cn } from "src/lib/utils";

interface ChooseProps {
	children?: any;
	className?: string;
}

const Choose = ({ children, className }: ChooseProps) => {
	return (
		<div className={cn("flex flex-col gap-2", className)}>{children}</div>
	);
};

interface ChooseItemProps {
	icon?: string;
	title: string;
	description: string;
	onClick?: () => void;
	active?: boolean;
	loading?: boolean;
}

const ChooseItem = ({
	icon,
	title,
	description,
	onClick,
	active,
	loading,
}: ChooseItemProps) => {
	return (
		<div
			{...{ onClick }}
			className={cn(
				"flex gap-3 p-3 border rounded-md border-border cursor-pointer group relative transition-all",
				active && "border-primary",
				icon &&
					"flex-col justify-center items-center md:flex-row md:justify-start md:items-center"
			)}
		>
			{icon && (
				<Icon
					iconFontSize={16}
					className="bg-primary-200 text-primary w-11 h-11 hover:bg-primary-200 group-hover:bg-primary-200 transition-all"
					icon={{
						font_awesome: {
							name: icon,
						},
					}}
				/>
			)}
			<div
				className={cn(
					"flex flex-col gap-2",
					icon && "text-center md:text-left"
				)}
			>
				<strong className="leading-full">{title}</strong>
				<div
					className={cn(
						"w-4 h-4 rounded-full border absolute right-4 top-4 border-border transition-all text-primary-foreground flex justify-center items-center",
						active && "border-primary bg-primary"
					)}
				>
					{loading ? (
						<LoaderComp />
					) : (
						<i
							className={cn(
								"fas fa-check text-[9px] transition-all",
								!active && "opacity-0"
							)}
						></i>
					)}
				</div>
				<p className="text-sm">{description}</p>
			</div>
		</div>
	);
};

Choose.Item = ChooseItem;

const Loader = () => (
	<div
		className={cn(
			"flex gap-2 p-3 border rounded-md border-border cursor-pointer group relative transition-all flex-col"
		)}
	>
		<Skeleton className="h-4 w-1/4 bg-accent" />
		<Skeleton className="h-4 w-full bg-accent" />
	</div>
);

Choose.Skeleton = Loader;

export default Choose;
