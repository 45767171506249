import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import ResumeAvatar from "./ResumeAvatar";
import DateOfBirthPicker from "src/components/form/DateOfBirthPicker";
import Input from "src/components/form/Input";
import Button from "src/components/Button";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import useResume from "src/hooks/api/services/resume/useResume";
import { useEffect } from "react";
import useAuth from "src/hooks/selectors/useAuth";
import Loader from "src/components/Loader";
import { useNavigate } from "react-router-dom";

function GeneralInfoForm({ t }: Translation) {
	const auth = useAuth();
	const { resume, actions, status } = useResume({
		rememberKey: auth.id,
	});
	const navigate = useNavigate();

	useEffect(() => {
		actions.get(auth.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const scheme = {
		date_of_birth: Yup.string()
			.test(
				"minimum_dob",
				t("min-age"),
				(value) => moment().diff(moment(value), "years") >= 16
			)
			.required(t("required")),
		bio: Yup.string().required(t("required")),
	} as {
		[key: string]: any;
	};

	const initialValues = {
		date_of_birth: resume?.date_of_birth || "",
		bio: resume?.bio?.bio || "",
	};

	return (
		<>
			{status === "loading" ? (
				<div className="flex mx-auto my-auto">
					<Loader />
				</div>
			) : (
				<Formik
					{...{ initialValues }}
					validateOnChange
					validateOnBlur
					validateOnMount
					enableReinitialize
					validationSchema={Yup.object().shape(scheme)}
					onSubmit={(values: any, { setSubmitting }) => {
						if (values.date_of_birth) {
							values.date_of_birth = moment(
								values.date_of_birth
							).format("YYYY-MM-DD");
						}

						actions
							.update(
								{
									id: auth.id,
									...values,
								},
								{
									updateList: true,
								}
							)
							.then((data: any) => {
								setSubmitting(false);
								actions.get(auth.id).then(() => {
									navigate(
										"/profile/resume/create/education"
									);
								});
							});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleSubmit,
						handleBlur,
						isSubmitting,
						isValid,
					}) => (
						<form className="flex flex-1 gap-4 flex-col">
							<ResumeAvatar />
							<DateOfBirthPicker
								label={t("date-of-birth")}
								name="date_of_birth"
								value={values.date_of_birth}
								noMarginBottom
								valid={
									values.date_of_birth &&
									!errors.date_of_birth
								}
								error={errors.date_of_birth}
								{...{ handleChange, handleBlur }}
								onChange={(key, value) => {
									handleChange({
										target: {
											name: "date_of_birth",
											value,
										},
									});
								}}
							/>
							<Input
								label={t("description")}
								className={""}
								multiline={true}
								name="bio"
								noMarginBottom
								placeholder={t("description-placeholder")}
								value={values.bio}
								valid={values.bio && !errors.bio}
								error={errors.bio && touched.bio && errors.bio}
								{...{ handleChange, handleBlur }}
							/>
							<div className="flex-1"></div>
							<div className="flex justify-end">
								<Button
									type="dark"
									small
									disabled={!isValid}
									loading={isSubmitting}
									className="w-40"
									onClick={handleSubmit}
								>
									{t("continue")}
								</Button>
							</div>
						</form>
					)}
				</Formik>
			)}
		</>
	);
}

GeneralInfoForm.locale = {
	nl: {
		title: "Mijn profiel",
		"not-found": {
			title: "CV niet gevonden",
			description: "Het lijkt er op dat je nog geen CV hebt.",
			create_resume: "Creëer een CV",
		},
		"date-of-birth": "Geboortedatum",
		"min-age": "Minimale leeftijd is 16 jaar",
		description: "Omschrijving",
		"description-placeholder": "Vertel wat over jezelf",
		required: "Verplicht",
		continue: "Doorgaan",
	},
	en: {
		title: "My profile",
		"not-found": {
			title: "Resume not found",
			description: "It seems like you don't have a resume yet.",
			create_resume: "Create a resume",
		},
		"date-of-birth": "Date of birth",
		"min-age": "Minimal age is 16 year",
		description: "Description",
		"description-placeholder": "Tell something about yourself",
		required: "Required",
		continue: "Continue",
	},
};

export default withTranslation(GeneralInfoForm);
