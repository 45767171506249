import React from "react";
import { useTranslation } from "react-i18next";
import Card from "src/components/Card";
import TipCard from "src/components/cards/TipCard";
import Layout from "src/components/layout/Layout";
import ResumeExperiencesList from "src/components/resume/modules/ResumeExperiencesList";
import useAuth from "src/hooks/selectors/useAuth";

const DashboardEditExperiencesView = () => {
	const { t } = useTranslation("resume-view", {
		keyPrefix: "edit-experiences-view",
	});
	const auth = useAuth();
	return (
		<Layout
			sidebar={
				<>
					<TipCard>{t("tip")}</TipCard>
				</>
			}
			title={t("title")}
			breadcrumb={[
				{
					isHome: true,
					label: t("breadcrumb.my-profile"),
					to: "/dashboard",
				},
				{
					label: t("breadcrumb.experiences"),
					to: "/dashboard/resume/experiences",
				},
			]}
		>
			<Layout.Header backUrl="/dashboard" title={t("title")} />
			<Card id="education" title={t("education")}>
				<ResumeExperiencesList
					color={auth.primary_color}
					type="education"
					query={"/student/experiences?type=education"}
					editable
				/>
			</Card>
			<Card id="job" title={t("job")}>
				<ResumeExperiencesList
					color={auth.primary_color}
					type="job"
					query={"/student/experiences?type=job"}
					editable
				/>
			</Card>
		</Layout>
	);
};

export default DashboardEditExperiencesView;
