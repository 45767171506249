import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ApiRegistration } from "src/api/types";
import FlatList from "src/components/FlatList";
import RegistrationListItem from "src/components/lists/list-items/RegistrationListItem";
import useGet from "src/hooks/rest/useGet";
import useSocketListener from "src/hooks/sockets/useSocketListener";
import useUpdateEffect from "src/hooks/useUpdateEffect";

interface RegistrationSelectListProps {
	limit: number;
	query?: string;
	task?: string | number;
}

const RegistrationSelectList = ({
	limit,
	query,
	task,
}: RegistrationSelectListProps) => {
	const location = useLocation();
	const { t } = useTranslation("lists", {
		keyPrefix: "registration-select-list",
	});
	const [result, loading, { pagination, handleSearch, setResult, fetchGet }] =
		useGet(
			`/tasks/${task}/registrations?limit=${limit}&include[]=task&include[]=user&with[]=favorite&response_type=list&&${query}`,
			{ data: [] },
			true
		);
	const { data } = result;

	const setItemStatus = (status: string, registration: string) => {
		setResult((res: any) => ({
			...res,
			data: res.data.map((item: ApiRegistration) => {
				if (item.hid === registration) {
					return {
						...item,
						status,
					};
				}
				return item;
			}),
		}));
	};

	useSocketListener(`tasks.${task}.registrations.status`, (res) => {
		if (res?.status && res?.registration) {
			setItemStatus(res.status, res.registration);
		}
	});

	useUpdateEffect(() => {
		fetchGet(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<FlatList
			noInfiniteScroll
			gap={16}
			searchPlaceholder={t("search")}
			{...{ loading, pagination, handleSearch }}
			data={data || []}
			empty={{
				title: t("empty.title"),
			}}
			renderItem={(row: any) => (
				<RegistrationListItem
					registration={row}
					key={`registration-${row?.id}`}
				/>
			)}
		/>
	);
};

export default RegistrationSelectList;
