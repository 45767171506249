import SignupForm from "src/components/forms/auth/signup/SignupForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";

function SignupView({ t }: Translation) {
	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: "/login",
					label: t("back"),
				},
			}}
		>
			<SignupForm />
		</AuthLayout>
	);
}

SignupView.locale = {
	nl: {
		title: "Aanmelden",
		back: "Terug naar inloggen",
	},
	en: {
		title: "Signup",
		back: "Back to login",
	},
};
export default withTranslation(SignupView);
