import React from "react";
import { useTranslation } from "react-i18next";
import { ApiRegistration } from "src/api/types";
import Button from "src/components/Button";
import ChatButton from "src/components/tasks/TaskForm/elements/ChatButton";
import SubscriptionProtectButton from "src/components/subscription/subscription-protect/SubscriptionProtectButton";
import useTask from "src/hooks/api/tasks/useTask";
import useAuth from "src/hooks/selectors/useAuth";
import useWindowSize from "src/hooks/useWindowSize";

interface RegistrationButtonsProps {
	registration: ApiRegistration;
}

const RegistrationButtons = ({ registration }: RegistrationButtonsProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "buttons",
	});
	const auth = useAuth();
	const { task } = useTask();
	const { isPhone } = useWindowSize();
	const { status, payment } = registration;
	const canFinish =
		["reserved", "completed"].includes(payment?.status) ||
		payment?.type === "afterpay";

	if (auth.type === "student") {
		return (
			<Wrapper>
				{!registration?.has_reviewed &&
					["matched", "completed"].includes(status) && (
						<Button
							to={`/tasks/${task.hid}/show/${registration?.hid}/accept`}
							type="orange"
							disabled={!registration.is_signed}
						>
							{t("review")}
						</Button>
					)}
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			{status !== "matched" && (
				<ChatButton
					className="gtm-student-dashboard-opdracht--button-naar-chat"
					to={`/tasks/${task.hid}/show/${registration?.hid}/chat`}
					registrationId={registration?.id}
				/>
			)}
			{status === "pending" && (
				<>
					<SubscriptionProtectButton
						to={`/tasks/${task.hid}/show/${registration?.hid}/denie`}
						type="dark"
					>
						<i className="fas fa-thumbs-down mr-2"></i>
						{t("denie")}
					</SubscriptionProtectButton>
					<SubscriptionProtectButton
						to={`/tasks/${task.hid}/show/${registration?.hid}/accept`}
						type="primary"
					>
						<i className="fas fa-thumbs-up mr-2"></i>
						{t("accept")}
					</SubscriptionProtectButton>
				</>
			)}

			{status === "completed" && !registration.has_reviewed && (
				<SubscriptionProtectButton
					to={`/tasks/${task.hid}/show/${registration?.hid}/review`}
					type="orange"
					disabled={!canFinish || !registration.is_signed}
				>
					{t("review")}
				</SubscriptionProtectButton>
			)}

			{status === "matched" && (
				<>
					{isPhone && (
						<ChatButton
							className="gtm-student-dashboard-opdracht--button-naar-chat"
							to={`/tasks/${task.hid}/show/${registration?.hid}/chat`}
							registrationId={registration?.id}
						/>
					)}
					{!registration?.has_reviewed && (
						<>
							<SubscriptionProtectButton
								to={`/tasks/${task.hid}/show/${registration?.hid}/cancel`}
								type="gray"
							>
								{t("cancel-task")}
							</SubscriptionProtectButton>
							<SubscriptionProtectButton
								to={`/tasks/${task.hid}/show/${registration?.hid}/review`}
								type="orange"
								disabled={!canFinish || !registration.is_signed}
							>
								{t("payout")}
							</SubscriptionProtectButton>
						</>
					)}
				</>
			)}
		</Wrapper>
	);
};

interface WrapperProps {
	children?: any;
}

const Wrapper = ({ children }: WrapperProps) => {
	return (
		<div className="flex flex-col-reverse sm:flex-row sm:items-center sm:justify-center gap-4 sticky bottom-0 bg-background p-4 z-10">
			{children}
		</div>
	);
};

export default RegistrationButtons;
