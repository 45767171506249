import { Helmet } from "react-helmet";
import useTenant from "src/hooks/api/services/tenants/useTenant";

const TenantIcons = () => {
	const { tenant } = useTenant();
	return (
		<Helmet>
			<link
				rel="icon"
				href={
					process.env.PUBLIC_URL + `/icons/${tenant.slug}/favicon.png`
				}
				sizes="32x32"
				type="image/png"
			/>

			<link
				rel="icon"
				href={
					process.env.PUBLIC_URL + `/icons/${tenant.slug}/logo192.png`
				}
				sizes="192x192"
				type="image/png"
			/>

			<link
				rel="icon"
				href={
					process.env.PUBLIC_URL + `/icons/${tenant.slug}/logo512.png`
				}
				sizes="512x512"
				type="image/png"
			/>
		</Helmet>
	);
};

export default TenantIcons;
