import ProfileBadge from "src/components/ProfileBadge/ProfileBadge";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeCardContext from "../ResumeCardContext";
import ResumeSectionTitle from "src/components/ResumeCompanyUser/resume-card/ResumeSectionTitle";

function ResumeBadge({ t }: Translation) {
	const { resume } = useResumeCardContext();

	if (resume.type === "company") {
		return <></>;
	}

	return (
		<div className="flex flex-col gap-4">
			{!resume.badges.length ? (
				<div className="flex flex-col gap-4">
					<h3>
						{t("title-no-badges", {
							first_name: resume.first_name,
						})}
					</h3>
				</div>
			) : (
				<>
					<div className="flex gap-4 flex-col">
						<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>
						{resume.badges.map((item: any) => (
							<ProfileBadge
								variant={
									resume.badges?.length === 1
										? "resume-list"
										: "resume-list"
								}
								key={item.type}
								badge={item}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
}

ResumeBadge.locale = {
	nl: {
		title: "Badges",
		"title-no-badges": "{{first_name}} heeft nog geen badges",
	},
	en: {
		title: "Badges",
		"title-no-badges": "{{first_name}} has no badges yet",
	},
};

export default withTranslation(ResumeBadge);
