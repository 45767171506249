import { HTMLAttributes, forwardRef } from "react";
import { cn } from "src/lib/utils";

const variants = {
	xsmall: "px-4 xl:px-0 w-full max-w-[660px] mx-auto",
	small: "px-4 xl:px-0 w-full max-w-[960px] mx-auto",
	medium: "px-4 w-full max-w-[1300px] mx-auto",
	large: "px-4 w-full max-w-[1500px] mx-auto",
	xlarge: "px-4 w-full max-w-[1600px] mx-auto",
	xxl: "flex flex-col max-w-xxl w-full mx-auto px-4",
	xxxl: "flex flex-col max-w-xxxl w-full mx-auto",
	lg: "flex flex-col max-w-lg w-full mx-auto",
	"no-container": "",
	default: "px-4 md:px-8",
	"full-scrollable":
		"flex flex-col flex-1 overflow-auto hide-default-scrollbar md:mx-0 md:px-0 -mx-4 px-4",
};

export type ContainerVariant = keyof typeof variants;

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
	children?: any;
	className?: string;
	variant?: ContainerVariant;
}

const Container = forwardRef(
	(
		{ children, className, variant = "default", ...rest }: ContainerProps,
		ref: any
	) => {
		return (
			<div
				{...rest}
				{...{ ref }}
				className={cn(variants[variant], className)}
			>
				{children}
			</div>
		);
	}
);

export default Container;
