import moment from "moment";
import { ApiMessage } from "src/api/types";
import Avatar from "src/components/Avatar";
import MessageBidForm from "src/components/chat/MessageBidForm";
import MessageFile from "src/components/chat/MessageFile";
import Skeleton from "src/components/Skeleton";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";

const Message = ({
	content,
	is_me,
	created_at,
	is_admin,
	creator,
	media,
	type: messageType,
	data,
}: ApiMessage) => {
	const size = useWindowSize();
	const className = cn(
		"items-end flex",
		is_me && "rounded-md w-max ml-auto",
		is_admin && "justify-center items-center text-center"
	);

	const parseMessage = (message: string) => {
		// Regular expression to match URLs
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		// Split the message into parts (text and URLs)
		const parts = message.split(urlRegex);
		// Map through parts to wrap URLs in <a> tags
		return parts.map((part: any, index: number) => {
			if (part.match(urlRegex)) {
				// If part is a URL, wrap it in an <a> tag
				return (
					<a
						key={index}
						href={part}
						target="_blank"
						className="dark:text-primary-foreground text-primary-foreground"
						rel="noopener noreferrer"
					>
						{part}
					</a>
				);
			} else {
				// Otherwise, return plain text
				return part;
			}
		});
	};

	return (
		<div className={`${className} w-full gap-4`}>
			{!is_me && !is_admin && !size.isPhone && (
				<Avatar size="regular" src={creator?.avatar} />
			)}
			<div className="">
				{media && media.length > 0 ? (
					<div className="flex flex-wrap md:flex-row flex-col mb-4 gap-4">
						{media.map((file) => (
							<MessageFile key={`file-${file.id}`} media={file} />
						))}
					</div>
				) : (
					false
				)}
				{content && (
					<div
						className={cn(
							"p-4 rounded-md w-max bg-accent text-accent-foreground dark:bg-background dark:text-background-foreground",
							is_me &&
								"bg-primary text-primary-foreground dark:bg-primary dark:text-primary-foreground ml-auto"
						)}
					>
						<div className="max-w-[220px] md:max-w-[400px] w-full description">
							{parseMessage(content)}
						</div>
					</div>
				)}
				{messageType === "bid" && data && (
					<MessageBidForm {...{ creator }} bid={data.bid} />
				)}

				<small
					className={`text-background-foreground opacity-20 ${
						is_me && "w-full block text-right mt-1"
					}`}
				>
					{moment(created_at).format("DD-MM-YYYY HH:mm")}
				</small>
			</div>
			{is_me && !is_admin && !size.isPhone && (
				<Avatar size="regular" src={creator?.avatar} />
			)}
		</div>
	);
};

interface LoadingProps {
	is_me?: boolean;
	lines?: number;
}

const Loading = ({ is_me, lines }: LoadingProps) => {
	const size = useWindowSize();
	let className = "items-end flex";

	if (is_me) {
		className += " rounded-md w-max ml-auto";
	}

	return (
		<div className={`${className} w-full gap-4`}>
			{!is_me && !size.isPhone && <Skeleton className="w-12 h-12" />}
			<div
				className={`${
					is_me ? "bg-primary ml-auto" : "bg-accent-900"
				} p-4 rounded-md w-max`}
			>
				<div className="flex flex-col gap-2 max-w-[220px] md:max-w-[400px] w-full description">
					{new Array(lines || 3).fill("").map((_, index) => (
						<Skeleton
							key={`${Math.random()}-${index}`}
							className="h-[27px]"
							style={{
								width: index === 0 ? "54px" : "200px",
							}}
						/>
					))}
				</div>
			</div>
			{is_me && !size.isPhone && <Skeleton className="w-12 h-12" />}
		</div>
	);
};

Message.Loading = Loading;

export default Message;
