import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import "./resume-edit-bio.scss";
import { useSelector } from "react-redux";
import useEditProfile from "src/hooks/api/useEditProfile";
import Input from "src/components/form/Input";
import Button from "src/components/Button";
import { useTranslation } from "react-i18next";

const scheme = {
	bio: Yup.string(),
} as {
	[key: string]: any;
};

const ResumeEditBio = () => {
	const { t } = useTranslation(["resume", "buttons"]);
	const user = useSelector((state: any) => state.auth);
	const [patchProfile] = useEditProfile();
	const [isSaved, setIsSaved] = useState(false);

	return (
		<Formik
			initialValues={{
				bio: user?.bio?.bio || "",
			}}
			validationSchema={Yup.object().shape(scheme)}
			onSubmit={(values: any, { setSubmitting, resetForm }) => {
				patchProfile({
					bio: {
						...values,
					},
				})
					.then(() => {
						setIsSaved(true);
						setTimeout(() => {
							setIsSaved(false);
						}, 3000);
					})
					.finally(() => setSubmitting(false));
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<form onSubmit={handleSubmit}>
					<Input
						label={t("edit-bio.label", {
							ns: "resume",
						})}
						multiline
						name="bio"
						value={values.bio}
						valid={values.bio && !errors.bio}
						error={errors.bio && touched.bio && errors.bio}
						{...{ handleChange, handleBlur }}
					/>

					<div className="buttons">
						<Button
							submit
							type={isSaved ? "primary" : undefined}
							loading={isSubmitting}
							className="gtm-student-dashboard-mijn-cv--button-omschrijving-aanpassen"
						>
							{isSaved && <i className="fas fa-check mr-2"></i>}
							{t("save", {
								ns: "buttons",
							})}
						</Button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default ResumeEditBio;
