import { atom, useAtom } from "jotai";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useApi from "src/hooks/api/utils/useApi";

type Interval = "MONTHLY" | "YEARLY";

const planIntervalAtom = atom<Interval>("MONTHLY");

export default function usePlans() {
	const { state, actions } = useApi(
		{
			id: "PLANS",
		},
		{
			baseUrl: "/auth/subscriptions/plans",
		}
	);
	const { tenant } = useTenant();
	const [interval, setInterval] = useAtom<Interval, any, any>(
		planIntervalAtom
	);
	const plans = state?.list || [];
	const intervalEnabled =
		["getjobsdone", "odylyt"].includes(tenant.slug) &&
		!plans.some((plan) => plan.slug === "jong-mkb");

	return {
		plans: state?.list || [],
		status: state.status,
		interval: !intervalEnabled ? "MONTHLY" : interval,
		intervalEnabled,
		actions: {
			...actions,
			setInterval,
		},
	};
}
