import StudentSignupForm from "src/components/forms/auth/signup/student/StudentSignupForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import AuthLayout from "src/components/layout/auth-layout/AuthLayout";

function SignupStudentView({ t }: Translation) {
	return (
		<AuthLayout
			head={{
				title: t("title"),
				back: {
					to: "/signup",
					label: t("back"),
				},
			}}
		>
			<StudentSignupForm />
		</AuthLayout>
	);
}

SignupStudentView.locale = {
	nl: {
		title: "Aanmelden als professional",
		back: "Terug naar aanmelden",
	},
	en: {
		title: "I want to complete tasks",
		back: "Back to signup",
	},
};
export default withTranslation(SignupStudentView);
