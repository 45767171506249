import moment from "moment";
import Avatar from "src/components/Avatar";
import Card from "src/components/Card";
import FlatList from "src/components/FlatList";
import Stars from "src/components/Stars";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeCardContext from "../ResumeCardContext";
import useReviews from "src/hooks/api/services/resume/useReviews";
import { ApiReview } from "src/api/types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ResumeSectionTitle from "src/components/ResumeCompanyUser/resume-card/ResumeSectionTitle";
import { cn } from "src/lib/utils";

interface ResumeReviewsProps extends Translation {
	variant?: "grid" | "list";
}

function ResumeReview({ t, variant = "grid" }: ResumeReviewsProps) {
	const { id } = useParams();
	const { resume } = useResumeCardContext();
	const { reviews, pagination, status, actions } = useReviews({ id: id });
	const loading = status === "loading" && reviews.length === 0;

	useEffect(() => {
		actions.list({
			page: 1,
		});

		// return () => {
		// 	actions.resetState();
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (resume.type === "company") {
		return <></>;
	}

	return (
		<div className="flex flex-col gap-4">
			{!reviews.length && status === "idle" ? (
				<div className="flex flex-col gap-2">
					<p className="font-bold">
						{t("title-no-reviews", {
							first_name: resume.first_name,
						})}
					</p>
					<p>
						{t("description-no-reviews", {
							first_name: resume.first_name,
						})}
					</p>
				</div>
			) : (
				<>
					<div className="flex flex-row justify-between items-center gap-2">
						<ResumeSectionTitle>{t("title")}</ResumeSectionTitle>
						<div className="flex flex-row gap-4 items-center">
							<Stars
								size="large"
								rating={resume.reviews_average}
							/>
							<p>
								{t("reviews", {
									count: resume.reviews_count,
								})}
							</p>
						</div>
					</div>
					<div className="flex gap-8 flex-wrap">
						<FlatList
							empty={{ title: "" }}
							data={reviews}
							columns={variant === "grid" ? 2 : 1}
							gap={16}
							fetching={loading}
							{...{ loading }}
							pagination={{
								...pagination,
							}}
							renderItem={(item: ApiReview) => (
								<Card
									key={item.id}
									className={cn(
										variant === "list" && "shadow-none"
									)}
									contentClassName={cn(
										variant === "list" && "p-0 gap-4"
									)}
								>
									<div className="flex items-center justify-between gap-2">
										<div className="flex flex-row gap-4 items-center">
											<Avatar
												size="small"
												src={item.creator?.avatar}
											/>
											<div className="flex flex-col gap-1">
												<p className="leading-full text-sm">
													{item.creator?.full_name}
												</p>
												{item.creator?.company && (
													<small className="opacity-60">
														{
															item.creator
																?.company.name
														}
													</small>
												)}
											</div>
										</div>
										<div className="flex flex-col gap-1">
											<Stars
												size="small"
												rating={item.rating}
											/>
											<small className="opacity-60 text-right">
												{moment(item.created_at).format(
													"DD/MM/YYYY"
												)}
											</small>
										</div>
									</div>
									<p>"{item.description}"</p>
									<div className="flex flex-col">
										<p className="text-sm opacity-60">
											{t("assignment")}
										</p>
										<p className="text-sm">
											{item.task?.title}
										</p>
									</div>
								</Card>
							)}
						/>
					</div>
				</>
			)}
		</div>
	);
}

ResumeReview.locale = {
	nl: {
		title: "Reviews",
		"title-no-reviews": "{{first_name}} heeft nog geen reviews",
		"description-no-reviews":
			"Ben jij de eerste die {{first_name}} een review geeft? Werk samen en geef na afronding een eerlijke beoordeling van de samenwerking.",
		assignment: "Opdracht",
		reviews_one: "1 review",
		reviews_other: "{{count}} reviews",
	},
	en: {
		title: "Reviews",
		"title-no-reviews": "{{first_name}} has no reviews yet",
		"description-no-reviews":
			"Will you be the first to review {{first_name}}? Work together and give an honest assessment of the cooperation.",
		assignment: "Assignment",
		reviews_one: "1 review",
		reviews_other: "{{count}} reviews",
	},
};

export default withTranslation(ResumeReview);
