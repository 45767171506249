import { useNavigate } from "react-router-dom";
import useNavigateModal from "src/hooks/useNavigateModal";

interface StudentOnboardingItemProps {
	title: string;
	description: string;
	isCompleted: boolean;
	image: string;
	to?: string;
	disabled?: boolean;
	linkType?: "modal" | "link";
}

const StudentOnboardingItem = ({
	title,
	description,
	image,
	isCompleted,
	to,
	disabled,
	linkType,
}: StudentOnboardingItemProps) => {
	const navigateModal = useNavigateModal();
	const navigate = useNavigate();
	return (
		<div
			onClick={() => {
				if (!disabled) {
					if (linkType === "modal") {
						navigateModal(to || "/dashboard");
					} else {
						navigate(to || "/dashboard");
					}
				}
			}}
			className={`transition-all border-2 flex md:flex-row flex-col md:items-center rounded-md dark:border-dark dark:hover:bg-dark hover:border-primary border-${
				isCompleted ? "green-300" : "gray"
			} px-6 py-4 gap-4 ${
				isCompleted && "text-background-foreground bg-green-300"
			} ${!disabled && " cursor-pointer"}`}
		>
			<div className="relative">
				<img className="w-10" src={image} alt={title} />
				{isCompleted ? (
					<i className="fas fa-check absolute -bottom-2 -right-1 bg-primary text-white rounded-md p-1 text-[12px] border-1"></i>
				) : (
					false
				)}
			</div>

			<div className="flex-1">
				<strong className="block">{title}</strong>
				<small className="mb-0">{description}</small>
			</div>
		</div>
	);
};

StudentOnboardingItem.defaultProps = {
	isCompleted: false,
	linkType: "modal",
};

export default StudentOnboardingItem;
