import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Card from "src/components/Card";
import ResumeCard from "src/components/ResumeCompanyUser/resume-card/ResumeCard";
import useResume from "src/hooks/api/services/resume/useResume";
import { useEffect } from "react";
import useNavigateModal from "src/hooks/useNavigateModal";
import useAuth from "src/hooks/selectors/useAuth";
import Skeleton from "src/components/Skeleton";
import useBackUrl from "src/hooks/useBackUrl";
import ProfileSettingsLayout from "src/components/layout/profile-settings-layout/ProfileSettingsLayout";

function ResumeView({ t }: Translation) {
	const auth = useAuth();
	const { resume, actions, status } = useResume({
		rememberKey: auth.id,
	});
	const navigateModal = useNavigateModal();
	const backUrl = useBackUrl();

	useEffect(() => {
		actions.get(auth.id);
		return () => {
			actions.setItem(
				{
					data: {},
				},
				{
					updateList: false,
				}
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProfileSettingsLayout title={t("title")}>
			{status === "loading" || !resume?.id ? (
				<div className="flex justify-center">
					<Loading type />
				</div>
			) : status === "idle" && !resume.has_resume ? (
				<div className="flex flex-1 justify-center items-center p-8 text-center flex-col max-w-md mx-auto">
					<strong className="mb-1 text-[18px]">
						{t("not-found.title")}
					</strong>
					<p className="mb-4">{t("not-found.description")}</p>
					<Button to={`/profile/resume/create`}>
						{t("not-found.create_resume")}
					</Button>
				</div>
			) : (
				<>
					<div className="flex flex-wrap flex-row gap-2 md:justify-end">
						<Button
							to={`/dashboard/resume/preview/${auth.hid}?${backUrl}`}
							type="gray"
							loading={status !== "idle"}
						>
							<i className="fas fa-id-card mr-2"></i>
							{t("cv")}
						</Button>
						<Button
							type="gray"
							onClick={() => {
								navigateModal("/profile/resume/theme/edit");
							}}
							loading={status !== "idle"}
						>
							<i className="fas fa-palette mr-2"></i>
							{t("adjust-theme")}
						</Button>
						<Button
							type="dark"
							to={`/profile/resume/create?${backUrl}`}
							loading={status !== "idle"}
						>
							<i className="fas fa-pencil mr-2"></i>
							{t("adjust")}
						</Button>
					</div>
					<div className="mt-4">
						<ResumeCard resume={resume}>
							<div className="flex flex-col gap-10">
								<ResumeCard.Head />
								<ResumeCard.Bio editable />
								<ResumeCard.Skills editable />
								<ResumeCard.Education editable />
								<ResumeCard.Work editable />
								<ResumeCard.Reviews />
								<ResumeCard.Badge />
								<ResumeCard.SocialMedia editable />
							</div>
						</ResumeCard>
					</div>
				</>
			)}
		</ProfileSettingsLayout>
	);
}

ResumeView.locale = {
	nl: {
		title: "Mijn profiel",
		"not-found": {
			title: "CV niet gevonden",
			description: "Het lijkt er op dat je nog geen CV hebt.",
			create_resume: "Creëer een CV",
		},
		adjust: "Aanpassen",
		"adjust-theme": "Aanpassen van thema",
		cv: "Bekijk CV",
	},
	en: {
		title: "My profile",
		"not-found": {
			title: "Rsume not found",
			description: "It seems like you don't have a resume yet.",
			create_resume: "Create a resume",
		},
		adjust: "Adjust",
		"adjust-theme": "Adjust theme",
		cv: "See your resume",
	},
};

interface LoadingProps {
	type: boolean;
}

export const Loading = ({ type }: LoadingProps) => {
	return (
		<div>
			{type && (
				<div className="flex flex-row gap-2 justify-end">
					<Skeleton className="bg-background h-12 w-36 mb-4" />
					<Skeleton className="bg-background h-12 w-52 mb-4" />
					<Skeleton className="bg-background h-12 w-40 mb-4" />
				</div>
			)}
			<Card className="w-full">
				{type ? (
					<div className="flex flex-row gap-2 pb-4 items-end">
						<Skeleton className="bg-background rounded-lg w-48 h-48 text-[32px]" />
						<div className="flex flex-col gap-2 pb-4">
							<Skeleton className="bg-background h-14 w-64" />
							<Skeleton className="bg-background h-6 w-64" />
						</div>
					</div>
				) : (
					<div className="flex flex-row gap-4 mb-8">
						<div
							style={{
								marginTop: "-100px",
								position: "relative",
							}}
						>
							<Skeleton className="bg-background rounded-xl w-56 h-56" />
						</div>
						<div className="ml-4 flex flex-col gap-2">
							<h1 className="relative">
								<Skeleton className="bg-background h-14 w-64" />
							</h1>
							<Skeleton className="bg-background h-14 w-64" />
						</div>
					</div>
				)}
				<div className="my-6">
					<Skeleton className="bg-background h-6 w-20 mb-4" />
					<Skeleton className="bg-background h-44 w-full" />
				</div>
				<Skeleton className="bg-background h-32 w-full" />
				<div>
					<div className="my-6">
						<Skeleton className="bg-background h-6 w-20 mb-4" />
						<div className="flex flex-row gap-4">
							<Skeleton className="bg-background h-32 w-64" />
							<Skeleton className="bg-background h-32 w-64" />
							<Skeleton className="bg-background h-32 w-64" />
							<Skeleton className="bg-background h-32 w-64" />
						</div>
					</div>
				</div>
				<div>
					<div className="my-6">
						<Skeleton className="bg-background h-6 w-20 mb-4" />
						<div className="flex flex-row gap-4">
							<Skeleton className="bg-background h-32 w-64" />
							<Skeleton className="bg-background h-32 w-64" />
							<Skeleton className="bg-background h-32 w-64" />
							<Skeleton className="bg-background h-32 w-64" />
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default withTranslation(ResumeView);
