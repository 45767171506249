import { useLocation } from "react-router-dom";
import useQueryString from "src/hooks/useQueryString";

export default function useBackUrl() {
	const location = useLocation();
	let url = location.pathname;
	url = btoa(url);
	return `back=${url}`;
}

export function useParseBackUrl() {
	const query = useQueryString();

	return query.back ? atob(query.back as string) : null;
}
