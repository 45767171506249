import Avatar from "src/components/Avatar";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeCardContext from "../ResumeCardContext";
import useWindowSize from "src/hooks/useWindowSize";
import { useMemo } from "react";
import { cn } from "src/lib/utils";
import ResumeEditButton from "src/components/ResumeCompanyUser/resume-card/ResumeEditButton";

interface ResumeHeadProps extends Translation {
	variant?: "large" | "small";
	className?: string;
	editable?: boolean;
}

function ResumeHead({
	t,
	variant = "large",
	className,
	editable,
}: ResumeHeadProps) {
	const { resume } = useResumeCardContext();
	const { isPhone } = useWindowSize();
	const avatarSize = useMemo(() => {
		if (variant === "small") {
			return "large";
		}
		return isPhone ? "large" : "xxlarge";
	}, [isPhone, variant]);
	const Title = variant === "large" ? "h1" : "strong";
	return (
		<div
			className={cn(
				"flex flex-col md:flex-row gap-4 md:items-end mb-8",
				className
			)}
		>
			<div className="flex w-max relative">
				<Avatar size={avatarSize} src={resume.avatar} />
				{editable && (
					<ResumeEditButton
						variant="general"
						className="absolute -bottom-2 -right-2 bg-background hover:bg-border transition-all w-8 h-8 rounded-md border border-border flex justify-center items-center"
					/>
				)}
			</div>
			<div className="flex flex-col gap-2 flex-1">
				<Title>{resume.full_name}</Title>
				<p>{resume.tagline}</p>
			</div>
		</div>
	);
}

export default withTranslation(ResumeHead);
