import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useResumeCardContext from "../ResumeCardContext";
import Label from "src/components/Label";
import useAuth from "src/hooks/selectors/useAuth";
import ResumeSectionTitle from "src/components/ResumeCompanyUser/resume-card/ResumeSectionTitle";
import ResumeEditButton from "src/components/ResumeCompanyUser/resume-card/ResumeEditButton";
import { cn } from "src/lib/utils";

interface ResumeSkillsProps extends Translation {
	editable?: boolean;
}

function ResumeSkills({ t, editable }: ResumeSkillsProps) {
	const auth = useAuth();
	const { resume } = useResumeCardContext();
	const backgroundColor = resume.primary_color;

	if (!resume?.skills?.length && resume.id === auth.id && editable) {
		return (
			<div
				style={{ backgroundColor }}
				className={cn(
					"flex flex-col text-center px-4 py-8 md:p-8 rounded-md justify-center items-center"
				)}
			>
				<h3 className="text-white mb-2">{t("empty.title")}</h3>
				<p className="text-white">{t("empty.description")}</p>
				<ResumeEditButton
					type="button"
					variant="skill"
					className="mt-6"
				>
					<i className="fas fa-plus mr-1"></i>
					{t("empty.button")}
				</ResumeEditButton>
			</div>
		);
	}

	if (!resume?.skills || !resume?.skills.length) {
		return <></>;
	}

	return (
		<div
			style={{ backgroundColor }}
			className={`flex rounded-lg flex-col text-center ${
				editable ? "gap-6" : "gap-8"
			} px-4 py-8 md:p-8`}
		>
			{editable ? (
				<div className="flex flex-row gap-3 justify-between items-center">
					<div className=""></div>
					<ResumeSectionTitle className="text-white">
						{t("title")}
					</ResumeSectionTitle>
					<ResumeEditButton variant="skill" className="text-white" />
				</div>
			) : (
				<ResumeSectionTitle className="text-white">
					{t("title")}
				</ResumeSectionTitle>
			)}
			<div className="flex gap-2 flex-wrap justify-center">
				{resume?.skills.map((item: any) => (
					<Label
						type="white"
						key={item.id}
						endorsements={item?.endorsements}
					>
						<div className="flex items-center">{item.name}</div>
					</Label>
				))}
			</div>
		</div>
	);
}

ResumeSkills.locale = {
	nl: {
		title: "Vaardigheden",
		empty: {
			title: "Geen vaardigheden gevonden",
			description:
				"Laat bedrijf in één oogopslag zien waar je goed in bent",
			button: "Skills toevoegen",
		},
	},
	en: {
		title: "Skills",
		empty: {
			title: "No skills found",
			description: "Show companies at a glance what you're good at",
			button: "Add skills",
		},
	},
};

export default withTranslation(ResumeSkills);
