import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ApiExperience } from "src/api/types";
import Button from "src/components/Button";
import FlatList from "src/components/FlatList";
import TimelineItem from "src/components/Timeline/TimelineItem";
import useGet from "src/hooks/rest/useGet";
import useNavigateModal from "src/hooks/useNavigateModal";
import { RESUME_EXPERIENCES_ICONS } from "src/lib/constants";

interface ResumeExperiencesListProps {
	query: string;
	type: "job" | "education";
	color: string;
	editable?: boolean;
	className?: string;
}

const ResumeExperiencesList = ({
	type,
	query,
	color,
	editable,
	className,
}: ResumeExperiencesListProps) => {
	const { t } = useTranslation("resume", {
		keyPrefix: "resume-experiences-list",
	});
	const navigate = useNavigateModal();
	const location = useLocation();
	const [{ data }, loading, { fetchGet }] = useGet(query, {
		data: [],
	});

	useEffect(() => {
		if (!loading) {
			fetchGet(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	if (!loading && data.length === 0) {
		return (
			<div className="py-8 px-2 gap-4 flex flex-col justify-center items-center">
				<div className="flex items-center">
					<img
						className="w-8"
						src={RESUME_EXPERIENCES_ICONS[type].icon}
						alt={t(`${type}.empty.title`)}
					/>
					<p className="mb-0">{t(`${type}.empty.title`)}</p>
				</div>

				{editable && (
					<Button
						className="mt-2"
						onClick={() => {
							navigate(
								`/dashboard/resume/experiences/${type}/create`
							);
						}}
					>
						<i className="fas fa-plus mr-2"></i>
						{t("create")}
					</Button>
				)}
			</div>
		);
	}

	return (
		<div className={`flex flex-col ${className}`}>
			<FlatList
				gap={0}
				{...{ data, loading }}
				empty={{
					title: t(`${type}.empty.title`),
				}}
				renderItem={(item: ApiExperience, index?: number) => (
					<TimelineItem
						key={`experience-${item.id}`}
						date={{
							start: item.started,
							end: item?.ended,
						}}
						title={item.title}
						subtitle={item.subtitle}
						type={{
							first: index === 0,
							last: data.length === (index || 0) + 1,
						}}
						{...{ color }}
						actions={
							editable && (
								<div className="flex items-center">
									<Button
										type="gray"
										iconOnly
										onClick={() => {
											navigate(
												`/dashboard/resume/experiences/${type}/${item.id}/edit`
											);
										}}
									>
										<i className="fas fa-pencil"></i>
									</Button>
								</div>
							)
						}
					/>
				)}
			/>
			{editable && (
				<div className="flex mt-4 justify-center items-center">
					<Button
						onClick={() => {
							navigate(
								`/dashboard/resume/experiences/${type}/create`
							);
						}}
					>
						<i className="fas fa-plus mr-2"></i>
						{t(`${type}.add`)}
					</Button>
				</div>
			)}
		</div>
	);
};

ResumeExperiencesList.defaultProps = {
	className: "mt-4",
};

export default ResumeExperiencesList;
