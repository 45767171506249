import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

function useResumeExperienceScheme() {
	const { t } = useTranslation("schemes");
	return {
		title: Yup.string().required(t("required")),
		subtitle: Yup.string().required(t("required")),
		description: Yup.string(),
		started: Yup.number()
			.required(t("required"))
			.max(moment().year(), t("year-future"))
			.test(
				"len",
				t("type-year"),
				(val?: any) =>
					val !== undefined || (val && val.toString().length === 4)
			),
		ended: Yup.number()
			.notRequired()
			.test(
				"len",
				t("type-year"),
				(val?: any) =>
					val === undefined || (val && val.toString().length === 4)
			)
			.max(moment().year(), t("year-future"))
			.when(
				"started",
				(started: any, schema: any) =>
					started &&
					schema.min(started, t("end-date-before-start-date"))
			),
	};
}

export default useResumeExperienceScheme;
