const subscription = {
	"minimal-subscription-plan": {
		confirm: {
			title: "Weet je het zeker",
			text: "Weet je zeker dat je je abonnement wilt wijzigen naar: {{name}} De opdrachten die al geplaatst zijn worden direct verrekend",
		},
		"cant-change":
			"Je kan niet wijzigen naar dit abonnement omdat je meer gebruikerslicenties hebt dan het pakket aanbiedt.",
		"per-month": " / per maand per",
		button: {
			current: "Huidige abonnement",
			upgrade: "Upgraden",
		},
	},
	"subscription-plan": {
		"most-common": "Meest gekozen",
		"cant-change":
			"Je kan niet wijzigen naar dit abonnement omdat je meer gebruikerslicenties hebt dan het pakket aanbiedt.",
		"per-month": "/ per maand",
		"service-fee": "Succesfee",
		trial: "{{days}} dagen gratis uitproberen",
		button: {
			"other-options": "Andere opties bekijken",
			choose: "Abonnement kiezen",
			current: "Huidige abonnement",
		},
		promos: {
			blackfriday: "Tijdelijke <1>BLACKFRIDAY</1> Deal",
		},
		footer: {
			"save-cta": "Bespaar {{save}}",
		},
	},
	"subscription-plan-price": {
		"first-month": "1e maand",
		"after-discount-price": "Daarna {{price}} per maand",
		"service-fee": "Succesfee",
		"per-month": "per maand",
		"per-year": "per jaar",
		save: "Bespaar {{value}}%",
		"excl-vat": "Excl. BTW",
		"first-x-months": "eerste {{months}} maanden",
		after: "Daarna {{price}} per maand",
	},
	"subscription-plan-custom": {
		title: "Toch niet helemaal wat je zoekt?",
		description:
			"Neem dan contact met ons op! Een medewerker van {{tenant.name}} kijkt samen met jou naar de mogelijkheden.",
		button: "Contact opnemen",
		subject: "Abonnement op maat: {{name}}",
	},
	"subscription-plan-selected": {
		"black-friday-sale": "Black friday korting",
		discount: "Korting",
		"trial-disclaimer-fee-later":
			"Dit bedrag wordt op <1>{{date}}</1> afgeschreven. Je kan je proefperiode daarvoor beëindigen.",
		"trial-disclaimer-fee-direct":
			"Dit bedrag wordt automatisch afgeschreven",
		error: {
			title: "Er is iets misgegaan",
			text: "Blijft dit probleem voorkomen, neem dan contact op met support@{{tenant.slug}}.nl",
		},
		summary: {
			title: "Samenvatting",
		},
		addons: {
			title: "Modules",
		},
		button: {
			confirm: "Abonnement bevestigen",
			"start-trial": "Start mijn gratis proefperiode",
		},
		wallet: {
			title: "Je hebt nog {{value}} in je wallet!",
			description: "Daarom betaal je voor deze betaling <1>{{value}}</1>",
			"description-remaining":
				"Daarom betaal je voor deze transactie <1>{{value}}</1>. Na voltooiing van deze betaling blijft er <3>{{remaining}}</3> over in je wallet.",
			"description-no-calculation":
				"Wij halen dit bedrag als korting van het subtotaal af!",
		},
	},
	"subscription-usage-card": {
		"per-month": "per maand",
		"service-fee": "succesfee",
		"auto-renew": "Wordt automatisch verlengt op {{date}}",
		button: {
			view: "Succesfee bekijken",
		},
	},
	"subscription-status": {
		title: "We zijn bijna klaar",
		mandate: {
			completed: "Machtiging is succesvol gemaakt",
			cancelled:
				"Het lijkt erop dat de machtiging niet is gelukt. Mocht je op een andere manier willen koppelen dan via Rabonbank, ABN of ING? Neem dan contact op met <1>support@getjobsodne.nl<1>",
			subject: "Incasso aanmaken {{name}}",
			pending: "Momenteel controleren wij de machtigingen",
		},
		subscription: {
			loading: "Het abonnement wordt aangemaakt",
			completed: "Het {{name}} abonnement is gekoppeld",
		},
		button: {
			continue: "Doorgaan",
		},
	},
	"current-mandate": {
		"change-subscription": "Abonnement wijzigen",
	},
	"current-subscription": {
		current: "Huidig abonnement",
		"per-month": "/ per maand excl. BTW",
		"per-year": "/ per jaar excl. BTW",
		"service-fee": {
			title: "+ {{price}} succesfee",
			button: "Bekijk opdrachten",
		},
	},
	"current-wallet": {
		title: "Huidig plaatsingstegoed",
		remaining: "resterend",
		why: {
			title: "Waarom heb ik nog plaatsingstegoed?",
			description:
				"Sinds 01-03-2021 is {{tenant.name}} overgegaan naar een abonnementsmodel, voorheen werd er gebruikt gemaakt door een portemonnee. Om de early adopters niet te verliezen gaan alle fee's en abonnement betalingen van deze portemonnee af.",
		},
	},
	"mandate-card": {
		connected: "Verbonden",
		pending: "In afwachting",
		"created-at": "Aangemaakt op: {{date}}",
		button: {
			selected: "Actieve betaalmethode",
			select: "Instellen als actief",
		},
	},
	"mandate-list": {
		error: {
			title: "Er is iets misgegaan",
			text: "Blijft dit probleem voorkomen, neem dan contact op met support@{{tenant.slug}}.nl",
		},
	},
	"subscription-next-payment": {
		title: "Volgende betaling",
		description: "Op {{date}}",
	},
	"subscription-payment-item": {
		summary: {
			title: "Samenvatting",
		},
		information: {
			title: "Gegevens",
			"start-date": "Begindatum:",
			"end-date": "Einddatum:",
			invoice: "Factuur",
			"invoice-number": "Factuurnummer",
			"worth-excl-vat": "Waarde excl. btw:",
		},
		"credit-log": {
			"service-fee": "Plaatsingstegoed",
			before: "Voor afschrijving",
			total: "Waarde",
			after: "Na afschrijving",
		},
		button: {
			"download-invoice": "Download factuur",
			before: "Voor afschrijving:",
			decreased: "Afgeschreven:",
			after: "Na afschrijving:",
		},
	},
	"subscription-payments-table": {
		headers: {
			price: "Bedrag",
			status: "Status",
			description: "Omschrijving",
			date: "Datum",
		},
		empty: {
			title: "Nog geen abonnementen gevonden",
		},
	},
};

export default subscription;
