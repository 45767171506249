import { Route } from "react-router-dom";
import DashboardConnectMandateView from "src/views/authenticated/dashboard/DashboardConnectMandateView";
import DashboardEditThemeModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditThemeModal";
import DashboardMandateView from "src/views/authenticated/dashboard/DashboardMandateView";
import DashboardView from "src/views/authenticated/DashboardView";
import DashboardShareNetworkView from "src/views/authenticated/dashboard/student/DashboardShareNetworkView";
import DashboardEditBasicModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditBasicModal";
import DashboardEditAvatarModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditAvatarModal";
import DashboardEditExperiencesView from "src/views/authenticated/dashboard/resume/edit/DashboardEditExperiencesView";
import DashboardEditExperienceModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditExperienceModal";
import DashboardEditExtraView from "src/views/authenticated/dashboard/resume/edit/DashboardEditExtraView";
// import DashboardResumePreviewView from "src/views/authenticated/dashboard/resume/DashboardResumePreviewView";
// import DashboardReferral from "src/views/Dashboard/DashboardReferral";
import DashboardEditInterestsModal from "src/views/authenticated/dashboard/resume/edit/DashboardEditInterestsModal";
import ProtectedRoute from "src/routes/middlewares/ProtectedRoute";
import OnboardingAddressModal from "src/views/authenticated/dashboard/company/onboarding/OnboardingAddressModal";
import OnboardingInformationModal from "src/views/authenticated/dashboard/company/onboarding/OnboardingInformationModal";
import InviteUserModal from "src/views/authenticated/profile/users/InviteUserModal";
import ResumePublicView from "src/views/authenticated/profile/resume/public/ResumePublicView";
import TaskModal from "src/components/tasks/TaskForm/modals/task-modal/TaskModal";
import TeamFormModal from "src/views/authenticated/profile/teams/TeamFormModal";

const DashboardRoutes = [
	<Route
		element={
			<ProtectedRoute>
				<DashboardView />
			</ProtectedRoute>
		}
		path="/dashboard/:modal?"
		key="/dashboard/:modal?"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardConnectMandateView />
			</ProtectedRoute>
		}
		path="/dashboard/connect/mandate"
		key="/dashboard/connect/mandate"
	/>,
	<Route
		element={
			<ProtectedRoute type="company">
				<DashboardMandateView />
			</ProtectedRoute>
		}
		path="/dashboard/mandate/:mandate"
		key="/dashboard/mandate/:mandate"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardShareNetworkView />
			</ProtectedRoute>
		}
		path="/dashboard/details/share-network"
		key="/dashboard/details/share-network"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditExperiencesView />
			</ProtectedRoute>
		}
		path="/dashboard/resume/experiences"
		key="/dashboard/resume/experiences"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditExtraView />
			</ProtectedRoute>
		}
		path="/dashboard/resume/extra"
		key="/dashboard/resume/extra"
	/>,
	<Route
		element={
			<ProtectedRoute>
				<ResumePublicView />
			</ProtectedRoute>
		}
		path="/dashboard/resume/preview/:id"
		key="/dashboard/resume/preview/:id"
	/>,
];

export const DashboardModalRoutes = [
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditThemeModal />
			</ProtectedRoute>
		}
		path="/dashboard/theme/edit"
		key="/dashboard/theme/edit"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditBasicModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/basic"
		key="/dashboard/resume/basic"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditAvatarModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/avatar"
		key="/dashboard/resume/avatar"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditInterestsModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/interests"
		key="/dashboard/resume/interests"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditExperienceModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/experiences/:type/create"
		key="/dashboard/resume/experiences/:type/create"
	/>,
	<Route
		element={
			<ProtectedRoute type="student">
				<DashboardEditExperienceModal />
			</ProtectedRoute>
		}
		path="/dashboard/resume/experiences/:type/:id/edit"
		key="/dashboard/resume/experiences/:type/:id/edit"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
					// size: "medium",
				}}
			>
				<OnboardingInformationModal />
			</ProtectedRoute>
		}
		path="/dashboard/onboarding/information"
		key="/dashboard/onboarding/information"
	/>,
	<Route
		element={
			<ProtectedRoute variant="on-top">
				<TaskModal defaultVisible backUrl="/dashboard" />
			</ProtectedRoute>
		}
		path="/dashboard/tasks/:id/show"
		key="/dashboard/tasks/:id/show"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<OnboardingAddressModal />
			</ProtectedRoute>
		}
		path="/dashboard/onboarding/address"
		key="/dashboard/onboarding/address"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<TeamFormModal />
			</ProtectedRoute>
		}
		path="/dashboard/onboarding/teams"
		key="/dashboard/onboarding/teams"
	/>,
	<Route
		element={
			<ProtectedRoute
				type="company"
				variant="modal"
				modal={{
					backUrl: "/dashboard",
					expanded: false,
					size: "default",
				}}
			>
				<InviteUserModal />
			</ProtectedRoute>
		}
		path="/dashboard/invite/users"
		key="/dashboard/invite/users"
	/>,
];

export default DashboardRoutes;
