import { FormEvent, useState, useMemo, MouseEvent, useEffect } from "react";
import axios from "axios";
import useGet from "src/hooks/rest/useGet";
import useAlert from "src/hooks/useAlert";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import { useTranslation } from "react-i18next";
import useModal from "src/hooks/useModal";
import useAuth from "src/hooks/selectors/useAuth";
import useResume from "src/hooks/api/services/resume/useResume";

type SocialProvider =
	| "INSTAGRAM"
	| "TWITTER"
	| "YOUTUBE"
	| "DRIBBBLE"
	| "FACEBOOK"
	| "LINKEDIN"
	| "BEHANCE";

interface Social {
	name: SocialProvider;
	icon: string;
	direct_link?: string;
}

const VALID_SOCIALS: Social[] = [
	{
		name: "INSTAGRAM",
		icon: "fab fa-instagram",
	},
	{
		name: "LINKEDIN",
		icon: "fab fa-linkedin-in",
	},
	{
		name: "FACEBOOK",
		icon: "fab fa-facebook-f",
	},
	{
		name: "TWITTER",
		icon: "fab fa-twitter",
	},
	{ name: "YOUTUBE", icon: "fab fa-youtube" },
	{ name: "DRIBBBLE", icon: "fab fa-dribbble" },
	{ name: "BEHANCE", icon: "fab fa-behance" },
];

interface ResumeSocialMediaProps {
	editable?: boolean;
	socials?: Social[];
	query?: string;
	modal?: boolean;
}

const ResumeSocialMedia = ({
	editable,
	socials,
	query,
	modal,
}: ResumeSocialMediaProps) => {
	const { t } = useTranslation("resume", {
		keyPrefix: "resume-social-media",
	});
	const auth = useAuth();
	const [result, fetching, { fetchGet }] = useGet(
		socials ? "" : query || `/resume/${auth.id}/social-media`
	);
	const [selectedSocial, setSelectedSocial] = useState<null | Social>(null);
	const [loading, setLoading] = useState(false);
	const [sendAlert] = useAlert();
	const { data } = result;
	const modaltitle = useModal();
	const { actions } = useResume({
		rememberKey: auth.id,
	});

	useEffect(() => {
		if (modal) {
			modaltitle.setTitle(t("title"));
		}
		return () => {
			if (modal) {
				actions.get(auth.id);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const parsedSocials = useMemo(() => {
		if (socials) {
			return VALID_SOCIALS.map((social) => {
				const apiSocial = socials.find(
					(item: any) => item.platform === social.name
				);

				if (!apiSocial) return false;

				return { ...social, direct_link: apiSocial.direct_link };
			}).filter((item) => item !== false);
		}

		if (!data) return [];

		const parsed = VALID_SOCIALS.map((social) => {
			const apiSocial = data.find(
				(item: any) => item.platform === social.name
			);

			if (apiSocial)
				return { ...social, direct_link: apiSocial.direct_link };

			return social;
		});

		if (!editable) {
			return parsed.filter((item) => item.direct_link);
		}

		return parsed;
	}, [data, socials, editable]);

	const handleDelete = (
		e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
		platform: SocialProvider
	) => {
		if (!editable) return;
		e.stopPropagation();
		setLoading(true);

		axios
			.delete(`/resume/${auth.id}/social-media`, { data: { platform } })
			.catch(() =>
				sendAlert({
					title: t("error-delete.title"),
					text: "",
					type: "error",
				})
			)
			.finally(() => {
				fetchGet();
				setLoading(false);
				setSelectedSocial(null);
			});
	};

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		if (!selectedSocial || !editable) return;

		setLoading(true);

		let link = selectedSocial.direct_link;

		if (!link?.startsWith("http")) {
			link = `https://${link}`;
		}
		axios
			.post(`/resume/${auth.id}/social-media`, {
				platform: selectedSocial.name,
				direct_link: link,
			})
			.then(() => {
				setSelectedSocial(null);
				fetchGet();
			})
			.catch(() =>
				sendAlert({
					title: t("error-save.title"),
					text: t("error-save.text"),
					type: "error",
				})
			)
			.finally(() => setLoading(false));
	};

	const handleClick = (social: Social) => {
		if (editable) return setSelectedSocial(social);
		window.open(social.direct_link);
	};

	return (
		<>
			<div className="flex flex-row flex-wrap gap-4">
				{(parsedSocials || []).map((social: any) => {
					const isSelected = social === selectedSocial;
					const isFilled = social.direct_link ? true : false;
					return (
						<div
							key={social.name}
							className={`flex rounded-md px-4 py-2 items-center border-2 border-transparent dark:border-dark hover:border-primary-200 cursor-pointer justify-between gap-3 ${
								isFilled || isSelected
									? "text-primary dark:border-primary"
									: ""
							} ${isSelected && "border-primary-200"}`}
							onClick={() => handleClick(social)}
						>
							<div className="block min-w-[18px]">
								<i className={social.icon}></i>
							</div>
							<span className="flex-1">
								{social.name.toLowerCase()}
							</span>
							{social.direct_link && editable && (
								<div
									className="delete-social"
									onClick={(e) =>
										handleDelete(e, social.name)
									}
								>
									<i className="fas fa-trash-alt fa-xs"></i>
								</div>
							)}
						</div>
					);
				})}
				{!fetching && (parsedSocials || []).length === 0 && (
					<div className="flex flex-col justify-center flex-1 items-center p-8">
						<strong>{t("empty.title")}</strong>
					</div>
				)}
			</div>

			{selectedSocial && editable && (
				<form
					className="flex items-center gap-4 mt-4 md:flex-row flex-col"
					onSubmit={handleSubmit}
				>
					<Input
						className="mb-0"
						name="resume-social"
						placeholder={t("form.placeholder", {
							name: selectedSocial.name.toLowerCase(),
						})}
						value={selectedSocial.direct_link}
						onChange={(_, value) =>
							setSelectedSocial({
								...selectedSocial,
								direct_link: value,
							})
						}
					/>
					<Button
						className="h-[50px] w-full md:w-auto"
						submit
						loading={loading}
					>
						<i className={`${selectedSocial.icon} mr-2`}></i>{" "}
						{t("form.button")}
					</Button>
				</form>
			)}
		</>
	);
};

export default ResumeSocialMedia;
