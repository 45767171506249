import { ComponentProps, createContext, useContext } from "react";
import Loader from "src/components/Loader";
import Skeleton from "src/components/Skeleton";
import { useList } from "src/components/ui/list/Provider";
import { cn } from "src/lib/utils";

export const RowContext = createContext<{
	variant: "row" | "head" | "footer" | "loading";
	id?: string | number;
}>({
	variant: "row",
	id: undefined,
});

interface ItemsProps extends Omit<ComponentProps<"tbody">, "children"> {
	children: (item: any, index: number) => JSX.Element;
}

const Items = ({ children, className, ...rest }: ItemsProps) => {
	const { data, status, groups, groupBy, columnCount, keepListWhileLoading } =
		useList();
	if (status === "loading" && !(keepListWhileLoading && data.length))
		return null;
	return (
		<tbody key="items" {...rest} className={cn("", className)}>
			{groupBy ? (
				<>
					{groups.map((group, index) => (
						<>
							<tr key={`group-${index}`}>
								<td
									colSpan={columnCount}
									className="col-span-full"
								>
									<div className="flex px-4 py-8">
										<p className="opacity-70">{group}</p>
									</div>
								</td>
							</tr>
							{data
								.filter((item) => groupBy(item) === group)
								.map(children)}
						</>
					))}
				</>
			) : (
				data.map(children)
			)}
		</tbody>
	);
};

interface RowProps extends Omit<ComponentProps<"tr">, "id"> {
	id?: string | number;
}

export const Row = ({ children, className, id, ...rest }: RowProps) => {
	const { selected } = useList();
	return (
		<RowContext.Provider value={{ variant: "row", id }}>
			<tr
				{...rest}
				className={cn(
					"border-t border-border relative transition-all",
					selected.includes(id) && "bg-aqua-light dark:bg-accent",
					className
				)}
			>
				{children}
			</tr>
		</RowContext.Provider>
	);
};

interface ItemProps extends ComponentProps<"td"> {
	sticky?: {
		position: "left" | "right";
	};
}

export const Item = ({ children, className, sticky, ...rest }: ItemProps) => {
	const { variant } = useContext(RowContext);
	const Wrapper = variant === "head" ? "th" : "td";
	return (
		<Wrapper
			align="left"
			{...rest}
			className={cn(
				`px-3 md:px-6 py-3 first-of-type:pl-0 last-of-type:pr-0 relative z-10`,
				sticky?.position && `${sticky.position}-0 sticky px-2`,
				sticky?.position === "right" &&
					"last-of-type:pr-2 bg-gradient-to-l from-background to-transparent",
				className
			)}
		>
			{variant === "head" ? (
				<p className="font-regular uppercase text-[15px] opacity-70">
					{children}
				</p>
			) : (
				children
			)}
		</Wrapper>
	);
};

interface HeadProps extends ComponentProps<"tr"> {}

export const Head = ({ children, className, ...rest }: HeadProps) => {
	const { isEmpty, showEmptyList, status } = useList();
	if (isEmpty && !showEmptyList) return null;
	return (
		<RowContext.Provider value={{ variant: "head" }}>
			<thead>
				<tr
					{...rest}
					className={cn(
						"relative md:whitespace-normal whitespace-nowrap",
						className
					)}
				>
					<>{children}</>
					{!isEmpty && status !== "idle" && (
						<div className="absolute right-0 top-0 bottom-0 flex items-center">
							<Loader />
						</div>
					)}
				</tr>
			</thead>
		</RowContext.Provider>
	);
};

interface LoadingProps extends ComponentProps<"tr"> {
	count?: number;
}

export const Loading = ({
	count = 6,
	children,
	className,
	...rest
}: LoadingProps) => {
	const { status, columnCount, keepListWhileLoading, data } = useList();
	if (status && !["loading", "mounted"].includes(status)) return null;
	if (keepListWhileLoading && data.length) return null;
	return (
		<RowContext.Provider value={{ variant: "loading" }}>
			<tbody>
				{new Array(count).fill("").map((item, index) => (
					<tr
						{...rest}
						key={`loader-${index}`}
						className={cn("", className)}
					>
						{!children && columnCount ? (
							<>
								{new Array(columnCount)
									.fill("")
									.map((item, index) => (
										<Item key={`loader-${index}`}>
											<Skeleton className="h-6" />
										</Item>
									))}
							</>
						) : (
							children
						)}
					</tr>
				))}
			</tbody>
		</RowContext.Provider>
	);
};

interface FilterProps extends ComponentProps<"div"> {}

export const Filter = ({ children, className, ...rest }: FilterProps) => {
	const { columnCount } = useList();
	return (
		<thead>
			<tr>
				<td colSpan={columnCount} className="col-span-full">
					<div
						{...rest}
						className={cn(
							"flex gap-3 flex-col md:flex-row row-span-full",
							className
						)}
					>
						{children}
					</div>
				</td>
			</tr>
		</thead>
	);
};

interface EmptyProps extends ComponentProps<"div"> {}

export const Empty = ({ children, className, ...rest }: EmptyProps) => {
	const { columnCount, isEmpty, status } = useList();
	if (!isEmpty || status === "loading") return null;
	return (
		<tbody>
			<tr>
				<td colSpan={columnCount} className="col-span-full">
					<div
						{...rest}
						className={cn(
							"flex gap-3 p-3 md:p-16 flex-1 flex-col justify-center items-center",
							className
						)}
					>
						{children}
					</div>
				</td>
			</tr>
		</tbody>
	);
};

interface FooterProps extends ComponentProps<"tr"> {}

export const Footer = ({ children, className, ...rest }: FooterProps) => {
	return (
		<tr
			{...rest}
			className={cn(
				"border-t border-border relative transition-all opacity-70",
				className
			)}
		>
			{children}
		</tr>
	);
};

export default Items;
