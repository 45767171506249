import React from "react";
import { useTranslation } from "react-i18next";
import Card from "src/components/Card";
import Layout from "src/components/layout/Layout";
import ResumeSkills from "src/components/resume/modules/ResumeSkills";
import ResumeSocialMedia from "src/components/resume/modules/ResumeSocialMedia";
import ResumeEditBio from "src/components/resume/ResumeEditBio";

const DashboardEditExtraView = () => {
	const { t } = useTranslation("resume-view", {
		keyPrefix: "edit-extra-view",
	});
	return (
		<Layout
			breadcrumb={[
				{
					isHome: true,
					label: t("breadcrumb.my-profile"),
					to: "/dashboard",
				},
				{
					label: t("breadcrumb.extra"),
					to: "/dashboard/resume/extra",
				},
			]}
			sidebar={<></>}
			title={t("breadcrumb.my-profile")}
		>
			<Layout.Header backUrl="/dashboard" title={t("title")} />
			<Card id="bio" title={t("cards.bio")}>
				<div className="mt-4">
					<ResumeEditBio />
				</div>
			</Card>

			<Card id="skills" title={t("cards.skills")}>
				<div className="mt-4">
					<ResumeSkills type="add" />
				</div>
			</Card>
			<Card id="social-media" title={t("cards.socials")}>
				<div className="mt-4">
					<ResumeSocialMedia editable />
				</div>
			</Card>
		</Layout>
	);
};

export default DashboardEditExtraView;
