import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Card from "src/components/Card";
import StudentOnboardingItem from "src/components/Onboarding/Student/StudentOnboardingItem";
import Progress from "src/components/Progress";
import useGet from "src/hooks/rest/useGet";
import useBackUrl from "src/hooks/useBackUrl";

const StudentOnboarding = () => {
	const { t } = useTranslation(["onboarding"]);
	const [{ data: onboarding }, , { fetchGet }] = useGet(
		`/student/onboarding/status`,
		{}
	);
	const { total, current } = onboarding?.progress || { total: 0, current: 0 };
	const backUrl = useBackUrl();
	const location = useLocation();

	useEffect(() => {
		fetchGet(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<Card
			title={t("student.title", {
				current,
				total,
			})}
			className=" "
		>
			<div className="mt-4">
				<Progress {...{ current, total }} />
			</div>
			<div className="mt-4 flex flex-col gap-4">
				<StudentOnboardingItem
					image="/assets/images/emoji/information.png"
					title={t("student.information.title")}
					description={t("student.information.description")}
					isCompleted={onboarding?.status?.basic}
					disabled={onboarding?.status?.basic}
					to="/dashboard/resume/basic"
				/>
				<StudentOnboardingItem
					image="/assets/images/emoji/heart.png"
					title={t("student.interests.title")}
					description={t("student.interests.description")}
					isCompleted={onboarding?.status?.interests}
					to="/dashboard/resume/interests"
				/>
				<StudentOnboardingItem
					image="/assets/images/emoji/camera-with-flash.png"
					title={t("student.avatar.title")}
					description={t("student.avatar.description")}
					isCompleted={onboarding?.status?.avatar}
					to={`/profile/resume/create/general?${backUrl}`}
					linkType="link"
				/>
				<StudentOnboardingItem
					image="/assets/images/emoji/school.png"
					title={t("student.experiences.title")}
					description={t("student.experiences.description")}
					isCompleted={onboarding?.status?.experience}
					to={`/profile/resume/create/education?${backUrl}`}
					linkType="link"
				/>
				<StudentOnboardingItem
					image="/assets/images/emoji/writing-hand.png"
					title={t("student.skills.title")}
					description={t("student.skills.description")}
					isCompleted={onboarding?.status?.skills}
					to={`/profile/resume/create/general?${backUrl}`}
					linkType="link"
				/>
			</div>
		</Card>
	);
};

export default StudentOnboarding;
