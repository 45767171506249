import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Tabs from "src/components/layout/Tabs";
import RegistrationSelectList from "src/components/lists/RegistrationSelectList";
import TaskMatchProgress from "src/components/tasks/TaskMatchProgress";
import useTask from "src/hooks/api/tasks/useTask";
import useWindowSize from "src/hooks/useWindowSize";

const RegistrationsSidebar = () => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "registrations-sidebar",
	});
	const states = {
		all: t("tabs.all"),
		accepted: t("tabs.accepted"),
		open: t("tabs.open"),
		// denied: t("tabs.denied"),
		favorites: t("tabs.favorites"),
	} as any;
	const { id, registration } = useParams() as any;
	const { isTablet } = useWindowSize();
	const { task } = useTask();

	return (
		<>
			{(!isTablet || (!registration && isTablet)) && (
				<div
					className={`w-full sticky-0 flex flex-col flex-1 h-full ${
						!isTablet && "max-w-[400px]"
					}`}
				>
					<div className="flex-1 flex flex-col h-full">
						<TaskMatchProgress
							matched={task?.matches?.count}
							students={task?.amount_of_students}
						/>
						<div className="flex flex-col flex-1 relative mt-4">
							<div className="absolute inset-0 overflow-auto hide-default-scrollbar flex flex-col">
								<Tabs
									options={{
										small: true,
										rememberKey: `registrations-${id}`,
										noBackground: true,
									}}
									tabs={Object.values(states)}
								>
									{(tab) => {
										const state =
											Object.keys(states).find(
												(key) => states[key] === tab
											) || "all";
										return (
											<RegistrationSelectList
												limit={8}
												task={id}
												query={`state=${state}`}
											/>
										);
									}}
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default RegistrationsSidebar;
