import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import Modal from "src/components/Modal";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import TaskModalActions from "src/components/tasks/TaskForm/modals/task-modal/TaskModalActions";
import TaskModalContent from "src/components/tasks/TaskForm/modals/task-modal/TaskModalContent";
import TaskModalHead from "src/components/tasks/TaskForm/modals/task-modal/TaskModalHead";
import TaskModalInfo, {
	Section,
} from "src/components/tasks/TaskForm/modals/task-modal/TaskModalInfo";
import TemplateTaskBanner from "src/components/tasks/TemplateTaskBanner";
import Timer from "src/components/time-tracker/Timer";
import useFillTask from "src/hooks/api/services/tasks/task/useFillTask";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useTaskAutoSave from "src/hooks/api/services/tasks/task/useTaskAutoSave";
import useBoards from "src/hooks/api/services/tasks/useBoards";
import { cn } from "src/lib/utils";

interface TaskModalProps extends Translation {
	defaultVisible?: boolean;
	backUrl?: string;
}

const TaskModal = ({ t, defaultVisible, backUrl }: TaskModalProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { id, board, project } = useParams();
	const { actions, task, status, mode, update } = useTask({
		id,
	});

	const { isForcedBoards: _isForcedBoards } = useBoards();
	const isForcedBoard = mode === "CREATE" && _isForcedBoards;
	const getFillableData = useFillTask();

	useTaskAutoSave({
		id,
		timeout: 3000,
		validate: (update) => {
			return id !== "create";
		},
		manipulateData: async (update) => {
			return update;
		},
	});

	useEffect(() => {
		actions.get();
		if (id === "create") {
			getFillableData().then((fill) => {
				actions.change(fill || {});
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const onClose = () => {
		let root = location.pathname;
		const sp = new URLSearchParams(location.search);

		if (Object.keys(update || {}).length > 0) {
			actions.update(update);
		}

		if (location.state.background?.pathname) {
			navigate(location.state.background.pathname);
			return;
		}

		if (board) {
			navigate(`/tasks/boards/${board}`);
			return;
		}

		if (project) {
			navigate(`/projects/${project}`);
			return;
		}

		if (backUrl) {
			navigate(backUrl);
			return;
		}

		sp.delete("modal");
		sp.delete("status");
		sp.delete("assign_to");
		sp.delete("end_date");

		root = root.replaceAll(/\/create-task$/, "");

		navigate(`${root}${sp ? `?${sp.toString()}` : ""}`);
	};

	const onCreate = async () => {
		if (id !== "create") return null;
		if (!(update.title && (update.board || project || !isForcedBoard))) {
			return null;
		}
		const fill = await getFillableData();

		const result = await actions.create({
			status: "BACKLOG",
			type: "INTERNAL",
			...(fill || {}),
			...update,
		});

		if (!result.hid) return;
		if (!backUrl) return;

		const pathname = location.pathname.replace(/\/$/, "");

		const newPathname = pathname.replace(
			`/modals/tasks/${id}`,
			`/modals/tasks/${result.hid}`
		);
		navigate(newPathname, {
			state: location.state,
		});
	};

	return (
		<Modal
			head="disabled"
			size={isForcedBoard ? "custom" : "large"}
			bodyClassName="p-0 md:p-0"
			className="p-0 md:p-0"
			{...{ onClose }}
			visible={defaultVisible || false}
			loading={
				!!(
					(status === "filling" || status === "loading") &&
					id !== "create"
				)
			}
		>
			{task.type === "TEMPLATE" && <TemplateTaskBanner />}
			<div className="flex justify-between items-start p-4 pt-6 md:pt-8 md:pb-4 md:px-8 sticky top-0 bg-background rounded-t-md z-[20] gap-6">
				<TaskModalHead autoFocus={id === "create"} />
				<TaskModalActions {...{ onClose }} />
			</div>
			<div
				className={cn(
					"flex-1 overflow-auto rounded-md relative",
					!isForcedBoard && "grid grid-cols-[65%,35%]",
					isForcedBoard && "pb-8"
				)}
			>
				{!id && status === "creating" && (
					<div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center">
						<Loader />
					</div>
				)}
				<div className="flex flex-col overflow-auto hide-default-scrollbar flex-1 max-h-full">
					<div className="flex-1 px-4 md:px-8 flex flex-col">
						<TaskModalContent {...{ isForcedBoard, onCreate }} />
					</div>
				</div>
				{!isForcedBoard && (
					<div
						style={{ paddingTop: 0 }}
						className="rounded-md p-4 md:p-8 overflow-hidden hidden md:flex flex-col flex-1"
					>
						<div className="border border-border rounded-md flex flex-col flex-1">
							{task?.id && task.type !== "TEMPLATE" && (
								<div className="p-3">
									<Section title={t("track-time")}>
										<Timer
											size="large"
											data={{
												task_id: task.id,
											}}
										/>
									</Section>
								</div>
							)}
							<div className="flex-1"></div>
							<TaskModalInfo />
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default withTranslation(TaskModal);
