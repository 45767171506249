import { ApiTask } from "src/api/types";
import useAuth from "src/hooks/selectors/useAuth";
import Card from "src/components/Card";
import Chart from "src/components/statistics/Chart";
import TotalCard from "src/components/statistics/TotalCard";
import ApiCall from "src/components/ApiCall";
import moment from "moment";
import NotFound from "src/views/auth/NotFoundView";
import { useTranslation } from "react-i18next";

interface StatisticsPaneProps {
	task: ApiTask;
}

const StatisticsPane = ({ task }: StatisticsPaneProps) => {
	const { t } = useTranslation("tasks", {
		keyPrefix: "statistics-pane",
	});
	const auth = useAuth();
	const isBeforeRelease = moment(task.created_at).isBefore("2022-04-10");

	if (auth.type === "student") {
		return <NotFound />;
	}

	return (
		<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
			{isBeforeRelease && (
				<div className="col-span-3 flex flex-col justify-center items-center">
					<strong>{t("before-release")}</strong>
				</div>
			)}

			<ApiCall query={`/company/tasks/${task.id}/statistics/views`}>
				{(data: any, loading) => (
					<TotalCard
						title={t("views")}
						icon="fas fa-eye"
						background="light"
						value={data?.views || 0}
						{...{ loading }}
					/>
				)}
			</ApiCall>

			{(task?.registration_count || 0) > 0 ? (
				<ApiCall
					query={`/company/tasks/${task.id}/statistics/first-response`}
				>
					{(data: any, loading) => (
						<TotalCard
							title={t("first-registration")}
							icon="fas fa-calendar"
							background="light"
							value={data?.time}
							{...{ loading }}
						/>
					)}
				</ApiCall>
			) : (
				false
			)}
			<TotalCard
				title={t("registrations")}
				icon="fas fa-users"
				background="light"
				value={task.registrations?.count || 0}
			/>

			<ApiCall
				query={`/company/tasks/${task.id}/statistics/charts/views`}
			>
				{(data: any) => (
					<>
						{data && data?.length > 2 && (
							<Card className="md:col-span-3">
								<Chart
									background="light"
									tooltip={{ label: t("views") }}
									{...{ data }}
								/>
							</Card>
						)}
					</>
				)}
			</ApiCall>
		</div>
	);
};

export default StatisticsPane;
