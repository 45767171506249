import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Button from "src/components/Button";
import Onboarding from "src/components/forms/onboarding";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import OnboardingLayout from "src/components/layout/onboarding-layout/OnboardingLayout";
import Text from "src/components/ui/text";
import useOnboarding from "src/hooks/api/services/auth/useOnboarding";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useQueryString from "src/hooks/useQueryString";

const OnboardingView = ({ t }: Translation) => {
	const auth = useAuth();
	const {
		tenant: { modules },
	} = useTenant();
	const { step, actions, defaultStep, onboarding, status } = useOnboarding();
	const qs = useQueryString();

	useEffect(() => {
		actions.current();
		if (qs.reset) {
			actions.setStep(defaultStep);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		(!modules.includes("intern-task") && auth.type === "student") ||
		(auth.type === "company" &&
			!modules.includes("intern-task") &&
			auth?.company)
	) {
		return <Navigate to="/dashboard" />;
	}

	const handleSkip = () => {
		switch (step) {
			case "create-company":
				actions.setStep("company");
				break;
			case "create-teams":
				actions.setStep(
					onboarding.teams.length === 0
						? "invite-users"
						: "team-skills"
				);
				break;
			case "team-skills":
				actions.setStep("invite-users");
				break;
			case "invite-users":
				actions.setStep("create-boards");
				break;
			case "create-boards":
				actions.setStep(
					auth.type === "student" ? "completed" : "completed"
				);
				break;
			case "website":
				actions.setStep("completed");
				break;
			default:
				break;
		}
	};

	return (
		<OnboardingLayout className="justify-center items-center gap-12">
			<OnboardingLayout.Header>
				<Text.Eyebrow className="text-md">
					{t(`${auth.type}.${step}.eyebrow`)}
				</Text.Eyebrow>
				<Text.Title>{t(`${auth.type}.${step}.title`)}</Text.Title>
			</OnboardingLayout.Header>
			<Onboarding className="w-full flex flex-1 flex-col z-2 relative">
				{status !== "loading" && (
					<>
						{step === "create-teams" &&
							modules.includes("intern-task") && (
								<Onboarding.CreateTeams />
							)}
						{step === "team-skills" &&
							modules.includes("intern-task") && (
								<Onboarding.TeamSkills />
							)}
						{step === "invite-users" && <Onboarding.InviteUsers />}
						{step === "create-boards" &&
							modules.includes("intern-task") && (
								<Onboarding.CreateBoards />
							)}

						{step === "website" && <Onboarding.Website />}
						{step === "company" && <Onboarding.Company />}
						{step === "completed" && <Onboarding.Completed />}
					</>
				)}
			</Onboarding>
			<div className="flex flex-col w-full mt-4">
				{!["completed", "company", "create-company"].includes(
					step as any
				) && (
					<Button
						onClick={handleSkip}
						type="transparent"
						className="opacity-50 hover:opacity-100"
					>
						<span className="relative flex items-center">
							{t("skip")}
							<i className="far fa-arrow-right absolute -right-6"></i>
						</span>
					</Button>
				)}
			</div>
		</OnboardingLayout>
	);
};

OnboardingView.locale = {
	nl: {
		continue: "Doorgaan",
		skip: "Overslaan",
		student: {
			"create-boards": {
				eyebrow: "Borden / groepen",
				title: "Onderscheid je taken in verschillende groepen",
			},
			completed: {
				eyebrow: "Ready",
				title: "Je account is klaar om taken beter te managen",
			},
		},
		company: {
			"create-company": {
				eyebrow: "Account",
				title: "Hoe heet je bedrijf?",
			},
			"create-teams": {
				eyebrow: "Teams instellen",
				title: "Welke teams zijn er binnen jouw bedrijf?",
			},
			"team-skills": {
				eyebrow: "Skills toewijzen",
				title: "Welke skills zijn er nodig om te werken binnen deze teams?",
			},
			"invite-users": {
				eyebrow: "Mederwerkers uitnodigen",
				title: "Voeg medewerkers toe aan je team",
			},
			"create-boards": {
				eyebrow: "Borden / omgevingen",
				title: "Maak verschillende borden aan voor je teams",
			},
			website: {
				eyebrow: "Website",
				title: "Mogen we de url van je website?",
			},
			completed: {
				eyebrow: "Ready",
				title: "Je account is klaar om taken beter te managen",
			},
			company: {
				eyebrow: "Account",
				title: "Bedrijfsgegevens",
			},
		},
	},
	en: {
		continue: "Continue",
		skip: "Skip",
		student: {
			"create-boards": {
				eyebrow: "Boards / Groups",
				title: "Distinguish your tasks in different groups",
			},
			completed: {
				eyebrow: "Ready",
				title: "Your account is ready to manage tasks better",
			},
		},
		company: {
			"create-teams": {
				eyebrow: "Setup teams",
				title: "What teams are there within your company?",
			},
			"team-skills": {
				eyebrow: "Add skills",
				title: "Which skills are needed to perform in those teams?",
			},
			"invite-users": {
				eyebrow: "Invite employees",
				title: "Add employees to a team",
			},
			"create-boards": {
				eyebrow: "Boards / enviroments",
				title: "Create different boards for your teams",
			},
			website: {
				eyebrow: "Website",
				title: "Could you give us your website domain?",
			},
			completed: {
				eyebrow: "Ready",
				title: "Your account is ready to start managing tasks",
			},
			company: {
				eyebrow: "Account",
				title: "Company information",
			},
		},
	},
};

export default withTranslation(OnboardingView);
