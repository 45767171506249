import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useMemo } from "react";
import { ApiIcon } from "src/api/types";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useApi from "src/hooks/api/utils/useApi";
import useAuth from "src/hooks/selectors/useAuth";

export type OnboardingUser = {
	id?: number;
	email: string;
	teams?: number[];
};

export type OnboardingBoard = {
	id?: number;
	icon?: ApiIcon;
	name: string;
	team: number;
	type: "MANUAL" | "STATIC";
};

export type OnboardingTeam = {
	name: string;
	icon?: ApiIcon;
	type: "MANUAL" | "DEFAULT";
};

type Update = {
	teams?: any[];
	users?: OnboardingUser[];
	boards?: OnboardingBoard[];
	company?: any;
	information?: {
		website?: string;
		description?: string;
	};
};

export type OnboardingStep =
	| "create-teams"
	| "invite-users"
	| "team-skills"
	| "create-boards"
	| "website"
	| "completed"
	| "company";

const stepAtom = atomFamily(
	({ step }) => atom<OnboardingStep>(step),
	(a: any, b: any) => a.type === b.type
);

function useOnboarding() {
	const auth = useAuth();
	const {
		tenant: { modules },
	} = useTenant();
	const defaultStep = useMemo(() => {
		if (
			modules.includes("extern-task") &&
			!modules.includes("intern-task")
		) {
			return "company";
		}
		return auth.type === "student" ? "create-boards" : "company";
	}, [auth.type, modules]);
	const [step, setStep] = useAtom(
		stepAtom({ type: auth.type, step: defaultStep }) as any
	);
	const { state, actions, api } = useApi(
		{ id: "ONBOARDING" },
		{
			baseUrl: `/client/onboarding`,
			query: {},
		}
	);

	const current = async () => {
		actions.set({
			...state,
			status: "loading",
		});
		const { data } = await api.get("/current");
		actions.set({
			...state,
			status: "idle",
			data: data?.data || {},
		});
	};

	const update = async (update: Update) => {
		actions.set({
			status: "updating",
		});
		try {
			const { data } = await api.patch("/", update);
			actions.set({
				...state,
				status: "idle",
				data: data?.data || {},
			});
		} catch (error: any) {
			actions.set({
				...state,
				status: "idle",
			});
			throw error;
		}
	};

	return {
		onboarding: state.data,
		step,
		status: state.status,
		defaultStep,
		actions: {
			current,
			update,
			setStep,
		},
	};
}

export default useOnboarding;
