import { useState } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/form/Input";
import usePost from "src/hooks/rest/usePost";
import useAlert from "src/hooks/useAlert";
import useQueryString from "src/hooks/useQueryString";
import Switch from "src/components/form/Switch";
import Stars from "src/components/Stars";
import FormGroup from "src/components/form/FormGroup";
import useAuth from "src/hooks/selectors/useAuth";
import { useTranslation } from "react-i18next";

type ReviewFormProps = {
	taskId?: any;
	userId?: any;
	companyId?: any;
	registrationId?: any;
	onDone: (review: any) => void;
	onCancel?: () => void;
	variant?: "cancel";
	theme?: "card" | "flat";
};

const ReviewForm = ({
	taskId,
	userId,
	registrationId,
	companyId,
	onDone,
	onCancel,
	variant,
	theme,
}: ReviewFormProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "review-form",
	});
	const location = useLocation();
	let url = location.search;
	let params = queryString.parse(url);
	const isCancel = variant === "cancel" || params?.cancel;
	const auth = useAuth();
	const [sendAlert] = useAlert();
	const [rating, setRating] = useState(0);
	const [description, setDescription] = useState("");
	const [showProfile, setShowProfile] = useState(false);
	const [createReview, loading] = usePost("/reviews");
	const query = useQueryString();
	const navigate = useNavigate();
	let backUrl = null as any;
	if (query?.back) {
		backUrl = atob(query.back as string);
	}

	const handleSubmit = async () => {
		const onConfirm = () => {
			createReview({
				task_id: taskId,
				user_id: userId,
				company_id: companyId,
				rating: isCancel ? 0 : rating,
				description,
				showProfile,
			}).then((res: any) => {
				if (backUrl) {
					navigate(backUrl);
				} else {
					onDone(res?.data || null);
				}
			});
		};
		if (rating === 0) {
			sendAlert({
				type: "confirm",
				title: t("confirm.title"),
				text: t("confirm.description"),
				onConfirm,
			});
		} else {
			onConfirm();
		}
	};

	if (theme === "flat") {
		return (
			<div className="flex flex-col gap-4">
				{isCancel && <p>{t("cancel.description")}</p>}
				<div className="flex flex-col gap-2">
					{!isCancel && (
						<FormGroup label={t("rating")}>
							<div className="flex flex-col gap-2">
								<Stars
									size="24px"
									inActiveClassName="text-placeholder bg-red"
									rating={rating}
									onClick={setRating}
								/>
								<small>{t("score")}</small>
							</div>
						</FormGroup>
					)}

					{/* {!isCancel && registrationId && auth.type === "company" && (
						<FormGroup label={t("skills.label")}>
							<div className="flex flex-col gap-2">
								<EndorsementForm
									{...{ userId, registrationId }}
								/>
								<small>{t("skills.description")}</small>
							</div>
						</FormGroup>
					)} */}

					<Input
						label={t("description")}
						name="description"
						value={description}
						onChange={(_, value) => setDescription(value)}
						valid={description !== ""}
						multiline
					/>

					{isCancel && (
						<Switch
							label={t("show-switch.label")}
							subLabel={t("show-switch.sub-label")}
							value={showProfile}
							onChange={setShowProfile}
						/>
					)}
				</div>
				<div className="flex justify-between gap-4">
					{onCancel && (
						<Button
							onClick={() => {
								if (backUrl) {
									navigate(backUrl);
									return;
								}
								onCancel();
							}}
							type="gray"
							className={`gtm-${auth.type}-dashboard-review--button-annuleren`}
						>
							{t("buttons.cancel")}
						</Button>
					)}
					<Button
						{...{ loading }}
						disabled={!description}
						onClick={handleSubmit}
						className={`gtm-${auth.type}-dashboard-review--button-opslaan`}
					>
						{t("buttons.save")}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Card title={isCancel ? t("title-cancel") : t("title")}>
			<div className="flex flex-col gap-4">
				{isCancel && <p>{t("cancel.description")}</p>}
				<div className="flex flex-col gap-2">
					{!isCancel && (
						<FormGroup label={t("rating")}>
							<div className="flex flex-col gap-2">
								<Stars
									size="24px"
									inActiveClassName="text-placeholder"
									rating={rating}
									onClick={setRating}
								/>
								<small>{t("score")}</small>
							</div>
						</FormGroup>
					)}

					{/* {!isCancel && registrationId && auth.type === "company" && (
						<FormGroup label={t("skills.label")}>
							<div className="flex flex-col gap-2">
								<EndorsementForm
									{...{ userId, registrationId }}
								/>
								<small>{t("skills.description")}</small>
							</div>
						</FormGroup>
					)} */}

					<Input
						label={t("description")}
						name="description"
						value={description}
						onChange={(_, value) => setDescription(value)}
						valid={description !== ""}
						multiline
					/>

					{isCancel && (
						<Switch
							label={t("show-switch.label")}
							subLabel={t("show-switch.sub-label")}
							value={showProfile}
							onChange={setShowProfile}
						/>
					)}
				</div>
				<div className="flex justify-between gap-4">
					{onCancel && (
						<Button
							onClick={() => {
								if (backUrl) {
									navigate(backUrl);
									return;
								}
								onCancel();
							}}
							type="gray"
							className={`gtm-${auth.type}-dashboard-review--button-annuleren`}
						>
							{t("buttons.cancel")}
						</Button>
					)}
					<Button
						{...{ loading }}
						disabled={!description}
						onClick={handleSubmit}
						className={`gtm-${auth.type}-dashboard-review--button-opslaan`}
					>
						{t("buttons.save")}
					</Button>
				</div>
			</div>
		</Card>
	);
};

export default ReviewForm;
