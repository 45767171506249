import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiRegistration } from "src/api/types";
import Avatar from "src/components/Avatar";
import PaymentStatus from "src/components/payment/PaymentStatus";
import ReadMore from "src/components/ReadMore";
import Signers from "src/components/signer/Signers";
import Stars from "src/components/Stars";
import RegistrationSection from "src/components/tasks/panes/registration/RegistrationSection";
import useTask from "src/hooks/api/services/tasks/task/useTask";
// import useTask from "src/hooks/api/tasks/useTask";
import { formatPrice } from "src/lib/formatters";

interface RegistrationTaskDetailsProps {
	registration: ApiRegistration;
}

const RegistrationTaskDetails = ({
	registration,
}: RegistrationTaskDetailsProps) => {
	const { t } = useTranslation("registrations", {
		keyPrefix: "task-details",
	});
	const { task } = useTask({
		id: registration?.task?.hid,
	});
	const creator = task?.creator || {};
	const company = task?.company || {};
	let date = t("date.between", {
		start: moment(task?.start_date).format("DD MMMM"),
		end: moment(task?.end_date).format("DD MMMM"),
	});

	if (moment(task.start_date).isSame(moment(task.end_date), "day")) {
		date = t("date.at", {
			date: moment(task.start_date).format("DD MMMM"),
		});
	}

	return (
		<>
			<div className="flex lg:flex-row flex-col flex-1 gap-6">
				<RegistrationSection className="flex-1" title={t("title")}>
					<div className="flex flex-col gap-1">
						<strong>Omschrijving</strong>
						<ReadMore text={task.description} length={200} />
					</div>
					<div className="flex flex-col gap-1">
						<strong>{t("address.title")}</strong>
						<p>
							{task?.is_home
								? t("address.home")
								: task?.address?.formatted_address}
						</p>
					</div>
					<div className="flex flex-col gap-1">
						<strong>{t("budget")}</strong>
						<p>
							{formatPrice(
								task.variant === "HOUR_RATE"
									? task.budget_per_hour
									: task.budget
							)}
							{task.variant === "HOUR_RATE" &&
								` ${t("per-hour")}`}
						</p>
					</div>
					<div className="flex flex-col gap-1">
						<strong>{t("date.title")}</strong>
						<p>{date}</p>
					</div>
					{task?.estimated_duration && (
						<div className="flex flex-col gap-1">
							<strong>{t("estimated-duration.title")}</strong>
							<p>
								{t("estimated-duration.hour", {
									hour: Math.floor(task.estimated_duration),
								})}
							</p>
						</div>
					)}
				</RegistrationSection>
				{company?.name && (
					<RegistrationSection
						className="flex-1"
						title={t("client.title")}
					>
						<div className="flex flex-col gap-1">
							<strong>{t("client.company-name")}</strong>
							{company?.website ? (
								<a
									target="_blank"
									className="underline"
									href={company?.website}
									rel="noreferrer"
								>
									{company?.name}
								</a>
							) : (
								<p>{company?.name}</p>
							)}
						</div>
						<div className="flex flex-col gap-1">
							<strong>{t("client.created-by")}</strong>
							<div className="flex items-center gap-2">
								<Avatar
									src={creator?.avatar}
									alt={creator?.full_name}
								/>
								<div className="flex flex-col">
									<p>{creator?.full_name}</p>
									{creator?.company && (
										<small className="opacity-60">
											{creator?.company?.name}
										</small>
									)}
								</div>
							</div>
						</div>
						{task?.company?.review > 0 && (
							<div className="flex flex-col gap-1">
								<strong>{t("client.reviews")}</strong>
								<Stars
									rating={task?.company?.review}
									inActiveClassName="text-white"
									size="20px"
								/>
							</div>
						)}
					</RegistrationSection>
				)}
			</div>
			{(registration?.envelope || registration?.payment) && (
				<div className="flex lg:flex-row flex-col flex-1 gap-6 mt-6">
					{registration?.envelope && (
						<RegistrationSection
							className="flex-1"
							title={t("envelope.title")}
						>
							<Signers
								id={registration?.envelope?.id}
								hid={registration?.envelope?.hid}
								variant="white"
								showButton
								isUpdateViewed={false}
							/>
						</RegistrationSection>
					)}
					{registration?.payment && task.variant !== "HOUR_RATE" && (
						<RegistrationSection
							className="flex-1"
							title={t("payment.title")}
						>
							<PaymentStatus
								className="flex-1"
								variant="details"
								payment={registration?.payment}
								{...{ registration }}
							/>
						</RegistrationSection>
					)}
				</div>
			)}
		</>
	);
};

export default RegistrationTaskDetails;
