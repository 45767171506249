import { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import moment from "moment";
import {
	ApiSubscriptionPlan,
	ApiPaymentMethods,
	ApiCouponCode,
} from "src/api/types";
import Button from "src/components/Button";
import MandateMethods from "src/components/checkout/MandateMethods";
import usePost from "src/hooks/rest/usePost";
import useAuth from "src/hooks/selectors/useAuth";
import useAlert from "src/hooks/useAlert";
import ManualCheckoutSummary, {
	Product,
} from "src/components/checkout/ManualCheckoutSummary";
import useWindowSize from "src/hooks/useWindowSize";
import { Trans, useTranslation } from "react-i18next";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import usePlans from "src/hooks/api/usePlans";
import useNavigateModal from "src/hooks/useNavigateModal";
import useUsers from "src/hooks/api/services/users/useUsers";
import { Switch } from "src/components/ui/switch";
import { formatPrice } from "src/lib/formatters";
import SubscriptionIntervalSwitch from "src/components/subscription/SubscriptionIntervalSwitch";
import CouponCodeChecker from "src/components/checkout/CouponCodeChecker";

interface SubscriptionPlanSelectedProps {
	plan: ApiSubscriptionPlan;
	onCancel: () => void;
	showIntervalSwitch?: boolean;
}

const SubscriptionPlanSelected = ({
	plan,
	onCancel,
	showIntervalSwitch,
}: SubscriptionPlanSelectedProps) => {
	const { t } = useTranslation("subscription", {
		keyPrefix: "subscription-plan-selected",
	});
	const [couponCode, setCouponCode] = useState<ApiCouponCode | null>(null);

	const { tenant } = useTenant();
	const { interval } = usePlans();
	const navigateModal = useNavigateModal();
	const [subscribeToPlan] = usePost(
		`/auth/subscriptions/plans/${plan.id}/subscribe`
	);
	const [posting, setPosting] = useState(false);
	const [method, setMethod] = useState({
		type: "ideal",
		issuer: "",
	}) as any;
	const [sendAlert] = useAlert();
	const auth = useAuth();
	const size = useWindowSize();
	const { users, actions: usersActions, status: usersStatus } = useUsers();
	const activeUsers =
		tenant.providers.payments === "STRIPE"
			? users.filter((user) => user.status === "ACTIVE")?.length
			: null;

	const promos = auth?.company?.promos || [];
	const canUseTrial = auth?.company?.statuses?.can_use_trial;
	const hasTrial =
		canUseTrial &&
		!promos.includes("euro_subscription_deal") &&
		!promos.includes("blackfriday") &&
		!plan.promos.includes("blackfriday");
	const trialDays = 14;
	const partnerFee = tenant.providers.payments === "OPP" ? 1 : 0;
	const vat = tenant.providers.payments === "STRIPE" ? 0 : 0.21;

	const addons = useMemo(() => {
		const items = plan.addons.map((addon) => {
			const active = addon.prices.find(
				(item) => item.interval === interval
			);
			return {
				name: addon.name,
				slug: addon.slug,
				price: active?.price,
			};
		});

		if (auth.type === "student") {
			return items.filter((item) => item.slug !== "analytics");
		}
		return items;
	}, [plan, interval, auth]);
	const [activeAddons, setActiveAddons] = useState<string[]>([]);

	const getPlanPrice = () => {
		if (tenant.providers.payments === "STRIPE") {
			return interval === "MONTHLY" ? plan.price : plan.price_per_year;
		}
		return interval === "MONTHLY" ? plan.price : plan.price_per_year;
	};
	const products: Product[] = [
		{
			description: `${tenant.name}, ${plan.name}`,
			price: getPlanPrice(),
			amount: activeUsers,
			vat,
		},
		...activeAddons.map((slug) => {
			const item = addons.find((item) => item.slug === slug);
			return {
				description: `Module: ${item?.name || ""}`,
				price: item?.price || 0,
				amount: activeUsers,
				vat,
			};
		}),
		...(partnerFee > 0
			? [
					{
						description: "PARTNER_FEE",
						price: partnerFee,
						vat,
					},
			  ]
			: []),
	];

	if (promos.includes("euro_subscription_deal")) {
		products.push({
			description: t("black-friday-sale"),
			price: -plan.price - 0.01,
			vat,
		});
	}

	if (couponCode?.id && couponCode.interval === interval) {
		const currentPrice = getPlanPrice();
		if (couponCode.price !== null) {
			products.push({
				description: t("discount"),
				price: couponCode.price - currentPrice,
				vat,
			});
		}

		if (couponCode.percent) {
			products.push({
				description: t("discount"),
				price: -(currentPrice * (couponCode.percent / 100)),
				vat,
			});
		}
	}

	useEffect(() => {
		setCouponCode(null);
	}, [interval]);

	useEffect(() => {
		usersActions.list();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTrialDisclaimer = () => {
		if (hasTrial) {
			return (
				<Trans i18nKey="trial-disclaimer-fee-later" {...{ t }}>
					Dit totale bedrag wordt pas op
					<b className="text-primary">
						{{
							date: moment()
								.add(trialDays, "days")
								.format("DD-MM-YYYY"),
						}}
					</b>
					afgeschreven
				</Trans>
			);
		}

		// return t("trial-disclaimer-fee-direct");
		return null;
	};

	const handleError = () => {
		sendAlert({
			type: "error",
			title: t("error.title"),
			text: t("error.text"),
		});
		setPosting(false);
	};

	const handleSubmit = (method: ApiPaymentMethods, issuer?: string) => {
		const onboarding = auth?.company?.onboarding?.missing || [];

		if (
			onboarding.includes("address") &&
			tenant.providers.payments !== "STRIPE"
		) {
			navigateModal("/subscription/address");
			return;
		}

		const addons = plan.addons
			.filter((item) => activeAddons.includes(item.slug))
			.map((item) => {
				return {
					price_id: item.prices.find(
						(price) => price.interval === interval
					)?.id,
				};
			});

		setPosting(true);
		subscribeToPlan({
			payment_method: method,
			redirect_url: `/subscription/${plan.slug}/thanks`,
			issuer,
			interval,
			addons,
			coupon_code_id:
				couponCode?.id && couponCode.interval === interval
					? couponCode?.id
					: null,
		})
			.then(({ data }) => {
				const url = data?.redirect_url;
				if (url) {
					window.location = url;
				} else {
					handleError();
				}
			})
			.catch(handleError);
	};

	return (
		<motion.div
			animate={{
				opacity: 1,
				x: 0,
				transition: {
					delay: 0.2,
					type: "timing",
				},
			}}
			initial={{
				opacity: 0,
				x: -200,
			}}
			className="rounded-md md:rounded-l-none md:mt-12 md:mb-4 flex flex-col gap-8 bg-background dark:bg-accent text-background-foreground dark:text-accent-foreground border border-border relative p-6 md:px-10 md:p-8"
		>
			{!posting && usersStatus !== "loading" ? (
				<>
					<div className="flex-1 flex flex-col gap-6">
						{tenant.slug !== "getjobsdone" && (
							<div>
								<strong
									onClick={() => onCancel()}
									className="cursor-pointer "
								>
									<i className="fas fa-arrow-left mr-2"></i>
								</strong>
							</div>
						)}
						{showIntervalSwitch && (
							<SubscriptionIntervalSwitch variant="switch" />
						)}
						{addons?.length > 0 && (
							<div className="flex flex-col gap-2">
								<strong>{t("addons.title")}</strong>
								<div className="flex flex-col">
									{addons.map((addon: any) => (
										<div
											key={`${interval}-${addon.slug}`}
											className="flex items-center gap-2"
										>
											<div className="flex-1">
												{addon.name}
											</div>
											<div className="flex gap-2 items-center">
												<Switch
													checked={activeAddons.includes(
														addon.slug
													)}
													onClick={() => {
														if (
															activeAddons.includes(
																addon.slug
															)
														) {
															setActiveAddons(
																activeAddons.filter(
																	(item) =>
																		item !==
																		addon.slug
																)
															);
														} else {
															setActiveAddons([
																...activeAddons,
																addon.slug,
															]);
														}
													}}
												/>
												<span>
													{formatPrice(addon.price)}
												</span>
											</div>
										</div>
									))}
								</div>
							</div>
						)}

						<div className="flex flex-col gap-2">
							<strong>{t("summary.title")}</strong>
							<ManualCheckoutSummary
								{...{ products }}
								summary={{
									vat_mode:
										tenant.providers.payments === "STRIPE"
											? "checkout"
											: undefined,
									// partner_fee: partnerFee,
								}}
							/>
						</div>
						{tenant.providers.payments === "OPP" && (
							<CouponCodeChecker
								{...{ couponCode }}
								onChange={setCouponCode}
								plan={{
									id: plan.id,
									interval: interval,
								}}
							/>
						)}

						{tenant.providers.payments === "OPP" && (
							<p>{getTrialDisclaimer()}</p>
						)}

						{!auth?.company?.statuses.has_active_mandate &&
							tenant.providers.payments === "OPP" && (
								<MandateMethods
									idealOnly
									value={method}
									onClick={(type, issuer) =>
										setMethod({ type, issuer })
									}
								/>
							)}
					</div>

					{size.isPhone && <div className="h-12"></div>}

					<div
						className={
							size.isPhone
								? "fixed left-4 bottom-0 right-4 p-3 flex justify-center items-center"
								: ""
						}
					>
						{auth?.company?.statuses.has_active_mandate ||
						tenant.providers.payments === "STRIPE" ? (
							<Button
								className="w-full flex-1"
								type="dark"
								onClick={() => handleSubmit("ideal")}
							>
								<i className="fas fa-check mr-4"></i>
								{t("button.confirm")}
							</Button>
						) : (
							<Button
								className="w-full flex-1"
								type="dark"
								disabled={!method.type || !method.issuer}
								onClick={() =>
									handleSubmit(method.type, method.issuer)
								}
							>
								{hasTrial ? (
									<>
										{!size.isPhone && (
											<i className="fas fa-check mr-4"></i>
										)}
										{t("button.start-trial")}
									</>
								) : (
									<>
										{!size.isPhone && (
											<i className="fas fa-check mr-4"></i>
										)}
										{t("button.confirm")}
									</>
								)}
							</Button>
						)}
					</div>
				</>
			) : (
				<div className="flex-1 flex justify-center items-center md:my-12">
					<span className="base-loader"></span>
				</div>
			)}
		</motion.div>
	);
};

export default SubscriptionPlanSelected;
