import Button from "src/components/Button";
import AgreeTermsCheckbox from "src/components/form/AgreeTermsCheckbox";
import Input from "src/components/form/Input";
import Choose from "src/components/forms/auth/signup/Choose";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useCompanyInfoSearch from "src/hooks/api/services/auth/useCompanyInfo";
import useSignup from "src/hooks/api/services/auth/useSignup";
import { useUpdateEffectDebounce } from "src/hooks/useDebounce";

interface CompanyMinimalFormProps extends Translation {
	hide?: string[];
}

const CompanyMinimalForm = ({ t, hide }: CompanyMinimalFormProps) => {
	const { actions, form, validate, status } = useSignup({
		type: "company-minimal",
		defaultValue: {},
	});
	const companyInfo = useCompanyInfoSearch();
	useUpdateEffectDebounce(form?.company_name, 1000, (q) => {
		// if (handleSearch && !fetching) {
		if (companyInfo.status === "idle") {
			companyInfo.actions.search(q);
		}
		// }
	});

	const getFormField = (name: string) => {
		return {
			...actions.getField(name),
			noMarginBottom: true,
			placeholder: t(`fields.${name}.placeholder`),
		};
	};

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!(validate.isValid && form.agreed)) return;
		actions.signup({});
	};

	return (
		<form className="flex flex-col gap-4" {...{ onSubmit }}>
			<div className="flex gap-2">
				<Input {...getFormField("first_name")} />
				<Input {...getFormField("last_name")} />
			</div>
			{!(hide || []).includes("email") && (
				<Input {...getFormField("email")} type="email" />
			)}
			<Input optional type="telephone" {...getFormField("telephone")} />

			{!(hide || []).includes("company_name") && (
				<>
					<Input
						{...getFormField("company_name")}
						type="company_name"
					/>
					{form.company_name &&
						(companyInfo?.searches || []).length > 0 && (
							<div className="flex flex-col max-h-40 overflow-auto hide-default-scrollbar">
								<Choose>
									{companyInfo.searches.map((item) => (
										<Choose.Item
											key={item.id}
											active={item.id === form.kvk_id}
											title={
												item.handelsnaam || item?.text
											}
											description={item?.dossiernummer}
											onClick={() =>
												actions.setForm(
													item.id === form.kvk_id
														? {
																kvk: undefined,
																kvk_id: undefined,
														  }
														: {
																kvk: item.dossiernummer,
																kvk_id: item.id,
														  }
												)
											}
										/>
									))}
								</Choose>
							</div>
						)}
				</>
			)}
			<Input {...getFormField("password")} type="password" />
			<AgreeTermsCheckbox
				className="my-2"
				value={form.agreed}
				onChange={() =>
					actions.setForm({
						agreed: form.agreed ? false : true,
					})
				}
			/>

			<Button
				loading={status !== "idle"}
				disabled={!(validate.isValid && form.agreed)}
				submit
			>
				{t("buttons.signup")}
			</Button>
		</form>
	);
};

CompanyMinimalForm.locale = {
	nl: {
		fields: {
			first_name: {
				placeholder: "Voornaam",
			},
			last_name: {
				placeholder: "Achternaam",
			},
			email: {
				placeholder: "Email",
			},
			job_title: {
				placeholder: "Functie",
			},
			telephone: {
				placeholder: "Telefoonnummer (optioneel)",
			},
			company_name: {
				placeholder: "Bedrijfsnaam",
			},
			password: {
				placeholder: "Wachtwoord",
			},
		},
		errors: {
			"email-in-use": "Emailadres is al in gebruik",
		},
		buttons: {
			signup: "Aanmelden",
		},
	},
	en: {
		fields: {
			first_name: {
				placeholder: "Firstname",
			},
			last_name: {
				placeholder: "Lastname",
			},
			email: {
				placeholder: "Email",
			},
			job_title: {
				placeholder: "Job title",
			},
			telephone: {
				placeholder: "Telephone",
			},
			password: {
				placeholder: "Password",
			},
			company_name: {
				placeholder: "Company name",
			},
		},
		errors: {
			"email-in-use": "Email is already in use",
		},
		buttons: {
			signup: "Signup",
		},
	},
};

export default withTranslation(CompanyMinimalForm);
