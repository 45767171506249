import { motion } from "framer-motion";
import React, { ComponentProps } from "react";
import Logo from "src/components/Logo";
import { cn } from "src/lib/utils";

interface OnboardingLayoutProps extends ComponentProps<"div"> {}

const OnboardingLayout = ({
	className,
	children,
	...rest
}: OnboardingLayoutProps) => {
	return (
		<div
			{...rest}
			className={cn(
				"dark flex flex-col flex-1 bg-background text-background-foreground py-24",
				className
			)}
		>
			<div className="flex flex-col max-w-[720px] w-full flex-1 relative z-[1] px-4">
				{children}
			</div>
			<motion.div
				initial={{ opacity: 0, scale: 0.8 }}
				animate={{
					opacity: 1,
					scale: 1,
					transition: {
						delay: 0.5,
					},
				}}
				className="w-[300px] md:w-[32vw] absolute right-0 -bottom-0 overflow-hidden z-[0]"
			>
				<Logo
					variant="small-outline"
					color="white"
					className="-mb-[6vw] -mr-[2vw] opacity-5"
				/>
			</motion.div>
		</div>
	);
};

const Header = ({ className, children, ...rest }: ComponentProps<"div">) => {
	return (
		<header
			{...rest}
			className={cn(
				"flex flex-col gap-3 text-center mx-auto mb-6",
				className
			)}
		>
			{children}
		</header>
	);
};

OnboardingLayout.Header = Header;

export default OnboardingLayout;
