import React, { ComponentProps } from "react";
import useResumeCardContext from "src/components/ResumeCompanyUser/resume-card/ResumeCardContext";
import { cn } from "src/lib/utils";
import Text from "src/components/ui/text";

interface ResumeSectionTitle extends ComponentProps<"h3"> {}

const ResumeEditButton = ({
	className,
	children,
	...rest
}: ResumeSectionTitle) => {
	const { options } = useResumeCardContext();

	if (options?.title?.variant === "uppercase") {
		return (
			<Text.Eyebrow {...rest} className={cn(className)}>
				{children}
			</Text.Eyebrow>
		);
	}

	return (
		<h3 {...rest} className={cn(className)}>
			{children}
		</h3>
	);
};

export default ResumeEditButton;
