import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiCategory } from "src/api/types";
import Button from "src/components/Button";
import Input from "src/components/form/Input";
import Skeleton from "src/components/Skeleton";
import Tag from "src/components/Tag";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useEditProfile from "src/hooks/api/useEditProfile";
import useGet from "src/hooks/rest/useGet";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

interface EditInterestsProps {
	onDone?: () => void;
	className?: string;
	renderButtons: (options?: {
		isSubmitting?: boolean;
		isValid?: boolean;
		onSubmit?: () => void;
	}) => any;
}

type Interest = {
	id?: number;
	name: string;
};

const EditInterests = ({
	renderButtons,
	className,
	onDone,
}: EditInterestsProps) => {
	const { t } = useTranslation("forms", {
		keyPrefix: "edit-interests",
	});
	const { tenant } = useTenant();
	const [{ data }, loading] = useGet(
		`/categories?variant=interests${
			tenant.slug === "getjobsdone" ? "&type=professional" : ""
		}`
	);
	const auth = useAuth();
	const [interests, setInterests] = useState(
		(auth?.interests || []).map((item: ApiCategory) => ({
			id: item?.id,
			name: item.name,
		})) as Interest[]
	);
	const [customTerm, setCustomTerm] = useState("");
	const [editProfile, updating] = useEditProfile();

	const handleSubmit = () => {
		editProfile({ interests }).then(() => {
			if (onDone) {
				onDone();
			}
		});
	};

	const handleCustomTermSubmit = (e?: any) => {
		if (e) e.preventDefault();

		const interest = {
			name: customTerm,
		};
		//Check if the interest is already added
		if (isInterestInArray(interest)) {
			setCustomTerm("");
			return;
		}

		setInterests((interests) => [...interests, interest]);
		setCustomTerm("");
	};

	const compareIfIsInterest = (a: Interest, b: Interest) => {
		return (
			(a.id && a.id === b.id) ||
			a?.name?.toLowerCase() === b?.name?.toLowerCase()
		);
	};

	const isInterestInArray = (interest: Interest) => {
		return interests.some((i: Interest) =>
			compareIfIsInterest(i, interest)
		);
	};

	const toggle = (interest: Interest) => {
		if (isInterestInArray(interest)) {
			setInterests([
				...interests.filter((i) => !compareIfIsInterest(i, interest)),
			]);
		} else {
			setInterests([...interests, interest]);
		}
	};

	const getAllInterests = (): any[] => {
		const isNotAdded = interests.filter((i) => !i.id);
		return [...(data || []), ...isNotAdded];
	};

	return (
		<>
			<div className={`flex-1 ${className}`}>
				{loading ? (
					<EditInterests.Loader />
				) : (
					<div className={`flex gap-2 flex-wrap`}>
						{getAllInterests().map((item: Interest) => (
							<Tag
								onClick={() => toggle(item)}
								// theme={
								// 	isInterestInArray(item) ? "primary" : "gray"
								// }
								key={`${item.id}-tag`}
								className={cn(
									"font-regular bg-accent py-2",
									isInterestInArray(item) &&
										"bg-primary text-primary-foreground"
								)}
								options={{
									closeable: isInterestInArray(item),
								}}
							>
								{item.name}
							</Tag>
						))}
					</div>
				)}
			</div>
			<form onSubmit={handleCustomTermSubmit} className="flex gap-2 mt-8">
				<div className="flex-1">
					<Input
						name="interest"
						onChange={(_, value) => setCustomTerm(value)}
						value={customTerm}
						placeholder={t("placeholder")}
						autoFill="interests"
					/>
				</div>
				<Button
					submit
					className="p-0 h-[42px] w-[42px] block"
					type="primary"
					disabled={!customTerm}
				>
					<i className="fas fa-arrow-right"></i>
				</Button>
			</form>
			{renderButtons({
				isSubmitting: updating,
				isValid: interests.length > 0,
				onSubmit: handleSubmit,
			})}
		</>
	);
};

EditInterests.Loader = () => {
	const widths = ["72px", "120px", "90px"];

	const getRandomWidth = () => {
		return widths[Math.floor(Math.random() * (widths.length - 1))];
	};

	return (
		<div className="flex gap-2 flex-wrap">
			{new Array(14).fill("").map((_, index) => (
				<Skeleton
					key={`skeleton-${index}`}
					className={`h-[28px]`}
					style={{ width: getRandomWidth() }}
				/>
			))}
		</div>
	);
};

export default EditInterests;
