import { Helmet } from "react-helmet";
import useTenant from "src/hooks/api/services/tenants/useTenant";

const TenantManifest = () => {
	const { tenant } = useTenant();

	return (
		<Helmet>
			<link
				rel="manifest"
				href={process.env.PUBLIC_URL + `/manifest/${tenant.slug}.json`}
			/>
		</Helmet>
	);
};

export default TenantManifest;
