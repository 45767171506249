import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ComplianceItem from "./ComplianceItem";

const Onboarding = () => {
	const auth = useSelector((state: any) => state.auth);
	const { t } = useTranslation("onboarding", {
		keyPrefix: "student.compliance",
	});

	const { status } = auth;
	const compliance = status?.compliance || {};
	const requirements = (compliance?.requirements || []).filter(
		(item: any) => item.object_redirect_url
	);

	if (
		["unverified", "pending"].includes(compliance?.status) &&
		requirements?.length
	) {
		return (
			<div className="flex flex-col gap-4">
				{requirements.map((item: any) => (
					<ComplianceItem key={item.type} {...{ ...item }} />
				))}

				{compliance?.overview_url && (
					<div>
						<a
							className="underline"
							target="_blank"
							href={compliance.overview_url}
							rel="noreferrer"
						>
							{t("cta")}
						</a>
					</div>
				)}
			</div>
		);
	}

	return <></>;
};

export default Onboarding;
