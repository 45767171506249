import { ComponentProps, useState } from "react";
import { ApiVideoPitch } from "src/api/types";
import Loader from "src/components/Loader";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useElementSize from "src/hooks/useElementSize";
import { cn } from "src/lib/utils";

interface VideoPitchPlayerProps extends ComponentProps<"div">, Translation {
	videoPitch: ApiVideoPitch;
}

const VideoPitchPlayer = ({
	t,
	videoPitch,
	className,
	...rest
}: VideoPitchPlayerProps) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const { ref, size } = useElementSize();
	const IS_PROCESSING = ["PROCESSING", "UPLOADING"].includes(
		videoPitch.status || ""
	);
	return (
		<div
			{...rest}
			{...{ ref }}
			className={cn("overflow-hidden rounded-md", className)}
		>
			{IS_PROCESSING && (
				<div className="flex flex-col">
					<div
						className={cn(
							"flex flex-col items-center justify-center px-4 py-12 text-center relative z-20",
							videoPitch?.thumbnail && "absolute inset-0",
							!videoPitch?.thumbnail &&
								"border border-border rounded-md"
						)}
					>
						<Loader className="mb-3" />
						<strong>{t("processing.title")}</strong>
						<p>{t("processing.description")}</p>
					</div>
					{videoPitch?.thumbnail && (
						<img
							src={videoPitch?.thumbnail?.url}
							className="w-full transition-all"
							alt=""
						/>
					)}
				</div>
			)}
			{!isPlaying && (
				<div
					onClick={() => setIsPlaying(true)}
					className="flex relative justify-center items-center overflow-hidden rounded-lg cursor-pointer group"
				>
					<div className="absolute z-10 flex justify-center items-center">
						<i className="fas fa-play-circle absolute text-[64px] text-card group-hover:shadow transition-all"></i>
					</div>
					<img
						src={videoPitch?.thumbnail?.url}
						className="w-full group-hover:scale-[1.4] transition-all"
						alt=""
					/>
				</div>
			)}
			{isPlaying && (
				<video
					style={{ width: size.width, height: size.height }}
					controls
					autoPlay
					src={videoPitch?.video?.url}
				></video>
			)}
		</div>
	);
};

VideoPitchPlayer.locale = {
	nl: {
		processing: {
			title: "Video wordt verwerkt",
			description:
				"Dit duurt meestal enkele minuten, je hoeft niet te wachten.",
		},
	},
};

export default withTranslation(VideoPitchPlayer);
