import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ComponentProps, useState } from "react";
import { ApiFormFieldFillableBy, ApiFormFieldType } from "src/api/types";
import Button from "src/components/Button";
import { CustomFormType } from "src/components/custom-form/CustomFormEditForm";
import Field from "src/components/field/Field";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import { cn } from "src/lib/utils";

export type CustomFormEditFieldValue = {
	_id?: string;
	id?: string;
	type: ApiFormFieldType;
	label: string;
	placeholder: string;
	options: any;
	order: number;
	identifier?: string;
	fillable_by: ApiFormFieldFillableBy;
	settings?: any;
};

interface CustomFormEditFieldProps extends Translation {
	value: CustomFormEditFieldValue;
	onChange: (value: CustomFormEditFieldValue) => void;
	onDelete: () => void;
	type?: CustomFormType;
}

const CustomFormEditField = ({
	t,
	className,
	value,
	onChange,
	onDelete,
	type = "PAYMENT_REQUEST",
	...rest
}: CustomFormEditFieldProps &
	Omit<ComponentProps<"div">, "value" | "onChange">) => {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
	} = useSortable({ id: value.id || value._id || "" });
	const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
	const getFormField = (name: keyof CustomFormEditFieldValue) =>
		({
			name,
			value: value[name],
			onChange: (update: any) => onChange({ ...value, [name]: update }),
			wrapper: {
				layout: "horizontal-always",
				label: t(`fields.${name}.label`),
				className: "w-full",
			},
			className: "border-border min-w-0 w-full",
			placeholder: t(`fields.${name}.placeholder`),
		} as any);

	const getOptionLabel = (type: ApiFormFieldType) => {
		const icons = {
			TEXT: "fa-font",
			NUMBER: "fa-hashtag",
			PRICE: "fa-euro-sign",
			FILE: "fa-file",
			DATE: "fa-calendar",
			DATE_RANGE: "fa-calendar-alt",
			DATE_START_END_TIME: "fa-calendar-alt",
			BOOLEAN: "fa-check-square",
			OPTIONS: "fa-list-ul",
		};
		return (
			<div className="flex items-center gap-2">
				<i className={cn("far text-[13px]", icons[type])}></i>
				<span>{t(`fields.type.options.${type}`)}</span>
			</div>
		);
	};

	const hasIdentifier =
		value.type &&
		["DATE_START_END_TIME"].includes(value?.type) &&
		type === "PAYMENT_REQUEST";
	const hasSettings = hasIdentifier || value?.type === "TEXT";

	const TYPE_OPTIONS = [
		{ value: "TEXT", label: getOptionLabel("TEXT") },
		{
			value: "NUMBER",
			label: getOptionLabel("NUMBER"),
		},
		{
			value: "PRICE",
			label: getOptionLabel("PRICE"),
		},
		{
			value: "FILE",
			label: getOptionLabel("FILE"),
		},
		{
			value: "DATE",
			label: getOptionLabel("DATE"),
		},
		{
			value: "DATE_START_END_TIME",
			label: getOptionLabel("DATE_START_END_TIME"),
		},
		{
			value: "BOOLEAN",
			label: getOptionLabel("BOOLEAN"),
		},
		{
			value: "OPTIONS",
			label: getOptionLabel("OPTIONS"),
		},
	];
	const FILLABLE_BY_OPTIONS = [
		{
			value: "ALL",
			label: t("fields.fillable_by.options.ALL"),
		},
		{
			value: "COMPANY",
			label: t("fields.fillable_by.options.COMPANY"),
		},
	];

	return (
		<div
			{...rest}
			ref={setNodeRef}
			style={{
				opacity: isDragging ? 0.4 : undefined,
				transform: CSS.Translate.toString(transform),
				transition,
			}}
			className={cn(
				"border border-border rounded-md flex flex-col gap-3  p-4",
				className
			)}
		>
			<div className="flex justify-between">
				<div {...attributes} {...listeners} ref={setActivatorNodeRef}>
					<Button xsmall type="ghost">
						<i className="far fa-sort"></i>
					</Button>
				</div>
				<Button xsmall type="ghost" onClick={onDelete}>
					{t("buttons.remove")}
				</Button>
			</div>

			<Field.Select {...getFormField("type")} options={TYPE_OPTIONS} />
			<Field.Input {...getFormField("label")} />

			{value.type === "OPTIONS" && (
				<Field.Input {...getFormField("options")} />
			)}

			<Field.Select
				{...getFormField("fillable_by")}
				options={FILLABLE_BY_OPTIONS}
			/>

			{/* <Field.Input {...getFormField("placeholder")} /> */}
			{value.type === "PRICE" && type === "PAYMENT_REQUEST" && (
				<p className="opacity-70 mt-2 text-[16px]">
					{t("descriptions.PRICE")}
				</p>
			)}

			{hasSettings && (
				<div className="flex flex-col">
					<div
						onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
						className={cn(
							"inline-flex w-max items-center text-border transition-all gap-2 cursor-pointer select-none hover:text-foreground",
							isAdvancedOpen && "text-foreground"
						)}
					>
						<div className="w-2">
							<i
								className={cn(
									"fas",
									isAdvancedOpen
										? "fa-caret-down"
										: "fa-caret-right"
								)}
							></i>
						</div>
						<span>{t("advanced.label")}</span>
					</div>

					{isAdvancedOpen && (
						<div className="flex flex-col gap-2 pt-2">
							{/* {value.type === "NUMBER" && (
								<Field.Switch
									{...getFormField("identifier")}
									label={t("fields.identifier.price")}
									value={value.identifier === "price"}
									onChange={(val) =>
										onChange({
											...value,
											identifier:
												value.identifier !== "price"
													? "price"
													: undefined,
										})
									}
									wrapper={{
										layout: "vertical",
										label: t(`fields.identifier.price`),
										className: "w-full",
									}}
								/>
							)} */}
							{value.type === "TEXT" && (
								<Field.Switch
									value={!!value.settings?.is_multiline}
									onChange={(val) =>
										onChange({
											...value,
											settings: {
												...(value?.settings || {}),
												is_multiline: val,
											},
										})
									}
									wrapper={{
										layout: "vertical",
										label: t(
											`fields.settings.is_multiline.label`
										),
										className: "w-full",
									}}
								/>
							)}
							{value.type === "DATE_START_END_TIME" && (
								<Field.Switch
									{...getFormField("identifier")}
									label={t("fields.identifier.date_hours")}
									value={value.identifier === "date_hours"}
									onChange={(val) =>
										onChange({
											...value,
											identifier:
												value.identifier !==
												"date_hours"
													? "date_hours"
													: undefined,
										})
									}
									wrapper={{
										layout: "vertical",
										label: t(
											`fields.identifier.date_hours`
										),
										className: "w-full",
									}}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

CustomFormEditField.locale = {
	nl: {
		descriptions: {
			PRICE: "Deze waarde wordt automatisch berekend op de prijs van het betaalverzoek",
		},
		fields: {
			label: {
				label: "Label",
				placeholder: "Voer een label in",
			},
			placeholder: {
				label: "Placeholder",
				placeholder: "Voer een placeholder in",
			},
			type: {
				label: "Type",
				placeholder: "Selecteer een type",
				options: {
					TEXT: "Tekst",
					NUMBER: "Nummer",
					PRICE: "Bedrag in euro's",
					FILE: "Bestand",
					DATE: "Datum",
					DATE_RANGE: "Start & einddatum",
					DATE_START_END_TIME: "Datum met start & eindtijd",
					BOOLEAN: "Ja/nee",
					OPTIONS: "Opties",
				},
			},
			options: {
				label: "Opties",
				placeholder: "Voer de opties gescheden door komma's in",
			},
			identifier: {
				price: "Bereken op de prijs van het betaalverzoek",
				date_hours: "Berekend de uren van de start- en einddatum",
			},
			fillable_by: {
				label: "Invulbaar door",
				placeholder: "Selecteer een optie",
				options: {
					ALL: "Iedereen",
					COMPANY: "Alleen opdrachtgevers",
				},
			},
			settings: {
				is_multiline: {
					label: "Meerdere regels",
				},
			},
		},
		buttons: {
			remove: "Verwijder",
		},
		advanced: {
			label: "Geavanceerd",
		},
	},
	en: {
		descriptions: {
			PRICE: "This value is automatically calculated based on the price of the payment request",
		},
		fields: {
			label: {
				label: "Label",
				placeholder: "Enter a label",
			},
			placeholder: {
				label: "Placeholder",
				placeholder: "Enter a placeholder",
			},
			type: {
				label: "Type",
				placeholder: "Select a type",
				options: {
					TEXT: "Text",
					NUMBER: "Number",
					PRICE: "Amount in euros",
					FILE: "File",
					DATE: "Date",
					DATE_RANGE: "Start & end date",
					DATE_START_END_TIME: "Date with start & end time",
					BOOLEAN: "Yes/no",
					OPTIONS: "Options",
				},
			},
			options: {
				label: "Options",
				placeholder: "Enter the options separated by commas",
			},
			identifier: {
				price: "Calculate based on the price of the payment request",
				date_hours: "Calculate the hours from the start and end date",
			},
			fillable_by: {
				label: "Fillable by",
				placeholder: "Select an option",
				options: {
					ALL: "Everyone",
					COMPANY: "Only clients",
				},
			},
			settings: {
				is_multiline: {
					label: "Multiple lines",
				},
			},
		},
		buttons: {
			remove: "Remove",
		},
		advanced: {
			label: "Advanced",
		},
	},
};

export default withTranslation(CustomFormEditField);
