import { ApiResume } from "src/api/types";
import useApi from "src/hooks/api/utils/useApi";

type Options = {
	filter?: any;
	rememberKey: string;
};

function useResume(options?: Options) {
	const { state, actions, api } = useApi(
		{
			id: options?.rememberKey
				? `RESUME_${options?.rememberKey}`
				: "RESUME",
		},
		{
			baseUrl: `/resume`,
			query: {
				state: "all",
				...(options?.filter || {}),
			},
		}
	);

	const resume: ApiResume = state?.data || [];

	return {
		resume,
		status: state.status,
		actions: {
			...actions,
		},
		api,
	};
}

export default useResume;
